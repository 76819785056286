import "../scss/app.scss";

import { withTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { Row } from "react-bootstrap";

import { useStore } from "react-redux";
import { useState, useRef, useEffect } from "react";
import Footer from "../../../../layout/concierge/FooterServ";
import HeaderServ from "../../../../layout/concierge/HeaderServ";

import { Typography, Box } from "@material-ui/core";
import SmsIcon from "@material-ui/icons/Sms";

import CodeInput from "../section-parts/CodeInput";
import config from "../../../../../config.json";
import MessengerCustomerChat from "react-messenger-customer-chat";

function SmsValidationC({ t }) {
    const history = useNavigate();
    const location = useLocation();
    const store = useStore();
    const Ref = useRef(null);
    const [timer, setTimer] = useState("00:00:00");
    const [count, setCount] = useState(0);

    const clearTimer = (e) => {
        setTimer("00:01:00");

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const handleResendSmsClick = () => {
        fetch(`${config.API_SERVER}/api/twilio/send`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                telephone:
                    store.getState().estimationConcierge.settings.telephone,
            }),
        });
        if (count > 0) clearTimer(getDeadTime());
        setCount(count + 1);
    };

    // useEffect(() => {
    // 	clearTimer(getDeadTime());
    // }, []);
    useEffect(() => {
        //clearTimer(getDeadTime());

        if (timer === "00:00:00" && count > 0 && count < 4) {
            handleResendSmsClick();
        }
    }, [timer, count, handleResendSmsClick]);

    if (
        location.pathname === "/concierge/sms_validation" &&
        !store.getState().estimationConcierge.settings.telephone
    ) {
        history("/concierge/validation");
        return <></>;
    }

    const displayPhoneNumber = () => {
        const phone = store.getState().estimationConcierge.settings.telephone;

        return `${phone[0]}${phone[1]} (${phone[2]}${phone[3]}${phone[4]})-${phone[5]}${phone[6]}${phone[7]} ${phone[8]}${phone[9]}${phone[10]}${phone[11]}`;
    };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                    ":" +
                    (minutes > 9 ? minutes : "0" + minutes) +
                    ":" +
                    (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    };

    let style = "blue_text";

    return (
        <div>
            <HeaderServ />

            <Box className="sms_validation">
                <Typography variant="h2">
                    {t("Client.Sms_validation.title")}
                </Typography>

                <Typography variant="h3">
                    {t("Client.Sms_validation.phone")}
                    <span> {displayPhoneNumber()}</span>
                </Typography>

                <p>{t("Client.Sms_validation.text1")}</p>

                <CodeInput />

                <p hidden={count === 0 ? true : false}>
                    {t("Client.Sms_validation.text3")}
                </p>
                <h6 hidden={count === 0 ? true : false}>{timer}</h6>

                <Link
                    to="#"
                    style={{
                        pointerEvents:
                            (timer === "00:00:00" && count < 4) || count === 0
                                ? ""
                                : "none",
                    }}
                    className={style}
                    onClick={handleResendSmsClick}
                >
                    <SmsIcon color="disabled" />
                    {t("Client.Sms_validation.text4")}
                </Link>
            </Box>

            <Row className="localisation_footer">
                {/* <p>{t("Client.Location.footer")}<Link to="#" className="link">{t("Client.Location.footer_link")}</Link></p> */}
            </Row>

            <MessengerCustomerChat
                pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            />
            <Footer />
        </div>
    );
}

export default withTranslation()(SmsValidationC);
