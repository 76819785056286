import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

import { useStore } from 'react-redux';

//import TextField from '@material-ui/core/TextField';
import config from '../../../../../config.json'
import VerificationInput from "react-verification-input";
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyle = makeStyles((theme)=> ({
    vefifyCode: {
        '& .vi__wrapper':{
            display: 'flex',
            justifyContent: 'center'
        },
        '& .vi__character': { 
            borderRadius: '8px',
            border: `1px solid ${theme.palette.primary.main}`,
            fontSize: '24px' 
        },
        '& .vi__character--selected--default': {  
            outline: `2px solid ${theme.palette.primary.main} !important`,
            color: theme.palette.primary.main
        } 
    },
    error: { 
        '& h6': { 
            color: '#dc3545', 
            fontWeight: 'bold'
        }
    }
}))

const CodeInput = () => {
    const classes = useStyle()
    const history = useNavigate();
    const store = useStore();
    const [sms, setSms] = useState('');
    const [smsError, setSmsError] = useState(false);
    const { t } = useTranslation()
    
    const handleSmsChange = (newValue) => {        
        setSms(newValue);
        if (newValue.length === 6) submit(newValue);
    };

    const submit = (sms) => {
        
        const requestBody = { ...store.getState().estimationConcierge.settings, sms }; 
        fetch(`${config.API_SERVER}/api/register/client`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        })
        .then((res) => {
            if (res.status === 200){ 
                history('/concierge/thankyou'); 
            }else {                 
                setSmsError(true); 
                console.log(res, 'res')
            }
        });
    };

    return (
        <div className="sms_code__wrapper">
            <form noValidate autoComplete="off">
                {/* <TextField
                    id="sms-code"
                    name="sms"
                    inputProps={{ maxLength: 6 }}
                    onChange={handleSmsChange}
                    value={sms}
                    error={smsError}
                /> */}
                <Box className={classes.vefifyCode}>
                    <VerificationInput 
                        value={sms}
                        length={6}
                        autoFocus={true}
                        onChange={handleSmsChange}
                        placeholder=""
                    />
                </Box>
                { 
                    (smsError) && ( 
                        <Box className={classes.error} mt={2}> 
                            <Typography variant="h6">{t("Client.Sms_validation.validation")}</Typography>
                        </Box>                    
                    )
                }
                
                <p className="sms_code__subtext">{t("Client.Sms_validation.text2")}</p>
            </form>
        </div>
    );
};

export default CodeInput;
