import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import { logEvent, ErrorResult } from "./util"; //Result
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import CheckBoxCustom from "../../../../share/inputs/CheckBoxCustom";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
    inputError: {
        "& #cardNumber, #expiry, #cvc, #name, #postal": {
            border: `2px solid #dc3545 !important`,
        },
    },
    textGroup: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0 8px !important",
        margin: theme.spacing(0, 0, 2, 0),
        "& .MuiFormControlLabel-root": {
            justifyContent: "flex-start",
        },
        "& input": {
            //height: '40px !important',
            border: `2px solid ${theme.palette.primary.main} !important`,
            padding: "10px 14px",
            fontSize: "15px",
            margin: theme.spacing(0, 0, 0, 0),
        },
        "& .error": {
            fontSize: "1.6rem",
        },
        "& h5": {
            fontWeight: "normal",
            fontSize: "13px",
            color: "#737379",
            "@media(max-width: 1366px)": {
                fontSize: "14px",
            },
            "@media(max-width: 1200px)": {
                fontSize: "11px",
            },
        },
        "& .MuiFormControlLabel-label": {
            fontSize: "1.4rem",
        },
        "& .StripeElement, .StripeElementIdeal, .StripeElementP24, .StripeElementEPS, .StripeElementFpx":
            {
                margin: "0 !important",
            },
    },
    contentPay: {
        margin: theme.spacing(2),
    },
    cardIconP: {
        "& .CardNumberField-input-wrapper": {
            marginLeft: "30px",
        },
    },
}));

const ELEMENT_OPTIONS = {
    style: {
        showIcon: true,
        iconStyle: "solid",
        base: {
            fontSize: "18px",
            color: "#424770",
            letterSpacing: "0.025em",
            //marginLeft: '30px',
            border: "1px solid blue",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#9e2146",
        },
        "& .ElementsApp, .ElementsApp .InputElement": {
            marginLeft: "30px",
        },
    },
};

const CheckoutFormElements = ({
    name,
    postal,
    keepingCard,
    handleFormData,
    errorMessage,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();

    let cardNumberStyle = classes.textGroup;
    let cardExpStyle = classes.textGroup;
    let cardCvc = classes.textGroup;
    let cardName = classes.textGroup;
    let cardPostal = classes.textGroup;

    if (
        errorMessage.message &&
        (errorMessage.code === "incomplete_number" ||
            errorMessage.code === "invalid_number")
    ) {
        cardNumberStyle = cardNumberStyle + " " + classes.inputError;
    }

    if (
        errorMessage.message &&
        (errorMessage.code === "incomplete_expiry" ||
            errorMessage.code === "invalid_expiry_year")
    ) {
        cardExpStyle = cardExpStyle + " " + classes.inputError;
    }

    if (errorMessage.message && errorMessage.code === "incomplete_cvc") {
        cardCvc = cardCvc + " " + classes.inputError;
    }
    if (errorMessage.message && errorMessage.code === "name_required") {
        cardName = cardName + " " + classes.inputError;
    }
    if (errorMessage.message && errorMessage.code === "postal_required") {
        cardPostal = cardPostal + " " + classes.inputError;
    }

    return (
        <Box>
            <Grid container>
                <Grid itms xs={12} sm={6} md={9} lg={6}>
                    <Box className={cardName}>
                        <label htmlFor="name">
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step2.labelNomCart")}
                            </Typography>
                        </label>
                        <input
                            id="name"
                            name="name"
                            placeholder="Jenny Rosen"
                            value={name}
                            onChange={(e) => handleFormData(e)}
                        />
                        {errorMessage.message &&
                            errorMessage.code === "name_required" &&
                            errorMessage.message && (
                                <ErrorResult>
                                    {errorMessage.message}
                                </ErrorResult>
                            )}
                    </Box>
                </Grid>
                <Grid itms xs={12} sm={6} md={6} lg={6}>
                    <Box className={cardPostal}>
                        <label htmlFor="postal">
                            <Typography variant="h5">
                                {t(
                                    "REQUEST.CheckoutForm.step2.labelPostalCode"
                                )}
                            </Typography>
                        </label>
                        <input
                            id="postal"
                            name="postal"
                            placeholder="X9X 9X9"
                            // style={{backgroundColor: '#dee2e6'}}
                            // disabled={true}
                            value={postal}
                            onChange={(e) => handleFormData(e)}
                        />
                        {errorMessage.message &&
                            errorMessage.code === "postal_required" &&
                            errorMessage.message && (
                                <ErrorResult>
                                    {errorMessage.message}
                                </ErrorResult>
                            )}
                    </Box>
                </Grid>
                <Grid items xs={12} sm={4} md={6} lg={6}>
                    <Box className={cardNumberStyle}>
                        <label htmlFor="cardNumber">
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step2.labelCC")}
                            </Typography>
                        </label>
                        <Box className={classes.cardIconP}>
                            <CardNumberElement
                                id="cardNumber"
                                onBlur={logEvent("blur")}
                                onChange={logEvent("change")}
                                onFocus={logEvent("focus")}
                                onReady={logEvent("ready")}
                                options={ELEMENT_OPTIONS}
                            />
                            {errorMessage.message &&
                                (errorMessage.code === "incomplete_number" ||
                                    errorMessage.code === "invalid_number") &&
                                errorMessage.message && (
                                    <ErrorResult>
                                        {errorMessage.message}
                                    </ErrorResult>
                                )}
                        </Box>
                    </Box>
                </Grid>
                <Grid items xs={12} sm={2} md={6} lg={6}>
                    <Box className={cardExpStyle}>
                        <label htmlFor="expiry">
                            <Typography variant="h5">
                                {t(
                                    "REQUEST.CheckoutForm.step2.labelExpirationDate"
                                )}
                            </Typography>
                        </label>
                        <CardExpiryElement
                            id="expiry"
                            onBlur={logEvent("blur")}
                            onChange={logEvent("change")}
                            onFocus={logEvent("focus")}
                            onReady={logEvent("ready")}
                            options={ELEMENT_OPTIONS}
                        />
                        {errorMessage.message &&
                            (errorMessage.code === "incomplete_expiry" ||
                                errorMessage.code === "invalid_expiry_year") &&
                            errorMessage.message && (
                                <ErrorResult>
                                    {errorMessage.message}
                                </ErrorResult>
                            )}
                    </Box>
                </Grid>
                <Grid items xs={12} sm={6} md={6} lg={6}>
                    <Box className={cardCvc}>
                        <label htmlFor="cvc">
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step2.labelCvc")}
                            </Typography>
                        </label>
                        <CardCvcElement
                            id="cvc"
                            onBlur={logEvent("blur")}
                            onChange={logEvent("change")}
                            onFocus={logEvent("focus")}
                            onReady={logEvent("ready")}
                            options={ELEMENT_OPTIONS}
                        />
                        {errorMessage.message &&
                            errorMessage.code === "incomplete_cvc" &&
                            errorMessage.message && (
                                <ErrorResult>
                                    {errorMessage.message}
                                </ErrorResult>
                            )}
                    </Box>
                </Grid>
                <Grid items xs={12} sm={6} md={6} lg={6}>
                    <Box className={classes.textGroup} mt={2}>
                        <CheckBoxCustom
                            name="keepingCard"
                            label={t(
                                "REQUEST.CheckoutForm.step2.labelCheckBox"
                            )}
                            value={keepingCard}
                            handleChange={(e) => handleFormData(e)}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/* {errorMessage.message && <ErrorResult>{errorMessage.message}</ErrorResult>} */}
            {/* {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>} */}
        </Box>
    );
};

export default CheckoutFormElements;
