import "../scss/app.scss";

import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Footer from "../../../../layout/concierge/FooterServ";
import HeaderServ from "../../../../layout/concierge/HeaderServ";

import HousingBenefitMenu from "../sections/chooseService/HousingBenefitMenu";
import Validation from "../sections/chooseService/Validation";
import MessengerCustomerChat from "react-messenger-customer-chat";

function ChooseServiceC({ t }) {
    const location = useLocation();

    return (
        <>
            <HeaderServ />

            {(location.pathname === "/concierge/housing" ||
                location.pathname === "/concierge/benefit") && (
                <HousingBenefitMenu />
            )}

            {location.pathname === "/concierge/validation" && <Validation />}

            <MessengerCustomerChat
                pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            />
            <Footer />
        </>
    );
}

export default withTranslation()(ChooseServiceC);
