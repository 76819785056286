import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { useDispatch, useStore } from "react-redux";
import {
    estimationHousingUpdate,
    fetchEstimation,
} from "../../../../../common/store/actions/estimationConciergeAction";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,

        "@media (max-width:599px)": {
            minWidth: "97px",
            margin: 0,
        },
    },
}));

const StudioServices = ({ t }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useStore();

    const houseworkPersonalization =
        store.getState().estimationConcierge.settings.houseworkPersonalization;

    const [animals, setAnimals] = useState({
        dog: houseworkPersonalization.dog,
        cat: houseworkPersonalization.cat,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        store.subscribe(() => {
            setErrors(store.getState().estimationConcierge.housingErrorsList);
        });
    }, [store]);

    const handleAnimalsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        let requestBody = {
            ...store.getState().estimationConcierge.settings,
            houseworkPersonalization: {
                ...houseworkPersonalization,
                [name]: value,
            },
        };

        estimationHousingUpdate(requestBody)(dispatch);
        fetchEstimation(requestBody)(dispatch);
        setAnimals({ ...animals, [name]: value });
    };

    return (
        <Box className="StudioServices__choice-container">
            <Typography variant="h3" className="HousingType__title">
                {t("Client.Logement.title3")}
            </Typography>

            <Box>
                <p>{t("Client.Logement.housingType_studio_text1")}</p>
                <br></br>
                <ul className="StudioServices__list">
                    <li>{t("Client.Logement.housingType_studio_text2")}</li>
                    <br></br>
                    <li>{t("Client.Logement.housingType_studio_text3")}</li>
                </ul>
            </Box>

            <Box className="StudioServices__choice-form"></Box>
        </Box>
    );
};

export default withTranslation()(StudioServices);
