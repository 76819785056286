import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useStore } from "react-redux";
import {
    estimationBenefitUpdate,
    fetchEstimation,
    fetchMoreThanOneEstimate,
} from "../../../../../common/store/actions/estimationConciergeAction";
import CounterCard from "./CounterCard";

const OptionsCard = ({
    error,
    name,
    title,
    notAuthorized,
    iconSrc,
    description,
    hasCounter,
    proActive,
    needSuppliesAcceptation,
    proCriterion,
    Banderie,
    id,
    iconUrl,
    houseworkPersonalization,
    disabled,
    description1,
    option2,
}) => {
    const store = useStore();
    const dispatch = useDispatch();
    // const [count, setCount] = useState(
    //     store.getState().estimationConcierge.settings.houseworkPersonalization[
    //         name
    //     ]
    // );

    const [isActiveCard, setIsActiveCard] = useState(
        name === "concierge_key"
            ? !!store.getState().estimationConcierge.settings
                  .houseworkServiceAddition[name]
            : !!store.getState().estimationConcierge.settings
                  .houseworkPersonalization[name]
    );

    // if (name === "concierge_key") {
    //     setIsActiveCard(
    //         !!store.getState().estimationConcierge.houseworkServiceAddition[
    //             name
    //         ]
    //     );
    // }

    //const [isActiveCard, setIsActiveCard] = useState(!!store.getState().estimationConcierge.settings.houseworkPersonalization[name]);
    const updateStore = (value) => {
        const twiceOrMoreRequests =
            store.getState().estimationConcierge.twiceOrMoreRequests;

        let requestBody = {};
        if (name === "product_standard" || name === "product_ecological") {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    product_ecological:
                        name === "product_ecological"
                            ? !needSuppliesAcceptation.product_ecological
                            : false,
                    product_standard:
                        name === "product_standard"
                            ? !needSuppliesAcceptation.product_standard
                            : false,
                },
            };
        } else if (name === "laundry_room") {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    [name]: id,
                },
            };
        } else if (name === "travel_welcome" || name === "travel_departure") {
            return;
        } else if (name === "concierge_key") {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkServiceAddition: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkServiceAddition,
                    ["concierge_key"]:
                        !store.getState().estimationConcierge.settings
                            .houseworkServiceAddition["concierge_key"],
                },
            };
        } else {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    [name]: value,
                },
            };
        }

        estimationBenefitUpdate(requestBody)(dispatch);

        if (twiceOrMoreRequests) {
            const access_token = store.getState().auth.access_token;
            const categoryId = store.getState().auth.user.housing.category.id;
            const sizeId =
                store.getState().auth.user.housing.id !== 1
                    ? store.getState().auth.user.housing.size.id
                    : null;
            const specificity = store.getState().auth.user.housing.specificity;
            fetchMoreThanOneEstimate(
                requestBody,
                access_token,
                categoryId,
                sizeId,
                specificity,
                false
            )(dispatch);
        } else {
            fetchEstimation(requestBody)(dispatch);
        }
    };

    const handleCounterLessClick = () => {
        let countt =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization[name];
        if (countt === 0) return;

        const newCount = countt > 0 ? countt - 1 : 0;

        // setCount(newCount);
        setIsActiveCard(newCount !== 0);
        updateStore(newCount);
    };

    const handleCounterMoreClick = () => {
        let countt =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization[name];
        const newCount = countt + 1;

        // setCount(newCount);
        setIsActiveCard(true);
        updateStore(newCount);
    };
    const handleonClick = () => {
        let B =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization["banderie"];
        if (name === "laundry_room" && vacummEcoStandar) {
            if (B) return setIsActiveCard(!isActiveCard);
            else return handleCardClick(!isActiveCard);
        } else if (name !== "laundry_room" && vacummEcoStandar)
            return handleCardClick();
        else return null;
    };

    const handleCardClick = () => {
        setIsActiveCard(!isActiveCard);
        updateStore(!isActiveCard);
    };

    let vacummEcoStandar = false; //proCriterion
    if (!hasCounter) {
        vacummEcoStandar = true;
        if (proCriterion === 0) {
            vacummEcoStandar = false;
        }
    }
    const activeCardFc = () => {
        if (disabled === true) {
            return "options_card2_disabled";
        }
        // if (error) {
        //     return "options_card_error";
        // } else if (error === false) return "options_card active";
        if (option2 === true) {
            if (proActive !== undefined)
                if (proActive) return "options_card2 active";
                else return "options_card2";
            else if (
                (isActiveCard &&
                    name === "laundry_room" &&
                    id ===
                        store.getState().estimationConcierge.settings
                            .houseworkPersonalization["laundry_room"]) ||
                (isActiveCard && Banderie === undefined && !hasCounter) ||
                (isActiveCard &&
                    store.getState().estimationConcierge.settings
                        .houseworkPersonalization[name] > 0 &&
                    hasCounter)
            )
                return "options_card2 active";
            else return "options_card2";
        }
        let B =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization["banderie"];

        if (proActive !== undefined)
            if (proActive) return "options_card active";
            else return "options_card";
        else if (
            (isActiveCard &&
                !B &&
                name === "laundry_room" &&
                id ===
                    store.getState().estimationConcierge.settings
                        .houseworkPersonalization["laundry_room"]) ||
            (isActiveCard && Banderie === undefined && !hasCounter) ||
            (isActiveCard &&
                store.getState().estimationConcierge.settings
                    .houseworkPersonalization[name] > 0 &&
                hasCounter)
        )
            return "options_card active";
        else return "options_card";
    };

    return (
        // <div className={ isActiveCard ? 'options_card active' : 'options_card' } onClick={!hasCounter ? handleCardClick : null}>

        <Box>
            <Box hidden={proCriterion === 1 || proCriterion === null}>
                <Box
                    style={{
                        textTransform: "initial !important",
                        fontSize: "14px",
                        color: "red",
                    }}
                >
                    {notAuthorized}
                </Box>
            </Box>

            <Box className={activeCardFc()} onClick={handleonClick}>
                <div className="options_card__header">
                    <div className="image">
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            flexDirection={"column"}
                        >
                            <Box display={"flex"}>
                                <img
                                    src={iconSrc}
                                    alt={name}
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                    }}
                                />
                                {iconUrl !== undefined ? (
                                    <Box>
                                        <AddIcon
                                            fontSize="inherit"
                                            color="action"
                                        />
                                        <img
                                            src={iconUrl}
                                            alt={name}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                // display: "flex",
                                                // marginRight: "10px",
                                                //marginLeft: "25px",
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Box>
                        </Box>

                        <h6>{title}</h6>
                    </div>
                    {hasCounter &&
                        (proCriterion === 1 || proCriterion === null) && (
                            <div className="counter">
                                <button
                                    className="counter_btn"
                                    onClick={handleCounterLessClick}
                                    disabled={
                                        houseworkPersonalization.Min ===
                                        store.getState().estimationConcierge
                                            .settings.houseworkPersonalization[
                                            name
                                        ]
                                    }
                                >
                                    -
                                </button>
                                <p>
                                    {
                                        store.getState().estimationConcierge
                                            .settings.houseworkPersonalization[
                                            name
                                        ]
                                    }
                                </p>
                                <button
                                    className="counter_btn"
                                    onClick={handleCounterMoreClick}
                                    disabled={
                                        houseworkPersonalization.Max ===
                                        store.getState().estimationConcierge
                                            .settings.houseworkPersonalization[
                                            name
                                        ]
                                    }
                                >
                                    +
                                </button>
                            </div>
                        )}
                    <Box
                        className="options_card__desc"

                        //marginLeft={"20px"}
                    >
                        <p>{description1}</p>
                    </Box>
                </div>

                {/* <Box className="options_card__desc" hidden={(( proCriterion === 1 || proCriterion === null))}>HOLA</Box> */}
            </Box>
            <Box
                className="options_card__desc"
                width={"260px"}
                marginLeft={"10px"}
            >
                <p style={{ align: "center", marginRight: "10px" }}>
                    {description}
                </p>
            </Box>
        </Box>
    );
};

export default withTranslation()(OptionsCard);
