// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./common.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env.REACT_APP_API_43P_KEY);

const PaymentForm = ({
    price,
    sendPaymentByClient,
    history,
    address,
    Data,
    personalData,
    housingSpec,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm
                price={price}
                sendPaymentByClient={sendPaymentByClient}
                address={address}
                personalData={personalData}
                housingSpec={housingSpec}
            />
        </Elements>
    );
};

export default PaymentForm;
