import moment from "moment";
import config from "../../../config.json";

export const deletePhoneMask = (phoneMasked) => {
    let phone = phoneMasked.replace("(", "");
    phone = phone.replace(")", "");
    phone = phone.replace("-", "");
    phone = phone.replace(" ", "");
    phone = phone.replace(/\s+/g, "");
    phone = phone.trim();

    return phone;
};

/**
 *
 * @param {Date} ra The format is: 2021-08-01 00:00:00
 * @return string 31/03/2021
 *
 */
export const formateDateSlashes = (ra) => {
    let today = new Date(ra.replace(/-/g, "/"));
    let year = today.getFullYear();
    let month =
        today.getMonth() + 1 < 10
            ? "0" + (today.getMonth() + 1)
            : today.getMonth() + 1;
    let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    let formDate = day + "/" + month + "/" + year;

    return formDate;
};

/**
 *
 * @param {Date} date The format is: 2021-03-27T02:18:23.000000Z
 * @return string 31 May 2021
 */
export const getDateFormatDayMonthYear = (date) => {
    let customeDate = new Date(date.replace(/-/g, "/"));
    customeDate = customeDate.toString().split(" ");
    customeDate = `${customeDate[2]} ${customeDate[1]} ${customeDate[3]}`;
    return customeDate;
};

/**
 * @param {Float, Float, Boolean} Float format is: 165.3, 15
 * @return: { tps: 9.02, tvq: 17.98, total: 207.30 }
 */
export const calculateTpsTvqAndTotal = (
    workPrice,
    vacuumPrice,
    ecologicalPrice,
    standardPrice,
    isCalculateTax
) => {
    let tps = 0.0;
    let tvq = 0.0;

    const totalPrice =
        parseFloat(workPrice, 10) +
        parseFloat(vacuumPrice, 10) +
        parseFloat(ecologicalPrice, 10) +
        parseFloat(standardPrice, 10);

    // if(isCalculateTax !== null && isCalculateTax !== 0){
    tps = (totalPrice * parseFloat(config.TPS, 10)) / 100;
    tps = tps.toFixed(2);
    tvq = (totalPrice * parseFloat(config.TVQ, 10)) / 100;
    tvq = tvq.toFixed(2);
    // }

    let total =
        parseFloat(totalPrice, 10) + parseFloat(tps, 10) + parseFloat(tvq, 10);

    return {
        tps: tps,
        tvq: tvq,
        total: total,
    };
};

/**
 * @param {Float, Float, Boolean} Float format is: 165.3, 15
 * @return: { tps: 9.02, tvq: 17.98, total: 207.30 }
 */
export const calculateTpsTvqAndTotalFromComission = (workPrice) => {
    let tps = 0.0;
    let tvq = 0.0;

    const commission = (workPrice * config.TIGGIDOOCOMMISSION) / 100;

    tps = (commission * parseFloat(config.TPS, 10)) / 100;
    tps = tps.toFixed(2);

    tvq = (commission * parseFloat(config.TVQ, 10)) / 100;
    tvq = tvq.toFixed(2);

    // let total = parseFloat(workPrice,10) + parseFloat(vacuumPrice,10) + parseFloat(ecologicalPrice,10) + parseFloat(standardPrice,10) + parseFloat(tps,10) + parseFloat(tvq,10)
    // total = total.toFixed(2)
    let total =
        parseFloat(commission, 10) + parseFloat(tps, 10) + parseFloat(tvq, 10);

    return {
        commission: config.TIGGIDOOCOMMISSION,
        commissionPrice: commission,
        tps: tps,
        tvq: tvq,
        total: total.toFixed(2),
    };
};

/**
 * @param {Float, Float} Float format is: 165.3, 15
 * @return: { 9.02 }
 */
export const calculateCommission = (workPrice) => {
    // let total = parseFloat(workPrice,10) + parseFloat(vacuumPrice,10) + parseFloat(ecologicalPrice,10) + parseFloat(standardPrice,10) + parseFloat(tps,10) + parseFloat(tvq,10)
    // total = total.toFixed(2)
    let res =
        (parseFloat(workPrice, 10) *
            parseFloat(config.TIGGIDOOCOMMISSION, 10)) /
        100;

    return (workPrice - res).toFixed(2);
};

/**
 * @param {String} Float format is: array('0': 2021-01-01, '1': 2021-01-01)
 * @return: { tps: 9.02, tvq: 17.98, total: 207.30 }
 */
export const compareDatesWithTodayDate = (time) => {
    const today = moment(`${new Date()}`).unix() * 1000;

    let state = false;
    for (let index in time) {
        let d = moment(`${time[index]}T00:00:00`).unix() * 1000;
        if (d <= today) {
            state = true;
        } else {
            state = false;
        }
    }

    return state;
};

export const fixTimeFromDataBase = (t) => {
    let hourEndActivity = t.toString().substring(0, 2);
    let minuteEndActivity = t.toString().substring(2, 4);
    if (t.toString().length === 5) {
        hourEndActivity = t.toString().substring(0, 1);
        minuteEndActivity = t.toString().substring(1, 3);
    }

    const endActivity =
        hourEndActivity < 10
            ? "0" + hourEndActivity + ":" + minuteEndActivity + ":00"
            : hourEndActivity + ":" + minuteEndActivity + ":00";

    return endActivity;
};

export const functionHour = (scheduleActivities, chosenDate) => {
    let serviceHour = [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
    ];

    for (let index in scheduleActivities) {
        // let hourStartActivity = scheduleActivities[index].pro_start_time.toString().substring(0, 2)
        // let minuteStartActivity = scheduleActivities[index].pro_start_time.toString().substring(2, 4)
        // if(scheduleActivities[index].pro_start_time.toString().length === 5){
        //   hourStartActivity = scheduleActivities[index].pro_start_time.toString().substring(0, 1)
        //   minuteStartActivity = scheduleActivities[index].pro_start_time.toString().substring(1, 3)
        // }

        // const startActivity = hourStartActivity < 10
        //                     ?
        //                       '0'+ hourStartActivity + ':' + minuteStartActivity + ':00'
        //                     :
        //                       hourStartActivity + ':' + minuteStartActivity + ':00'

        const startActivity = scheduleActivities[index].pro_start_time;

        let hourEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(0, 2);
        let minuteEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(2, 4);
        if (scheduleActivities[index].pro_finish_time.toString().length === 5) {
            hourEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(0, 1);
            minuteEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(1, 3);
        }

        const endActivity =
            hourEndActivity < 10
                ? "0" + hourEndActivity + ":" + minuteEndActivity + ":00"
                : hourEndActivity + ":" + minuteEndActivity + ":00";

        //const endActivity = `${ hourEndActivity < 10 ? `0${hourEndActivity}` : hourEndActivity }:${minuteEndActivity}:00`

        const unixStartActivity =
            moment(`${chosenDate}T${startActivity}`).unix() * 1000;
        const unixEndActivity =
            moment(`${chosenDate}T${endActivity}`).unix() * 1000;

        for (let i = unixStartActivity; i <= unixEndActivity; i += 30 * 60000) {
            let hour = moment(i).hours();
            hour = hour < 10 ? `0${hour.toString()}` : hour.toString();

            let minutes = moment(i).minutes();
            minutes =
                minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

            let t = `${hour}:${minutes}`;

            if (serviceHour.indexOf(t) > -1) {
                serviceHour = serviceHour.filter((row) => row !== t);
            }
        }
    }

    const returnServiceHour = [];
    for (let i = 0; i < serviceHour.length; i++) {
        returnServiceHour.push({
            id: `${serviceHour[i]}:00`,
            name: serviceHour[i].replace(":", "h"),
        });
    }

    return returnServiceHour;
};

export const functionActivitiesHour = (scheduleActivities, chosenDate) => {
    let array = [];
    for (let index in scheduleActivities) {
        const startActivity = scheduleActivities[index].pro_start_time;

        let hourEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(0, 2);
        let minuteEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(2, 4);
        if (scheduleActivities[index].pro_finish_time.toString().length === 5) {
            hourEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(0, 1);
            minuteEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(1, 3);
        }

        const endActivity =
            hourEndActivity < 10
                ? "0" + hourEndActivity + ":" + minuteEndActivity + ":00"
                : hourEndActivity + ":" + minuteEndActivity + ":00";

        //const endActivity = `${ hourEndActivity < 10 ? `0${hourEndActivity}` : hourEndActivity }:${minuteEndActivity}:00`

        const unixStartActivity =
            moment(`${chosenDate}T${startActivity}`).unix() * 1000;
        const unixEndActivity =
            moment(`${chosenDate}T${endActivity}`).unix() * 1000;
        //let i = unixStartActivity
        //let hour
        //let minutes
        //let t
        for (let i = unixStartActivity; i <= unixEndActivity; i += 30 * 60000) {
            let hour = moment(i).hours();
            hour = hour < 10 ? `0${hour.toString()}` : hour.toString();

            let minutes = moment(i).minutes();
            minutes =
                minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

            let t = `${hour}:${minutes}:00`;
            array.push({
                id: t,
                color: "green",
            });
        }

        // hour = moment(i).hours()
        // hour = hour < 10 ? `0${hour.toString()}`: hour.toString()

        // minutes = moment(i).minutes()
        // minutes = minutes < 10 ? `0${minutes.toString()}`: minutes.toString()

        // t = `${hour}:${minutes}:00`
        // array.push({
        //   id: t,
        //   color: 'orange'
        // })
    }
    return array;
};

export const returnCamelCaseString = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

/**
 * @param {String} Float format is: 2021-12-31T15:00:00
 * @param {String} Float format is: 01:30:00
 * @return: 16:30:00
 */
export const functionAddMinutesToADate = (date, min) => {
    let splittedDate = date.split("T");
    const d = splittedDate[0].split("-");
    const t = splittedDate[1].split(":");
    const numberOfMlSeconds = new Date(
        d[0],
        parseInt(d[1]) - 1,
        parseInt(d[2]),
        parseInt(t[0]),
        parseInt(t[1]),
        parseInt(t[2])
    ).getTime();

    const addMlSeconds =
        (parseInt(min.split(":")[0], 10) * 60 +
            parseInt(min.split(":")[1], 10)) *
        60000;
    const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    let hours = newDateObj.getHours();
    hours = hours < 10 ? "0" + hours : hours;

    let minutes = newDateObj.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${minutes}:00`;

    return time;
};

/**
 * @param {String} Float format is: 2021-12-31T15:00:00
 * @return: 1646316000000
 */
export const getTimeUnix = (date) => {
    let splittedDate = date.split("T");
    const d = splittedDate[0].split("-");
    const t = splittedDate[1].split(":");
    const numberOfMlSeconds = new Date(
        d[0],
        parseInt(d[1]) - 1,
        parseInt(d[2]),
        parseInt(t[0]),
        parseInt(t[1]),
        parseInt(t[2])
    ).getTime();

    return numberOfMlSeconds;
};

/**
 * @param {String} Float format is: 2022-03-28 00:00:00
 * @param {String} Float format is: fr-FR
 * @return: Monday, April 25, 2022
 */

export const datetoLocaleString = (date, language) => {
    const getDay = new Date(date.replace(/-/g, "/"));
    const res = getDay.toLocaleString(language, {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
    });

    let cad = "";
    if (language === "fr") {
        let darray = res.split(" ");

        for (let index in darray) {
            cad =
                cad +
                " " +
                darray[index].slice(0, 1).toUpperCase() +
                darray[index].slice(1, darray[index].length).toLowerCase();
        }
    } else {
        cad = res;
    }

    return cad;
};

/**
 * @param {String} Float format is: 00:00:00 or 00:00
 * @return: 0h00
 */

export const displayDuration = (time) => {
    let ra = time.split(":");
    if (ra.length > 0) {
        ra = `${parseInt(ra[0], 10)}h${ra[1] === undefined ? "00" : ra[1]}`;
    } else {
        ra = time;
    }
    return ra;
};

/**
 * @param { Number } Number format is : 12.00 or 12
 * @return: 12.00
 */

export const checkNumber = (number) => {
    let res = number;
    if (typeof number == "number" && !isNaN(number)) {
        // check if the number is integer
        if (Number.isInteger(number)) {
            res = number + "." + Array(2 + 1).join("0");
        }
    } else {
        res = number;
    }
    return res;
};
