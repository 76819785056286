export const LoftIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="63.813"
                height="53.595"
                viewBox="0 0 63.813 53.595"
            >
                <g
                    id="Groupe_2835"
                    data-name="Groupe 2835"
                    transform="translate(-115 -237.999)"
                >
                    <g id="Groupe_2836" data-name="Groupe 2836">
                        <path
                            className="a"
                            id="Tracé_4277"
                            data-name="Tracé 4277"
                            d="M62.749,53.595H1.064a1.064,1.064,0,0,1,0-2.127H9.638V18.734l-4.172,2.7A1.063,1.063,0,0,1,4.311,19.65L30.774,2.515l-.653-.421A1.064,1.064,0,0,1,31.276.306l1.457.944L34.4.169a1.064,1.064,0,1,1,1.156,1.786l-.864.56,26.6,17.222a1.063,1.063,0,1,1-1.154,1.786L56.1,18.911V51.467h6.649a1.064,1.064,0,1,1,0,2.127M11.765,51.467H53.973V17.536L32.733,3.783,11.765,17.357Z"
                            transform="translate(115 237.999)"
                            fill="#8e8f8d"
                        />
                        <path
                            className="a"
                            id="Tracé_4279"
                            data-name="Tracé 4279"
                            d="M22.785,34.679H20.658v-17.2h-10v17.2H8.529V15.35H22.785Z"
                            transform="translate(124.434 254.978)"
                            fill="#8e8f8d"
                        />
                        <path
                            className="a"
                            id="Tracé_4281"
                            data-name="Tracé 4281"
                            d="M27.2,22H20.391a1.917,1.917,0,0,1-1.914-1.914V13.633a1.917,1.917,0,0,1,1.914-1.914H27.2a1.915,1.915,0,0,1,1.912,1.914v6.451A1.915,1.915,0,0,1,27.2,22M20.6,19.872h6.379V13.846H20.6Z"
                            transform="translate(135.438 250.962)"
                            fill="#8e8f8d"
                        />
                        <path
                            className="a"
                            id="Tracé_4282"
                            data-name="Tracé 4282"
                            d="M7.676,11.517H6.613V3.124h5.318V8.669H10.867V4.188H7.676Z"
                            transform="translate(122.314 241.455)"
                            fill="#8e8f8d"
                        />
                        <rect
                            className="a"
                            id="Rectangle_1803"
                            data-name="Rectangle 1803"
                            width="8.507"
                            height="1.064"
                            transform="translate(127.333 244.579)"
                            fill="#8e8f8d"
                        />
                        <path
                            className="a"
                            id="Tracé_4283"
                            data-name="Tracé 4283"
                            d="M18.745,15.381a5.317,5.317,0,1,1,5.316-5.316,5.321,5.321,0,0,1-5.316,5.316m0-8.507a3.19,3.19,0,1,0,3.191,3.191,3.194,3.194,0,0,0-3.191-3.191"
                            transform="translate(129.854 243.25)"
                            fill="#8e8f8d"
                        />
                    </g>
                    <rect
                        id="Rectangle_1804"
                        data-name="Rectangle 1804"
                        width="63.813"
                        height="53.594"
                        transform="translate(115 238)"
                        fill="none"
                    />
                </g>
            </svg>
        </>
    );
};
