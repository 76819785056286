import { combineReducers } from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import estimationConciergeReducer from "./estimationConciergeReducer";
// import registrationReducer from './registrationReducer';
// import reservationReducer from './reservationReducer';
// import estimationReducer from './estimationReducer';
// import chatReducer from './chatReducer'
const rootReducer = combineReducers({
    auth: authReducer,
    estimationConcierge: estimationConciergeReducer,
    alert: alertReducer,
    // registration: registrationReducer,
    // reservation: reservationReducer,
    // estimation: estimationReducer,
    // chat : chatReducer
});

export default rootReducer;
