import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles((theme) => ({
    title:{
        //margin: theme.spacing(0,0,3,1),
        '& h6':{
            color: '#000',
            fontWeight: 'bold',
            borderBottom: `5px solid ${theme.palette.secondary.main}`,
            display: 'inline'
        }
    }
}))

const LabelWithBorder = ({ text }) => {
    const classes = useStyle()
    return (
        <Box className={classes.title} >
            <Typography variant="h6">{ text }</Typography>
        </Box>
    )
}

export default LabelWithBorder
