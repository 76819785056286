import {
    ESTIMATION_HOUSING_UPDATE_C,
    ESTIMATION_HOUSING_VALIDATION_ERROR_C,
    ESTIMATION_HOUSING_VALIDATION_SUCCESS_C,
    ESTIMATION_BENEFIT_VALIDATION_SUCCESS_C,
    ESTIMATION_BENEFIT_VALIDATION_ERROR_C,
    ESTIMATION_BANDERIE_UPDATE,
    ESTIMATION_BENEFIT_UPDATE_C,
    ESTIMATION_SERVICE_ADDITION,
    FETCH_ESTIMATION_C,
    FETCH_NEW_ESTIMATION_C,
    ESTIMATION_LOCATION_UPDATE_C,
    ESTIMATION_PERSONAL_DATA_UPDATE_C,
    ESTIMATION_NEW_REQUEST_C,
    GET_LOCKED_DATE_C,
    DELETE_DATA,
} from "../actions/typesAction";

const initState = {
    sourceId: 2,
    address: null,
    calculation: {
        totalDuration: 0,
        totalPrice: 0,
    },
    settings: {        
        serviceId: 1,
        housingCategoryId: 1,
        housingSizeId: null,
        housingSpecificity: null,
        houseworkFrequencyId: 1,
        houseworkPersonalization: {
            oven: 0,
            fridge: 0,
            vacuum: false,
            product_ecological: false,
            product_standard: false,
            cat: false,
            dog: false,
            laundry_room: 0,
            single_bed: 0,
            double_bed: 0,
            queen_bed: 0,
            king_bed: 0,
            super_bed: 0,
            bebe_bed: 0,
            sofa_bed: 0,
            balcony: 0,
            terrace: 0,
        },
        houseworkServiceAddition: {
            travel_welcome: false,
            travel_departure: false,
            concierge_key: false,
            less_48h_key: false,
            find_key: false,
            on_site_key: false,
            in_box_key: false,
        },
    },

    housingSuccess: false,
    housingError: false,
    housingErrorsList: {},
    benefitSuccess: false,
    benefitError: false,
    benefitErrorsList: {},
    twiceOrMoreRequests: false,
    proId: "",
    proData: null,
    lockedDates: [],
};

const estimationReducer = (state = initState, action) => {
    switch (action.type) {
        case ESTIMATION_LOCATION_UPDATE_C:
            return {
                ...state,
                ...{
                    settings: {
                        ...state.settings,
                        ...action.payload,
                    },
                },
            };

        case ESTIMATION_BANDERIE_UPDATE:
            return {
                ...state,
                ...{
                    settings: action.payload,
                },
            };
        case DELETE_DATA:
            return initState;

        case ESTIMATION_HOUSING_UPDATE_C:
            return {
                ...state,
                ...{
                    settings: action.payload,
                },
            };

        case ESTIMATION_HOUSING_VALIDATION_SUCCESS_C:
            return {
                ...state,
                housingSuccess: true,
                housingError: false,
                housingErrorsList: {},
            };

        case ESTIMATION_HOUSING_VALIDATION_ERROR_C:
            return {
                ...state,
                housingSuccess: false,
                housingError: true,
                housingErrorsList: action.payload,
            };

        case ESTIMATION_BENEFIT_UPDATE_C:
            return {
                ...state,
                ...{ settings: action.payload },
            };
        case ESTIMATION_SERVICE_ADDITION:
            return {
                ...state,
                ...{ houseworkServiceAddition: action.payload },
            };

        case ESTIMATION_BENEFIT_VALIDATION_SUCCESS_C:
            return {
                ...state,
                benefitSuccess: true,
                benefitError: false,
                benefitErrorsList: {},
            };
        case ESTIMATION_BENEFIT_VALIDATION_ERROR_C:
            return {
                ...state,
                benefitSuccess: false,
                benefitError: true,
                benefitErrorsList: action.payload,
            };
        case ESTIMATION_PERSONAL_DATA_UPDATE_C:
            return {
                ...state,
                ...{ settings: action.payload },
            };
        case FETCH_ESTIMATION_C:
            return {
                ...state,
                ...{ calculation: action.payload },
            };
        case FETCH_NEW_ESTIMATION_C:
            const { estimation, categoryId, sizeId, specificity } =
                action.payload;
            return {
                ...state,
                ...{
                    calculation: estimation,
                    settings: {
                        ...state.settings,
                        housingCategoryId: categoryId,
                        housingSizeId: sizeId,
                        housingSpecificity: specificity,
                    },
                },
            };
        case ESTIMATION_NEW_REQUEST_C:
            return {
                ...state,
                ...{ settings: action.payload },
                housingSuccess: action.payload.housingSuccess,
                twiceOrMoreRequests: action.payload.twiceOrMoreRequests,
                proId: action.payload.proId,
                proData: action.payload.proData,
            };
        case GET_LOCKED_DATE_C:
            return {
                ...state,
                lockedDates: [...state.lockedDates, ...action.payload],
            };
        default:
            return {
                ...state,
            };
    }
};

export default estimationReducer;
