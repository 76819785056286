import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { useDispatch, useStore } from "react-redux";
import {
    estimationHousingUpdate,
    fetchEstimation,
} from "../../../../../common/store/actions/estimationConciergeAction";
import {
    estimationBenefitUpdate,
    fetchMoreThanOneEstimate,
} from "../../../../../common/store/actions/estimationConciergeAction";

const Counter = ({
    description,
    iconSrc,
    name,
    title,
    hasBathtubOrShower,
    specLogement,
    bed,
}) => {
    const dispatch = useDispatch();
    const store = useStore();
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (bed !== true) {
            setCount(
                store.getState().estimationConcierge.settings
                    .housingSpecificity[name]
            );
        } else {
            setCount(
                store.getState().estimationConcierge.settings
                    .houseworkPersonalization[name]
            );
        }
    }, [bed]);
    const updateStore = (newCount, bed) => {
        if (bed === true) {
            const twiceOrMoreRequests =
                store.getState().estimationConcierge.twiceOrMoreRequests;

            let requestBody = {};

            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    [name]: newCount,
                },
            };

            estimationBenefitUpdate(requestBody)(dispatch);

            if (twiceOrMoreRequests) {
                const access_token = store.getState().auth.access_token;
                const categoryId =
                    store.getState().auth.user.housing.category.id;
                const sizeId =
                    store.getState().auth.user.housing.id !== 1
                        ? store.getState().auth.user.housing.size.id
                        : null;
                const specificity =
                    store.getState().auth.user.housing.specificity;
                fetchMoreThanOneEstimate(
                    requestBody,
                    access_token,
                    categoryId,
                    sizeId,
                    specificity,
                    false
                )(dispatch);
            } else {
                fetchEstimation(requestBody)(dispatch);
            }
        } else {
            let requestBody = {
                ...store.getState().estimationConcierge.settings,
                housingSpecificity: {
                    ...store.getState().estimationConcierge.settings
                        .housingSpecificity,
                    [name]: newCount,
                },
            };

            estimationHousingUpdate(requestBody)(dispatch);
            fetchEstimation(requestBody)(dispatch);
        }
    };

    const handleCounterLessClick = (countt, bed) => {
        const newCount = countt > 0 ? countt - 1 : 0;

        setCount(newCount);
        updateStore(newCount, bed);
    };

    const handleCounterMoreClick = (countt, bed) => {
        const newCount = countt + 1;

        setCount(newCount);
        updateStore(newCount, bed);
    };

    if (hasBathtubOrShower === undefined) {
        hasBathtubOrShower = false;
    }

    return (
        <div className="counter_container">
            <div className="counter_header">
                <img src={iconSrc} alt={title} />
                <h6
                    style={{
                        color:
                            hasBathtubOrShower && count === 0 ? "red" : "black",
                    }}
                >
                    {title}
                </h6>
            </div>

            <div className="counter">
                <button
                    disabled={specLogement.Min === count}
                    className="btn_blue_bg"
                    onClick={() =>
                        bed === true
                            ? handleCounterLessClick(
                                  store.getState().estimationConcierge.settings
                                      .houseworkPersonalization[name],
                                  bed
                              )
                            : handleCounterLessClick(count, bed)
                    }
                >
                    -
                </button>

                <p>{count}</p>

                <button
                    disabled={specLogement.Max === count}
                    className="btn_blue_bg"
                    onClick={() =>
                        bed === true
                            ? handleCounterMoreClick(
                                  store.getState().estimationConcierge.settings
                                      .houseworkPersonalization[name],
                                  bed
                              )
                            : handleCounterMoreClick(count, bed)
                    }
                >
                    +
                </button>
            </div>

            <p className="counter_description">{description}</p>
        </div>
    );
};

export default withTranslation()(Counter);
