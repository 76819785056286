import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
    estimationBenefitUpdate,
    fetchEstimation,
    fetchMoreThanOneEstimate,
} from "../../../../../../common/store/actions/estimationConciergeAction";
import varLogement from "../../../../../../constant.json";
import {
    FormControlLabel,
    Checkbox,
    FormControl,
    Typography,
    Box,
    Grid,
    makeStyles,
} from "@material-ui/core";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import OptionsCard from "../../section-parts/OptionsCard";
import { findClosestDate, generateCurrentDateToString } from "../../utils/date";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr-CA";
import Counter from "../../section-parts/Counter";
import { Button } from "react-bootstrap";
import { set } from "date-fns";

registerLocale("fr", fr);

const weekDayMapping = { Su: 1, Mo: 2, Tu: 3, We: 4, Th: 5, Fr: 6, Sa: 7 };
const reverseWeekDayMapping = ["", "Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const initialDays = {
    Su: false,
    Mo: false,
    Tu: false,
    We: false,
    Th: false,
    Fr: false,
    Sa: false,
    selected: 0,
};

const useStyle = makeStyles((theme) => ({
    errorMorning: {
        color: "#ff3e3e",
    },
    cardStyle: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "210px",
    },
    fabStyle: {
        position: "absolute",
        top: "-50px",
        right: "-56px",
        "& button": {
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
            fontSize: "16px",
            fontWeight: "bold",
        },
        zIndex: 1,
    },
    fabStyleFive: {
        right: "-50px",
        "& button": {
            width: "46px",
            height: "46px",
        },
    },
    fabStyleActive: {
        "& button": {
            color: theme.palette.primary.main,
            backgroundColor: "#fff",
        },
    },
    bedsCheckbox: {
        display: "block",
        marginRight: "500px",
    },
    Checkbox: {
        "& .Mui-checked": {
            color: "#FFD74F",
        },
    },
    Box1: {
        padding: "20px",

        "& .MuiBox-root-167": {
            "& .MuiAccordionSummary-expandIcon": {
                color: "#FFD74F",
            },
            "& .MuiAccordionDetails-root": {
                display: "contents !important",
            },
        },
        "& .MuiAccordionDetails-root": {
            display: "contents !important",
        },
        "& .MuiAccordionSummary-expandIcon": {
            color: "#FFD74F !important",
        },
        "& .MuiBox-root-178": {
            "& .MuiAccordionSummary-expandIcon": {
                color: "#FFD74F",
            },
            "& .MuiAccordionDetails-root": {
                display: "contents !important",
            },
        },
        "& .MuiBox-root-189": {
            "& .MuiAccordionSummary-expandIcon": {
                color: "#FFD74F",
            },
            "& .MuiAccordionDetails-root": {
                display: "contents !important",
            },
        },
    },
    keyBox: {
        backgroundColor: "#f4f4f4",
        padding: "20px",
        marginBottom: "20px",
        "& .MuiAccordion-root.Mui-expanded:last-child": {
            width: "60%",
            marginLeft: "10px",
        },
        "& .MuiAccordion-rounded:last-child": {
            width: "60%",
        },
    },
    date_box: {
        backgroundColor: "#fff",
        marginBottom: "30px",
        // marginLeft: "20px",

        "& .MuiGrid-container": {
            marginLeft: "20px",
            "& .MuiGrid-grid-lg-6": {
                marginTop: "20px",
                //width: "200px",
                "& .MuiBox-root-195": {
                    //  width: "100px",
                },

                // "& .MuiBox-root-184": {
                //     width: "100px",
                // },
                // "& .MuiBox-root-177": {
                //     width: "100px",
                // },

                "& .MuiBox-root-159": {
                    marginTop: "20px",
                },
                "& .MuiBox-root-165": {
                    marginTop: "20px",
                    width: "80px",
                    "& .MuiBox-root-168": {
                        width: "80px",
                    },
                },
                "& .MuiBox-root-169": {
                    marginTop: "20px",
                },
            },

            "& .MuiStepIcon-active": {
                color: "#FFD74F",
            },
        },
        "& .MuiBox-root-161": {
            marginLeft: "10px",
        },
        "& .MuiBox-root-162": {
            marginLeft: "10px",
        },
        "& .MuiBox-root-158": {
            marginLeft: "10px",
        },
        "& .MuiBox-root-159": {
            marginLeft: "10px",
        },
    },
    oneDate: {
        backgroundColor: "#fff",

        "& .MuiBox-root-163": {
            marginLeft: "10px",
        },
        "& .MuiBox-root-162": {
            marginLeft: "10px",
        },
        "& .MuiGrid-container": {
            marginLeft: "20px",
            "& .MuiStepIcon-active": {
                color: "#FFD74F",
            },
            "& .MuiGrid-grid-lg-6": {
                marginTop: "45px",
                "& .MuiCheckbox-colorPrimary.Mui-checked": { color: "#FFD74F" },
            },
        },
    },
    firstDate: {
        display: "flex",
        backgroundColor: "#fff",
        marginBottom: "30px",
        marginLeft: "20px",
    },
    btn: {
        fontSize: "16px",
        textDecoration: "underline",
        color: "#489DD0",
        textTransform: "inherit",
        fontfamily: "Avenir_Regular",
        padding: "6px 0",
        background: "none",
        float: "right",
        marginLeft: "100px",
        marginTop: "26px",
        "&:hover": {
            backgroundColor: "#fff !important",
            color: "#489DD0",
        },
    },
    btnKey: {
        height: "50px",
        marginTop: "10px",
        borderBottom: "none !important",
        marginBottom: "5px",
        padding: "10px",
        marginTop: "5px",
        display: "flex !important",
        verticalAlign: "none",

        background: "transparent",
        backgroundColor: "#fff",
        color: "#000",
        "&:hover": {
            backgroundColor: "#fff !important",
            color: "#FFD74F",
        },
        "&:focus": {
            height: "50px",
            padding: "14px 20px",
            backgroundColor: "#FFD74F !important",
            color: "#fff",
            border: "#FFD74F",
        },
    },
    btnError: {
        height: "30px",
        marginTop: "10px",
        borderBottom: "none !important",
        marginBottom: "20px",
        display: "flex !important",
        verticalAlign: "none",
        padding: "0px",
        background: "transparent",
        backgroundColor: "#fff",
        color: "#ff3e3e",
        "&:hover": {
            backgroundColor: "#fff !important",
            color: "#FFD74F",
        },
        "&:focus": {
            height: "70px",
            padding: "14px 20px",
            backgroundColor: "#FFD74F !important",
            color: "#fff",
            border: "#FFD74F",
        },
    },
    LaundryError: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "#ff3e3e",
    },
    addClass: {
        display: "flex",
        backgroundColor: "#fff",
        marginBottom: "30px",
        marginLeft: "20px",
    },
    recuperKey: {
        display: "flex",
        backgroundColor: "#fff",
        marginBottom: "30px",
        marginLeft: "20px",
        marginTop: "30px",
    },
}));

const Benefit = ({ t }) => {
    const classes = useStyle();

    const store = useStore();
    const dispatch = useDispatch();
    const { houseworkPersonalization } = varLogement
    const [datesSelection, setDatesSelection] = useState({
        frequency: 1,
        days: { ...initialDays },
        date: null,
        hours: [],
    });
    //const [optionKey1, setoptionKey1]=useState(false)
    const [expanded, setExpanded] = React.useState(false);
    const [errors, setErrors] = useState({});
    const [date1, setDate1] = useState({
        frequency: 1,
        days: { ...initialDays },
        date: null,
        hours: [],
    });
    const [date2, setDate2] = useState({
        frequency: 1,
        days: { ...initialDays },
        date: null,
        hours: [],
    });
    const [date3, setDate3] = useState({
        frequency: 1,
        days: { ...initialDays },
        date: null,
        hours: [],
    });
    const [suppliesAcceptation, setSuppliesAcceptation] = useState(false);
    const [Banderie, setBanderie] = useState(false);
    const [proCriterion, setproCriterion] = useState(null);
    const [needSuppliesAcceptation, setNeedSuppliesAcceptation] = useState({
        product_ecological: false,
        product_standard: false,
    });
    const [concierge_key, setconcierge_key] = useState(false);

    const [valetChoice, setValetChoice] = useState({
        valet: 1,
        product_standard: false,
    });

    const [oneDate, setoneDate] = useState(false);

    const [vacuumAcceptation, setVacuumAcceptation] = useState(false);
    const [needVacuumAcceptation, setNeedVacuumAcceptation] = useState(false);
    const [displayCalendarPro, setDisplayCalendarPro] = useState(false);
    let houseworkWeekTime =
        store.getState().estimationConcierge.settings.houseworkWeekTime;
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const local = lang === "fr" ? "fr" : "en";

    useEffect(() => {
        window.scrollTo(0, 0);

        const houseworkFrequencyId =
            store.getState().estimationConcierge.settings
                .houseworkFrequencyId ?? 1;
        const houseworkWeekTime =
            store.getState().estimationConcierge.settings.houseworkWeekTime;
        const startDate =
            store.getState().estimationConcierge.settings.startDate;
        const twiceOrMoreRequests =
            store.getState().estimationConcierge.twiceOrMoreRequests;
        const chosenDates = {
            frequency: 1,
            days: { ...initialDays },
            date: null,
            hours: [],
        };

        chosenDates.frequency = houseworkFrequencyId ?? 1;
        chosenDates.date = startDate ?? null;

        if (houseworkWeekTime && houseworkWeekTime.length) {
            chosenDates.days[houseworkWeekTime[0]["weekDay"]] = true;

            if (houseworkWeekTime.length === 2)
                chosenDates.days[houseworkWeekTime[1]["weekDay"]] = true;

            chosenDates.days["selected"] = houseworkWeekTime.length;
            chosenDates.hours = houseworkWeekTime;
        }

        store.subscribe(() => {
            setErrors(store.getState().estimationConcierge.benefitErrorsList);
            setNeedSuppliesAcceptation({
                product_ecological:
                    store.getState().estimationConcierge.settings
                        .houseworkPersonalization.product_ecological,
                product_standard:
                    store.getState().estimationConcierge.settings
                        .houseworkPersonalization.product_standard,
            });

            setNeedVacuumAcceptation(
                !store.getState().estimationConcierge.settings
                    .houseworkPersonalization.vacuum
            );
        });

        const requestBody = {
            ...store.getState().estimationConcierge.settings,
            houseworkWeekTime: houseworkWeekTime ?? chosenDates.hours,
            houseworkFrequencyId: chosenDates.frequency,
            startDate: chosenDates.date,
        };

        //setDate1({ ...chosenDates });
        // setDate2({ ...chosenDates });
        // setDate3({ ...chosenDates });

        if (houseworkWeekTime !== undefined) {
            if (houseworkWeekTime.length > 0) {
                if (houseworkWeekTime.length === 3) {
                    setDate1({
                        frequency: 1, //houseworkWeekTime[2].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[2].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[2],
                            },
                        ],
                    });
                    setDate2({
                        frequency: 1, //houseworkWeekTime[1].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[1].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[1],
                            },
                        ],
                    });
                    setDate3({
                        frequency: 1, //houseworkWeekTime[0].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[0].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[0],
                            },
                        ],
                    });
                }
                if (houseworkWeekTime.length === 2) {
                    setDate1({
                        frequency: houseworkWeekTime[1].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[1].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[1],
                            },
                        ],
                    });
                    setDate2({
                        frequency: houseworkWeekTime[0].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[0].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[0],
                            },
                        ],
                    });
                }
                if (houseworkWeekTime.length === 1) {
                    setDate1({
                        frequency: houseworkWeekTime[0].period,
                        days: { ...initialDays },
                        date: houseworkWeekTime[0].weekDate,
                        hours: [
                            {
                                ...houseworkWeekTime[0],
                            },
                        ],
                    });
                }
            } else {
                setDate1({ ...chosenDates });
            }
        } else {
            setDate1({ ...chosenDates });
        }

        setDatesSelection({ ...chosenDates });

        estimationBenefitUpdate(requestBody)(dispatch);
        fetchEstimation(requestBody)(dispatch);
        if (twiceOrMoreRequests) {
            const access_token = store.getState().auth.access_token;
            const categoryId = store.getState().auth.user.housing.category.id;
            const sizeId =
                store.getState().auth.user.housing.size !== null
                    ? store.getState().auth.user.housing.size.id
                    : null;
            const specificity = store.getState().auth.user.housing.specificity;
            const proId = store.getState().estimationConcierge.proId;
            const proCriterian =
                store.getState().estimationConcierge.settings.proData
                    .proCriterion;
            setproCriterion(proCriterian);

            fetchMoreThanOneEstimate(
                requestBody,
                access_token,
                categoryId,
                sizeId,
                specificity,
                true
            )(dispatch);
        } else {
            fetchEstimation(requestBody)(dispatch);
        }
    }, [dispatch, store]);

    useEffect(() => {
        if (date1.date !== null) compareDates();
    }, [date1]);

    useEffect(() => {
        if (houseworkWeekTime !== undefined) {
            if (houseworkWeekTime.length < 3) {
                if (date1.date !== null && date2.date === null) {
                    let requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        houseworkWeekTime: [
                            ...store.getState().estimationConcierge.settings
                                .houseworkWeekTime,
                        ],
                    };

                    estimationBenefitUpdate({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                    fetchEstimation({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                } else if (date2.date !== null && date3.date === null) {
                    let house = date1.hours[0];
                    let house2 = date2.hours[0];
                    let requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        houseworkWeekTime: [
                            ...store.getState().estimationConcierge.settings
                                .houseworkWeekTime,
                            {
                                ...house,
                            },
                        ],
                    };

                    estimationBenefitUpdate({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                    fetchEstimation({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                } else if (date2.date !== null && date3.date !== null) {
                    let house = date1.hours[0];
                    let house2 = date2.hours[0];
                    let requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        houseworkWeekTime: [
                            ...store.getState().estimationConcierge.settings
                                .houseworkWeekTime,
                            {
                                ...house2,
                            },
                            {
                                ...house,
                            },
                        ],
                    };

                    estimationBenefitUpdate({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                    fetchEstimation({
                        ...requestBody,
                        ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
                    })(dispatch);
                }
            }
        }
    }, [date1, date3, date2]);

    const compareDates = () => {
        if (date1.date !== null) {
            let requestBody = {};
            var today = new Date();

            var dd = String(today.getDate()).padStart(2, "0");

            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + "-" + mm + "-" + dd;
            const date = new Date(today);

            date.setDate(date.getDate() + 2);
            var dd = String(date.getDate()).padStart(2, "0");

            var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = date.getFullYear();

            today = yyyy + "/" + mm + "/" + dd;

            if (date1.date !== null)
                if (today === date1.date) {
                    requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        houseworkServiceAddition: {
                            ...store.getState().estimationConcierge.settings
                                .houseworkServiceAddition,
                            less_48h_key: true,
                        },
                    };
                    estimationBenefitUpdate(requestBody)(dispatch);
                } else {
                    requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        houseworkServiceAddition: {
                            ...store.getState().estimationConcierge.settings
                                .houseworkServiceAddition,
                            less_48h_key: false,
                        },
                    };
                    estimationBenefitUpdate(requestBody)(dispatch);
                }
            fetchEstimation(requestBody)(dispatch);
        } else return;
    };

    const validationTime = () => {
        if (
            store.getState().estimationConcierge.calculation.totalDuration !== 0
        ) {
            const durationArray = store
                .getState()
                .estimationConcierge.calculation.totalDuration.split(":");
            const totalDuration =
                parseInt(durationArray[0], 10) * 60 +
                parseInt(durationArray[1], 10);

            if (totalDuration > 300) {
                return true;
            }
        }
        return false;
    };

    const displayFormattedStringDateByLocale = (date) => {
        if (!date) return;

        const splittedDate = date.split("/");

        if (local === "en")
            return (
                splittedDate[1] + "/" + splittedDate[2] + "/" + splittedDate[0]
            );
        else
            return (
                splittedDate[2] + "/" + splittedDate[1] + "/" + splittedDate[0]
            );
    };

    // const handleDayChange = (event, value) => {
    //     const name = event.target.name;
    //     const requestBody = {
    //         ...store.getState().estimationConcierge.settings,
    //     };

    //     let selectedDays = 0;

    //     if (
    //         datesSelection.days.selected === 2 &&
    //         datesSelection.days[name] === false
    //     )
    //         return;

    //     if (datesSelection.days[name]) {
    //         selectedDays = datesSelection.days.selected - 1;
    //         requestBody.houseworkWeekTime.splice(
    //             requestBody.houseworkWeekTime.findIndex(
    //                 (element) => element.weekDay === name
    //             ),
    //             1
    //         );
    //     } else {
    //         selectedDays = datesSelection.days.selected + 1;
    //         requestBody.houseworkWeekTime.push({
    //             weekDay: name,
    //             weekDate: datesSelection.date
    //                 ? findClosestDate(weekDayMapping[name], datesSelection.date)
    //                 : "",
    //             period: "",
    //         });
    //     }

    //     datesSelection.days.selected = selectedDays;
    //     datesSelection.days[name] = value;

    //     setDatesSelection({ ...datesSelection });
    //     estimationBenefitUpdate(requestBody)(dispatch);
    // };

    const handleHourChange = (event, dateName, nameObj) => {
        let name = "";
        let value = "";

        if (event.target.checked) {
            name = event.target.name.split("-")[0];
            value = event.target.name.split("-")[1];
        }

        let requestBody = {
            ...store.getState().estimationConcierge.settings,
        };

        // requestBody.houseworkWeekTime = requestBody.houseworkWeekTime.map(
        //     (element) => {
        //         if (element.weekDay === name) element.period = value;
        //         return element;
        //     }
        // );

        // requestBody.houseworkWeekTime.map((element) => {
        //     if (element.weekDay === name) element.period = value;
        //     return element;
        // });

        requestBody.houseworkWeekTime.map((element) => {
            if (element.weekDate === dateName.hours[0].weekDate)
                element.period = value;
            return element;
        });

        // const man = requestBody.houseworkWeekTime.filter(
        //     (row) => row.weekDate === dateName.hours[0].weekDate
        // );

        // if (nameObj === "date1") {
        //     setDate1({
        //         ...date1,
        //         hours: requestBody.houseworkWeekTime,
        //     });
        // } else if (nameObj === "date2") {
        //     setDate2({
        //         ...date2,
        //         hours: requestBody.houseworkWeekTime,
        //     });
        // } else if (nameObj === "date3") {
        //     setDate3({
        //         ...date3,
        //         hours: requestBody.houseworkWeekTime,
        //     });
        // } else {
        //     setDatesSelection({
        //         ...datesSelection,
        //         hours: requestBody.houseworkWeekTime,
        //     });
        // }

        // if (date3.date !== null && date2.date !== null && date1.date !== null) {
        //     let house = date1.hours[0];
        //     let house2 = date2.hours[0];
        //     let house3 = date3.hours[0];
        //     requestBody = {
        //         ...store.getState().estimationConcierge.settings,
        //         houseworkWeekTime: [
        //             ...store.getState().estimationConcierge.settings
        //                 .houseworkWeekTime,

        //         ],
        //     };
        // } else if (
        //     date3.date === null &&
        //     date2.date !== null &&
        //     date1.date !== null
        // ) {
        //     let house = date1.hours[0];
        //     let house2 = date2.hours[0];
        //     requestBody = {
        //         ...store.getState().estimationConcierge.settings,
        //         houseworkWeekTime: [
        //             ...store.getState().estimationConcierge.settings
        //                 .houseworkWeekTime,
        //         ],
        //     };
        // }
        estimationBenefitUpdate({
            ...requestBody,
        })(dispatch);
        fetchEstimation({
            ...requestBody,
        })(dispatch);
    };

    // const handleHourByRecomanderPro = (name, value) => {
    //     const requestBody = {
    //         ...store.getState().estimationConcierge.settings,
    //     };

    //     requestBody.houseworkWeekTime = requestBody.houseworkWeekTime.map(
    //         (element) => {
    //             if (element.weekDay === name) element.period = value;
    //             return element;
    //         }
    //     );

    //     setDatesSelection({
    //         ...datesSelection,
    //         hours: requestBody.houseworkWeekTime,
    //     });
    //     estimationBenefitUpdate({
    //         ...requestBody,
    //         ...{ houseworkWeekTime: requestBody.houseworkWeekTime },
    //     })(dispatch);
    // };

    const handleDateChange = (event, dateName) => {
        if (!event) return;

        const startDateString = generateCurrentDateToString(event);
        const weekDay = new Date(event).getDay() + 1;
        const requestBody = {
            ...store.getState().estimationConcierge.settings,
            startDate: startDateString,
        };

        if (datesSelection.frequency === 1) {
            if (datesSelection.hours.length) requestBody.houseworkWeekTime = [];

            requestBody.houseworkWeekTime.push({
                weekDay: reverseWeekDayMapping[weekDay],
                weekDate: findClosestDate(weekDay, event),
                period: "",
            });

            datesSelection.days = {
                ...initialDays,
                [reverseWeekDayMapping[weekDay]]: true,
            };
        } else if (requestBody.houseworkWeekTime.length) {
            requestBody.houseworkWeekTime = requestBody.houseworkWeekTime.map(
                (element) => {
                    element.weekDate = findClosestDate(
                        weekDayMapping[element.weekDay],
                        event
                    );
                    return element;
                }
            );
        }

        datesSelection.date = startDateString.replace(/-/g, "/");
        datesSelection.hours = requestBody.houseworkWeekTime;

        if (dateName === "date1") {
            setDate1({ ...datesSelection });
            setDate2({
                frequency: 1,
                days: { ...initialDays },
                date: null,
                hours: [],
            });
            setDate3({
                frequency: 1,
                days: { ...initialDays },
                date: null,
                hours: [],
            });
        } else if (dateName === "date2") {
            setDate2({ ...datesSelection });
            setDate3({
                frequency: 1,
                days: { ...initialDays },
                date: null,
                hours: [],
            });
        } else if (dateName === "date3") {
            setDate3({ ...datesSelection });
        } else {
            setDatesSelection({ ...datesSelection });
        }
        estimationBenefitUpdate(requestBody)(dispatch);
    };

    const handleSuppliesAcceptationChange = (event) => {
        const requestBody = {
            ...store.getState().estimationConcierge.settings,
            suppliesAcceptation: !suppliesAcceptation,
        };

        setSuppliesAcceptation(!suppliesAcceptation);
        estimationBenefitUpdate(requestBody)(dispatch);
    };

    const handleVacuumAcceptationChange = (event) => {
        const requestBody = {
            ...store.getState().estimationConcierge.settings,
            vacuumAcceptation: !vacuumAcceptation,
        };

        setVacuumAcceptation(!vacuumAcceptation);
        estimationBenefitUpdate(requestBody)(dispatch);
    };
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleOneDateChange = (event) => {
        // const requestBody = {
        //     ...store.getState().estimationConcierge.settings,
        //     vacuumAcceptation: !vacuumAcceptation,
        // };

        setoneDate(!oneDate);

        let requestBody = {
            ...store.getState().estimationConcierge.settings,
            houseworkWeekTime: [],
            startDate: "",
            houseworkServiceAddition: {
                ...store.getState().estimationConcierge.settings
                    .houseworkServiceAddition,
                less_48h_key: false,
            },
        };

        estimationBenefitUpdate({
            ...requestBody,
        })(dispatch);

        fetchEstimation({
            ...requestBody,
        })(dispatch);

        setDate1({
            frequency: 1,
            days: { ...initialDays },
            date: null,
            hours: [],
        });
        setDate2({
            frequency: 1,
            days: { ...initialDays },
            date: null,
            hours: [],
        });
        setDate3({
            frequency: 1,
            days: { ...initialDays },
            date: null,
            hours: [],
        });
        //estimationBenefitUpdate(requestBody)(dispatch);
    };

    const displayHoursFromDays = (dateName, nameObj) => {
        const validationMorning = validationTime();

        let toDisplay = [];
        let html = [];
        let i = 0;

        for (const day in dateName.days) {
            i++;
            if (dateName.days[day] && day !== "selected") {
                toDisplay.push(
                    <FormControl required error={errors?.hours ? true : false}>
                        <Box
                            key={i}
                            className={
                                dateName.frequency !== 1
                                    ? "frequencyTwo"
                                    : "frequencyOne"
                            }
                        >
                            <Box display="flex" flexDirection="column">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                (dateName.hours.find(
                                                    (element) =>
                                                        element.weekDay === day
                                                )?.period ?? "") === "1"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleHourChange(
                                                    e,
                                                    dateName,
                                                    nameObj
                                                )
                                            }
                                            name={`${day}-1`}
                                        />
                                    }
                                    label={t("Client.Time.morning")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                (dateName.hours.find(
                                                    (element) =>
                                                        element.weekDay === day
                                                )?.period ?? "") === "4"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleHourChange(
                                                    e,
                                                    dateName,
                                                    nameObj
                                                )
                                            }
                                            name={`${day}-4`}
                                        />
                                    }
                                    label={t("Client.Time.depart")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                (dateName.hours.find(
                                                    (element) =>
                                                        element.weekDay === day
                                                )?.period ?? "") === "2"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleHourChange(
                                                    e,
                                                    dateName,
                                                    nameObj
                                                )
                                            }
                                            name={`${day}-2`}
                                        />
                                    }
                                    label={t("Client.Time.afternoon")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={
                                                (dateName.hours.find(
                                                    (element) =>
                                                        element.weekDay === day
                                                )?.period ?? "") === "3"
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) =>
                                                handleHourChange(
                                                    e,
                                                    dateName,
                                                    nameObj
                                                )
                                            }
                                            name={`${day}-3`}
                                        />
                                    }
                                    label={t("Client.Time.any")}
                                />
                            </Box>
                        </Box>
                    </FormControl>
                );
                if (dateName.hours.length > 0) {
                    const valWeek = dateName.hours.find(
                        (element) =>
                            element.weekDay === day && element.period === 1
                    );
                    if (valWeek !== undefined) {
                        if (valWeek.weekDay === day && validationMorning) {
                            toDisplay.push(
                                <Box className={classes.errorMorning}>
                                    {t("Client.Validation.validation_morning")}
                                </Box>
                            );
                        }
                    }
                }
            }
        }

        html.push(
            <Box display="flex" flexDirection="column" width="100%">
                {toDisplay}
            </Box>
        );
        return html;
    };

    const displayCalendar = () => {
        let toDisplay = [];

        for (const day in datesSelection.days) {
            if (datesSelection.days[day] && day !== "selected") {
                toDisplay.push(
                    <Box className="calendar_wrapper">
                        <p>Date</p>
                        <DatePicker
                            className="one_shot_date"
                            dateFormat="MM/dd/yyyy"
                            id="date-picker-inline"
                            value={displayFormattedStringDateByLocale(
                                datesSelection.date
                            )}
                            minDate={new Date()}
                            onChange={handleDateChange}
                            locale={local}
                        />
                        <CalendarTodayIcon />
                    </Box>
                );

                return toDisplay;
            }
        }

        return toDisplay;
    };

    const startDate = () => {
        let date = new Date();
        date = new Date(date.getTime() + 86400000); //86400000 day in ms

        return date;
    };

    const endDate = () => {
        let date = new Date();
        date = new Date(date.getTime() + 86400000 * 30); //86400000 day in ms
        return date;
    };

    const excludeDates = () => {
        // const lockedDates = store.getState().estimationConcierge.lockedDates;
        // return lockedDates;
        let tab = [];
        let time = "01:00";

        if (date1.date !== null) {
            tab.push(Math.floor(new Date(date1.date).getTime()));
        }

        if (date2.date !== null)
            tab.push(Math.floor(new Date(date2.date).getTime()));
        if (date3.date !== null)
            tab.push(Math.floor(new Date(date3.date).getTime()));

        return tab;
    };

    const getValue = (Date) => {
        let date = null;
        if (houseworkWeekTime !== undefined)
            if (houseworkWeekTime.length === 1) {
                if (Date.includes("1")) {
                    date = houseworkWeekTime[0].weekDate;
                }
            } else if (houseworkWeekTime.length === 2) {
                if (Date.includes("1")) {
                    date = houseworkWeekTime[1].weekDate;
                }
                if (Date.includes("2")) {
                    date = houseworkWeekTime[0].weekDate;
                }
            } else if (houseworkWeekTime.length === 3) {
                if (Date.includes("1")) {
                    date = houseworkWeekTime[2].weekDate;
                }
                if (Date.includes("2")) {
                    date = houseworkWeekTime[1].weekDate;
                }
                if (Date.includes("3")) {
                    date = houseworkWeekTime[0].weekDate;
                }
            }

        if (displayFormattedStringDateByLocale(date) === null) {
            return displayFormattedStringDateByLocale(date);
        } else {
            return date;
        }
    };

    const BanderieNeed = (event) => {
        let requestBody = {};
        if (event.target.checked) {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    valet: 0,
                    sofa_bed: 0,
                    double_bed: 0,
                    single_bed: 0,
                    queen_bed: 0,
                    king_bed: 0,
                    super_bed: 0,
                    bebe_bed: 0,
                    banderie: true,
                },
            };
            estimationBenefitUpdate(requestBody)(dispatch);
        } else {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkPersonalization: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkPersonalization,
                    banderie: false,
                },
            };
            estimationBenefitUpdate(requestBody)(dispatch);
        }
        setBanderie((Banderie) => !Banderie);
    };
    const handleClickKey = (option) => {
        let requestBody = {};
        if (option === "find_key")
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkServiceAddition: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkServiceAddition,
                    in_box_key: false,
                    find_key: true,
                    on_site_key: false,
                },
            };
        else if (option === "on_site_key")
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkServiceAddition: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkServiceAddition,
                    in_box_key: false,
                    find_key: false,
                    on_site_key: true,
                },
            };
        else if (option === "in_box_key") {
            requestBody = {
                ...store.getState().estimationConcierge.settings,
                houseworkServiceAddition: {
                    ...store.getState().estimationConcierge.settings
                        .houseworkServiceAddition,
                    in_box_key: true,
                    find_key: false,
                    on_site_key: false,
                },
            };
        }
        estimationBenefitUpdate(requestBody)(dispatch);
        fetchEstimation(requestBody)(dispatch);
    };

    return (
        <Box className="HousingType">
            <Box className="options_box">
                <Typography variant="h3" className="HousingType__title">
                    {t("Client.Benefit.title2")}
                </Typography>

                <h6
                    style={{
                        marginBottom: "30px",
                        fontSize: "1.9rem",
                        fontWeight: "bold",
                    }}
                >
                    {t("Client.Benefit.section2_desc")}
                </h6>

                <div
                    className={
                        errors.laundry_room
                            ? classes.LaundryError
                            : "options_list"
                    }
                >
                    <OptionsCard
                        error={errors.laundry_room}
                        name="laundry_room"
                        id="1"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_bed1"
                        )}
                        description={t("Client.Benefit.section2_option3_desc1")}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        proCriterion={proCriterion ? proCriterion.valet1 : null}
                        iconSrc="images/literie.svg"
                        Banderie={!Banderie}
                    />
                    <OptionsCard
                        error={errors.laundry_room}
                        name="laundry_room"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_bed2"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        description={t("Client.Benefit.section2_option3_desc2")}
                        proCriterion={proCriterion ? proCriterion.valet2 : null}
                        iconSrc="images/tumble-dry.svg"
                        iconUrl="images/wash.svg"
                        Banderie={!Banderie}
                        id="2"
                    />

                    <OptionsCard
                        error={errors.laundry_room}
                        name="laundry_room"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_bed3"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        proCriterion={proCriterion ? proCriterion.bed : null}
                        iconSrc="images/delivery-truck.svg"
                        description={t("Client.Benefit.section2_option3_desc3")}
                        Banderie={!Banderie}
                        id="3"
                    />
                </div>

                <Box marginTop={"80px"}>
                    <Typography variant="h3" className="HousingType__title">
                        {t("Client.Benefit.title4")}
                    </Typography>
                </Box>

                <Box
                    display={"flex"}
                    flexWrap={"wrap"}
                    justifyContent={"flex-start !important"}
                    marginBottom={"80px"}
                >
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="sofa_bed"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_sofa_bed"
                            )}
                            iconSrc="images/sofa.svg"
                            specLogement={houseworkPersonalization.sofa_bed}
                        />
                    </Box>
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="double_bed"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_double_bed"
                            )}
                            iconSrc="images/double.svg"
                            specLogement={houseworkPersonalization.double_bed}
                        />
                    </Box>
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="single_bed"
                            iconSrc="images/single-bed.svg"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_single_bed"
                            )}
                            specLogement={houseworkPersonalization.single_bed}
                        />
                    </Box>
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="queen_bed"
                            iconSrc="images/queen.svg"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_queen_bed"
                            )}
                            specLogement={houseworkPersonalization.queen_bed}
                        />
                    </Box>
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="king_bed"
                            iconSrc="images/king.svg"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_king_bed"
                            )}
                            specLogement={houseworkPersonalization.king_bed}
                        />
                    </Box>
                    <Box marginRight={"40px"}>
                        <Counter
                            bed={true}
                            name="super_bed"
                            iconSrc="images/super.svg"
                            title={t(
                                "Client.Benefit.houseworkPersonalization_super_bed"
                            )}
                            specLogement={houseworkPersonalization.super_bed}
                        />
                    </Box>
                    <Counter
                        bed={true}
                        name="bebe_bed"
                        iconSrc="images/bebe.svg"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_bebe_bed"
                        )}
                        specLogement={houseworkPersonalization.bebe_bed}
                    />
                </Box>
                <Box>
                    <Typography variant="h3" className="HousingType__title">
                        {t("Client.Benefit.title5")}
                    </Typography>
                </Box>
                <div className="options_list">
                    <OptionsCard
                        option2={true}
                        name="travel_welcome"
                        title={t(
                            "Client.Benefit.houseworkServiceAddition_Voyageur"
                        )}
                        proCriterion={
                            proCriterion ? proCriterion.voyageur : null
                        }
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        iconSrc="images/valise.svg"
                        description1={t("Client.Benefit.section2_option7_desc")}
                        disabled={true}
                    />
                    <OptionsCard
                        option2={true}
                        name="travel_departure"
                        title={t(
                            "Client.Benefit.houseworkServiceAddition_departures"
                        )}
                        proCriterion={
                            proCriterion ? proCriterion.departures : null
                        }
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        iconSrc="images/departures.svg"
                        description1={t("Client.Benefit.section2_option8_desc")}
                        disabled={true}
                    />
                    <OptionsCard
                        option2={true}
                        proCriterion={
                            proCriterion ? proCriterion.concierge_key : null
                        }
                        name="balcony"
                        title={t("Client.Logement.housingSpecificity_balcony")}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        iconSrc="images/balcon.svg"
                        description1={t(
                            "Client.Benefit.section2_option10_desc"
                        )}
                    />
                    <OptionsCard
                        option2={true}
                        proCriterion={
                            proCriterion ? proCriterion.concierge_key : null
                        }
                        name="terrace"
                        title={t("Client.Logement.housingSpecificity_terrace")}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        iconSrc="images/terrace.svg"
                        description1={t(
                            "Client.Benefit.section2_option11_desc"
                        )}
                    />
                    <OptionsCard
                        option2={true}
                        proCriterion={
                            proCriterion ? proCriterion.concierge_key : null
                        }
                        name="concierge_key"
                        title={t(
                            "Client.Benefit.houseworkServiceAddition_concierge_key"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        iconSrc="images/key-chain.svg"
                        description1={t("Client.Benefit.section2_option9_desc")}
                    />
                    <OptionsCard
                        option2={true}
                        name="product_ecological"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_product_ecological"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        proCriterion={
                            proCriterion
                                ? proCriterion.product_ecological
                                : null
                        }
                        iconSrc="images/icon_eco_spray.svg"
                        description1={t("Client.Benefit.section2_option5_desc")}
                        proActive={needSuppliesAcceptation.product_ecological}
                        needSuppliesAcceptation={needSuppliesAcceptation}
                    />
                    <OptionsCard
                        option2={true}
                        name="product_standard"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_product_standard"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        proCriterion={
                            proCriterion ? proCriterion.product_standard : null
                        }
                        iconSrc="images/icon_spray.svg"
                        description1={t("Client.Benefit.section2_option6_desc")}
                        proActive={needSuppliesAcceptation.product_standard}
                        needSuppliesAcceptation={needSuppliesAcceptation}
                    />
                    <OptionsCard
                        option2={true}
                        name="vacuum"
                        title={t(
                            "Client.Benefit.houseworkPersonalization_vacuum"
                        )}
                        notAuthorized={t("Client.Benefit.notAuthorized")}
                        proCriterion={proCriterion ? proCriterion.vacuum : null}
                        iconSrc="images/icon_vacuum.svg"
                        description1={t("Client.Benefit.section2_option4_desc")}
                    />

                    {!needSuppliesAcceptation.product_ecological &&
                        !needSuppliesAcceptation.product_standard && (
                            <Box className={classes.Checkbox}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={
                                                errors.suppliesAcceptation
                                                    ? "error"
                                                    : ""
                                            }
                                            color="primary"
                                            checked={suppliesAcceptation}
                                            onChange={
                                                handleSuppliesAcceptationChange
                                            }
                                            name="su"
                                        />
                                    }
                                    label={t("Client.Benefit.section2_agree1")}
                                />
                            </Box>
                        )}

                    {needVacuumAcceptation && (
                        <Box className={classes.Checkbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className={
                                            errors.vacuumAcceptation
                                                ? "error"
                                                : ""
                                        }
                                        color="primary"
                                        checked={vacuumAcceptation}
                                        onChange={handleVacuumAcceptationChange}
                                        name="su"
                                    />
                                }
                                label={t("Client.Benefit.section2_agree2")}
                            />
                        </Box>
                    )}
                </div>
            </Box>

            <Box className={classes.Box1}>
                <Typography variant="h3" className="HousingType__title">
                    {t("Client.Benefit.title3")}
                </Typography>
                <Box className={oneDate ? classes.oneDate : classes.date_box}>
                    <Box className={classes.Checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={
                                        errors.vacuumAcceptation ? "error" : ""
                                    }
                                    color="primary"
                                    checked={oneDate}
                                    onChange={handleOneDateChange}
                                    name="oneDate"
                                />
                            }
                            label={t("Client.Benefit.section3_desc1")}
                        />
                    </Box>
                    <Box className={classes.Checkbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={
                                        errors.vacuumAcceptation ? "error" : ""
                                    }
                                    color="primary"
                                    checked={!oneDate}
                                    onChange={handleOneDateChange}
                                    name="multiDate"
                                />
                            }
                            label={t("Client.Benefit.section3_desc3")}
                        />
                    </Box>
                </Box>

                <Box hidden={!oneDate} className={classes.oneDate}>
                    {date1.frequency === 1 && (
                        <>
                            {/* <p>{t("Client.Benefit.section3_desc1")}</p> */}

                            <Grid container>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        mb={4}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            mb={2}
                                        >
                                            <Box mr={2}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                >
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="12"
                                                    ></circle>
                                                    <text
                                                        className="MuiStepIcon-text"
                                                        x="12"
                                                        y="16"
                                                        textAnchor="middle"
                                                    >
                                                        1
                                                    </text>
                                                </svg>
                                            </Box>
                                            <Typography
                                                className={`${
                                                    errors.date ? "error" : ""
                                                }`}
                                                variant="h6"
                                            >
                                                {t(
                                                    "Client.Benefit.section3_option3"
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box marginLeft={"20px"}>
                                            <Box
                                                className="calendar_wrapper"
                                                display={"flex"}
                                            >
                                                <Box
                                                    marginRight={"20px"}
                                                    marginTop={"17px"}
                                                >
                                                    <h5>Date</h5>
                                                </Box>

                                                <DatePicker
                                                    required
                                                    className="one_shot_date"
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    value={getValue("1")}
                                                    minDate={startDate()}
                                                    maxDate={endDate()}
                                                    excludeDates={excludeDates()}
                                                    onChange={(e) =>
                                                        handleDateChange(
                                                            e,
                                                            "date1"
                                                        )
                                                    }
                                                    locale={local}
                                                />
                                                <CalendarTodayIcon />
                                            </Box>
                                            <Box
                                                hidden={
                                                    !store.getState()
                                                        .estimationConcierge
                                                        .settings
                                                        .houseworkServiceAddition
                                                        .less_48h_key
                                                }
                                                width={"250px"}
                                            >
                                                <Box
                                                    color={"#489DD0"}
                                                    fontSize={"1.2rem"}
                                                    marginTop={"10px"}
                                                >
                                                    <ErrorOutlineIcon fontSize="small" />{" "}
                                                    {t("Client.Benefit.error", {
                                                        value:
                                                            store.getState()
                                                                .estimationConcierge
                                                                .calculation
                                                                .less48h !==
                                                            undefined
                                                                ? store.getState()
                                                                      .estimationConcierge
                                                                      .calculation
                                                                      .less48h
                                                                : 0,
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        mb={4}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            mb={2}
                                        >
                                            <Box mr={2}>
                                                <svg
                                                    className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                    aria-hidden="true"
                                                >
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="12"
                                                    ></circle>
                                                    <text
                                                        className="MuiStepIcon-text"
                                                        x="12"
                                                        y="16"
                                                        textAnchor="middle"
                                                    >
                                                        {" "}
                                                        2
                                                    </text>
                                                </svg>
                                            </Box>
                                            <Typography
                                                className={
                                                    errors?.hours
                                                        ? classes.errorMorning
                                                        : ""
                                                }
                                                variant="h6"
                                            >
                                                {t(
                                                    "Client.Benefit.section3_option2"
                                                )}
                                            </Typography>
                                        </Box>

                                        {displayHoursFromDays(date1, "date1")}
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
                {/* ********* date1 *********** */}
                <Box hidden={oneDate} marginBottom={"20px"}>
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                    >
                        {date1.date === null ? (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    className={`${errors.date ? "error" : ""}`}
                                    variant="h6"
                                >
                                    {t("Client.Benefit.section3_option4")}
                                </Typography>
                            </AccordionSummary>
                        ) : (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Box
                                    hidden={oneDate}
                                    className={classes.firstDate}
                                >
                                    <Box
                                        marginRight={"20px"}
                                        marginTop={"37px"}
                                        marginLeft={"20px"}
                                    >
                                        <h5>Date 1</h5>
                                    </Box>
                                    <Box
                                        marginLeft={"30px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        <p>{getValue("1")}</p>
                                    </Box>
                                    <Box
                                        marginLeft={"100px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        {date1.hours.map((e) => {
                                            if (e.period === "1")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.morning"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "2")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.afternoon"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "3")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.any"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "4")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.depart"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else return <p></p>;
                                        })}
                                    </Box>

                                    <Button className={classes.btn}>
                                        {" "}
                                        <p>
                                            {" "}
                                            {t(
                                                "Client.Benefit.section3_Btn"
                                            )}{" "}
                                        </p>{" "}
                                    </Button>
                                </Box>
                                {/* <Typography className={classes.heading}>Accordion 1</Typography> */}
                            </AccordionSummary>
                        )}
                        <AccordionDetails>
                            <Box hidden={oneDate} className={classes.oneDate}>
                                {date1.frequency === 1 && (
                                    <>
                                        {/* <p>{t("Client.Benefit.section3_desc1")}</p> */}

                                        <Grid container>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    1
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={`${
                                                                errors.date
                                                                    ? "error"
                                                                    : ""
                                                            }`}
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option3"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginLeft={"20px"}>
                                                        <Box
                                                            className="calendar_wrapper"
                                                            display={"flex"}
                                                        >
                                                            <DatePicker
                                                                required
                                                                className="one_shot_date"
                                                                format="MM/dd/yyyy"
                                                                id="date-picker-inline"
                                                                value={getValue(
                                                                    "1"
                                                                )}
                                                                minDate={startDate()}
                                                                maxDate={endDate()}
                                                                excludeDates={excludeDates()}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "date1"
                                                                    )
                                                                }
                                                                locale={local}
                                                            />
                                                            <CalendarTodayIcon />
                                                        </Box>
                                                        <Box
                                                            hidden={
                                                                !store.getState()
                                                                    .estimationConcierge
                                                                    .settings
                                                                    .houseworkServiceAddition
                                                                    .less_48h_key
                                                            }
                                                            width={"250px"}
                                                        >
                                                            <Box
                                                                color={
                                                                    "#489DD0"
                                                                }
                                                                fontSize={
                                                                    "1.2rem"
                                                                }
                                                                marginTop={
                                                                    "10px"
                                                                }
                                                            >
                                                                <ErrorOutlineIcon fontSize="small" />{" "}
                                                                {t(
                                                                    "Client.Benefit.error",
                                                                    {
                                                                        value:
                                                                            store.getState()
                                                                                .estimationConcierge
                                                                                .calculation
                                                                                .less48h !==
                                                                            undefined
                                                                                ? store.getState()
                                                                                      .estimationConcierge
                                                                                      .calculation
                                                                                      .less48h
                                                                                : 0,
                                                                    }
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    2
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={
                                                                errors?.hours
                                                                    ? classes.errorMorning
                                                                    : ""
                                                            }
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option2"
                                                            )}
                                                        </Typography>
                                                    </Box>

                                                    {displayHoursFromDays(
                                                        date1,
                                                        "date1"
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {/* ********* date2 *********** */}
                <Box hidden={oneDate} marginBottom={"20px"}>
                    {/*  */}
                    <Accordion
                        expanded={expanded === "panel2"}
                        onChange={() => {
                            if (date1.hours.length === 1) {
                                if (date1.hours[0].period !== "") {
                                    setExpanded(
                                        date1.date !== null ? "panel2" : true
                                    );
                                }
                            }
                            //
                        }}
                        // onChange={handleChange("panel2")}
                    >
                        {date2.date === null ? (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    className={`${errors.date ? "error" : ""}`}
                                    variant="h6"
                                >
                                    {t("Client.Benefit.section3_option4")}
                                </Typography>
                            </AccordionSummary>
                        ) : (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Box
                                    hidden={oneDate}
                                    className={classes.firstDate}
                                >
                                    <Box
                                        marginRight={"20px"}
                                        marginTop={"37px"}
                                        marginLeft={"20px"}
                                    >
                                        <h5>Date 2</h5>
                                    </Box>
                                    <Box
                                        marginLeft={"30px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        <p>{getValue("2")}</p>
                                    </Box>
                                    <Box
                                        marginLeft={"100px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        {date2.hours.map((e) => {
                                            if (e.period === "1")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.morning"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "2")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.afternoon"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "3")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.any"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "4")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.depart"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else return <p></p>;
                                        })}
                                    </Box>

                                    <Button className={classes.btn}>
                                        <p>
                                            {t("Client.Benefit.section3_Btn")}
                                        </p>
                                    </Button>
                                </Box>
                                {/* <Typography className={classes.heading}>Accordion 1</Typography> */}
                            </AccordionSummary>
                        )}
                        <AccordionDetails>
                            <Box
                                className={
                                    oneDate ? classes.oneDate : classes.date_box
                                }
                            >
                                {date2.frequency === 1 && (
                                    <>
                                        {/* <p>{t("Client.Benefit.section3_desc1")}</p> */}

                                        <Grid container>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    1
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={`${
                                                                errors.date
                                                                    ? "error"
                                                                    : ""
                                                            }`}
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option3"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginLeft={"20px"}>
                                                        <Box
                                                            className="calendar_wrapper"
                                                            display={"flex"}
                                                        >
                                                            {/* <Box
                                                            marginRight={"20px"}
                                                            marginTop={"17px"}
                                                            width={"80px"}
                                                        >
                                                            <p>Date 2</p>
                                                        </Box> */}

                                                            <DatePicker
                                                                required
                                                                className="one_shot_date"
                                                                format="MM/dd/yyyy"
                                                                id="date-picker-inline"
                                                                value={getValue(
                                                                    "2"
                                                                )}
                                                                disabled={
                                                                    date1.date ===
                                                                    null
                                                                }
                                                                minDate={
                                                                    date1.date !==
                                                                    null
                                                                        ? new Date(
                                                                              date1.date
                                                                          )
                                                                        : startDate()
                                                                }
                                                                maxDate={endDate()}
                                                                excludeDates={excludeDates()}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "date2"
                                                                    )
                                                                }
                                                                locale={local}
                                                            />
                                                            <CalendarTodayIcon />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    2
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={
                                                                errors?.hours
                                                                    ? classes.errorMorning
                                                                    : ""
                                                            }
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option2"
                                                            )}
                                                        </Typography>
                                                    </Box>

                                                    {displayHoursFromDays(
                                                        date2,
                                                        "date2"
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {/* ********* date3 ******** */}

                <Box hidden={oneDate} marginBottom={"20px"}>
                    <Accordion
                        expanded={expanded === "panel3"}
                        // onChange={handleChange("panel3")}
                        onChange={() => {
                            if (
                                date1.hours.length === 1 &&
                                date2.hours.length === 1
                            ) {
                                if (
                                    date1.hours[0].period !== "" &&
                                    date2.hours[0].period !== ""
                                ) {
                                    setExpanded(
                                        date2.date !== null ? "panel3" : true
                                    );
                                }
                            }
                            //
                        }}
                    >
                        {date3.date === null ? (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography
                                    className={`${errors.date ? "error" : ""}`}
                                    variant="h6"
                                >
                                    {t("Client.Benefit.section3_option4")}
                                </Typography>
                            </AccordionSummary>
                        ) : (
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Box
                                    hidden={oneDate}
                                    className={classes.firstDate}
                                >
                                    <Box
                                        marginRight={"20px"}
                                        marginTop={"37px"}
                                        marginLeft={"20px"}
                                    >
                                        <h5>Date 3</h5>
                                    </Box>
                                    <Box
                                        marginLeft={"30px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        <p>{getValue("3")}</p>
                                    </Box>
                                    <Box
                                        marginLeft={"100px"}
                                        marginTop={"34px"}
                                        fontSize={"15px"}
                                    >
                                        {date3.hours.map((e) => {
                                            if (e.period === "1")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.morning"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "2")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.afternoon"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "3")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.any"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else if (e.period === "4")
                                                return (
                                                    <p>
                                                        {" "}
                                                        {t(
                                                            "Client.Time.depart"
                                                        )}{" "}
                                                    </p>
                                                );
                                            else return <p></p>;
                                        })}
                                    </Box>

                                    <Button className={classes.btn}>
                                        <p>
                                            {t("Client.Benefit.section3_Btn")}
                                        </p>
                                    </Button>
                                </Box>
                            </AccordionSummary>
                        )}
                        <AccordionDetails>
                            <Box
                                className={
                                    oneDate ? classes.oneDate : classes.date_box
                                }
                            >
                                {date3.frequency === 1 && (
                                    <>
                                        <Grid container>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    1
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={`${
                                                                errors.date
                                                                    ? "error"
                                                                    : ""
                                                            }`}
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option3"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box marginLeft={"20px"}>
                                                        <Box
                                                            className="calendar_wrapper"
                                                            display={"flex"}
                                                        >
                                                            <DatePicker
                                                                required
                                                                className="one_shot_date"
                                                                format="MM/dd/yyyy"
                                                                id="date-picker-inline"
                                                                value={getValue(
                                                                    "3"
                                                                )}
                                                                disabled={
                                                                    date2.date ===
                                                                    null
                                                                }
                                                                minDate={
                                                                    date2.date !==
                                                                    null
                                                                        ? new Date(
                                                                              date2.date
                                                                          )
                                                                        : startDate()
                                                                }
                                                                maxDate={endDate()}
                                                                excludeDates={excludeDates()}
                                                                onChange={(e) =>
                                                                    handleDateChange(
                                                                        e,
                                                                        "date3"
                                                                    )
                                                                }
                                                                locale={local}
                                                            />
                                                            <CalendarTodayIcon />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    mb={4}
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        mb={2}
                                                    >
                                                        <Box mr={2}>
                                                            <svg
                                                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                                                focusable="false"
                                                                viewBox="0 0 24 24"
                                                                aria-hidden="true"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="12"
                                                                ></circle>
                                                                <text
                                                                    className="MuiStepIcon-text"
                                                                    x="12"
                                                                    y="16"
                                                                    textAnchor="middle"
                                                                >
                                                                    2
                                                                </text>
                                                            </svg>
                                                        </Box>
                                                        <Typography
                                                            className={
                                                                errors?.hours
                                                                    ? classes.errorMorning
                                                                    : ""
                                                            }
                                                            variant="h6"
                                                        >
                                                            {t(
                                                                "Client.Benefit.section3_option2"
                                                            )}
                                                        </Typography>
                                                    </Box>

                                                    {displayHoursFromDays(
                                                        date3,
                                                        "date3"
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
            <Box className={classes.keyBox}>
                <Typography variant="h3" className="HousingType__title">
                    {t("Client.Benefit.title6")}
                </Typography>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <Typography variant="h6">
                            {t("Client.Benefit.title7")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical contained primary button group"
                            variant="text"
                        >
                            <Box style={{ borderBottom: "0.1rem solid" }}>
                                <Button
                                    className={
                                        errors.key
                                            ? classes.btnError
                                            : classes.btnKey
                                    }
                                    onClick={() =>
                                        handleClickKey("on_site_key")
                                    }
                                >
                                    <Typography
                                        style={{ textTransform: "none" }}
                                        variant="h6"
                                    >
                                        {" "}
                                        {t("Client.Benefit.section4_title1")}
                                    </Typography>
                                </Button>
                            </Box>
                            {/* <hr
                                color="#000"
                                // size="1"
                                style={{ borderTop: "0.0rem solid" }}
                            ></hr> */}
                            <Box
                                style={{
                                    borderBottom: "0.1rem solid",
                                }}
                            >
                                <Button
                                    className={
                                        errors.key
                                            ? classes.btnError
                                            : classes.btnKey
                                    }
                                    onClick={() => handleClickKey("in_box_key")}
                                >
                                    <Box display={"block"}>
                                        <Typography
                                            style={{ textTransform: "none" }}
                                            variant="h6"
                                        >
                                            {t(
                                                "Client.Benefit.section4_title2"
                                            )}
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>

                            <Button
                                className={
                                    errors.key
                                        ? classes.btnError
                                        : classes.btnKey
                                }
                                onClick={() => handleClickKey("find_key")}
                            >
                                <Typography
                                    style={{ textTransform: "none" }}
                                    variant="h6"
                                >
                                    {" "}
                                    {t("Client.Benefit.section4_title3")}{" "}
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default withTranslation()(Benefit);
