/*  
    This is the Hero section of Client Home page. 
*/
import { Col, Container, Row } from "react-bootstrap";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PostCodeCl from "../../section-parts/PostCodeCl";

const useStyle = makeStyles((theme) => ({
    ClHero__title: {
        marginTop: "143px",
        marginBottom: "38px",
        "& span": {
            fontSize: "38px",
            display: "block",

            "@media (max-width:1200px)": {
                fontSize: "33px",
            },
            "@media (max-width:599px)": {
                fontSize: "19px",
            },
        },

        "@media (max-width:599px)": {
            marginTop: "30px",
            marginBottom: "10px",
        },
    },
}));

function Hero({ t }) {
    const classes = useStyle();

    return (
        <section className="ClHero">
            <Grid md={12} xs={12}>
                <Container
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // marginTop: "300px",
                        // marginBottom: "100px",
                        /* height: 100%; */
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "300px",
                            marginBottom: "100px",
                            /* height: 100%; */
                            justifyContent: "center",
                        }}
                    >
                        {/* <Typography variant="h1" className={classes.ClHero__title}>
                                {t("Client.Hero.title1")}
                                <Box component="span">{t("Client.Hero.text1")}</Box>
                            </Typography> */}

                        <PostCodeCl />
                    </Box>
                </Container>
            </Grid>
        </section>
    );
}

export default withTranslation()(Hero);
