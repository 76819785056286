import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom'
import { geocodeByAddress } from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import { estimationLocationUpdate } from "../../../../../common/store/actions/estimationConciergeAction";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { estimationNewRequest } from "../../../../../common/store/actions/estimationConciergeAction";
import config from "../../../../../config.json";
import axios from "axios";
import Form from "../form/Form";
import { Button } from "react-bootstrap";
import { setAlert } from "../../../../../common/store/actions/alertAction";

const useStyle = makeStyles((theme) => ({
    Btn: {
        position: "relative",
        zIndex: "2px",
        right: "8px",
        padding: "10px 14px",
        cursor: "pointer",
        background: "#28cc8b !important",
        color: "#fff !important",
        transition: "ease-in 0.3s",
        border: "3px solid #28cc8b !important",
        fontFamily: "Avenir_Black",
        fontSize: "20px",

        borderRadius: " 0 !important",
        textTransform: "uppercase",
    },
}));
const InputPostCode = ({ t, ClassColor }) => {
    const classes = useStyle();
    const store = useStore();
    const dispatch = useDispatch();
    const history = useNavigate();
    const isLoggedIn = store.getState().auth.isLoggedIn;

    const newRequest = (e) => {
        e.preventDefault();

        const data = {
            proId: "",
            proData: {},
            houseworkFrequencyId: 1,
            houseworkPersonalization: {
                oven: 0,
                fridge: 0,
                vacuum: false,
                product_ecological: false,
                " product_standard": false,
                cat: false,
                dog: false,
                valet: 0,
                single_bed: 0,
                double_bed: 0,
                queen_bed: 0,
                king_bed: 0,
                super_bed: 0,
                bebe_bed: 0,
                sofa_bed: 0,
            },
            housingSuccess: true,
            twiceOrMoreRequests: true,
        };
        //It updates the store with the initial satate.
        dispatch(estimationNewRequest(data));
        history("/concierge/benefit");
    };

    const { errors, values, handleChange, handleSubmit } = Form({
        initValues: { postCode: "" },
        validator: (values) => {
            let errors = {};

            const postCode = values.postCode.trim().replace(/[^0-9A-Z]/g, "");

            if (!postCode || postCode === "")
                errors.postCode = t("Client.Error.postcode_required");
            if (
                postCode &&
                postCode !== "" &&
                !new RegExp(
                    /^([A-Za-z]{1})([0-9]{1})([A-Za-z]{1})([0-9]{1})([A-Za-z]{1})([0-9]{1})$/,
                    "g"
                ).test(postCode)
            )
                errors.postCode = t("Client.Error.postcode_invalid_format");

            return errors;
        },
        onSubmit: ({ values, setErrors }) => {
            let postCode = values.postCode.trim().replace(/[^0-9A-Z]/g, "");
            postCode = postCode.slice(0, 3) + " " + postCode.slice(3);
            axios
                .post(`${config.API_SERVER}/api/conciege/postcode/check`, {
                    postcode: postCode,
                })
                .then((res) => {
                    if (res.status === 200) {
                        console.log(res, "message");

                        geocodeByAddress(postCode)
                            .then((res) => {
                                const arrayAddress = res[0].address_components;

                                let city = null;
                                let province = null;
                                let country = null;

                                for (let index in arrayAddress) {
                                    switch (arrayAddress[index].types[0]) {
                                        case "locality":
                                            city =
                                                arrayAddress[index].long_name;
                                            break;
                                        case "political":
                                            city =
                                                arrayAddress[index].long_name;
                                            break;
                                        case "administrative_area_level_1":
                                            province =
                                                arrayAddress[index].long_name;
                                            break;
                                        case "country":
                                            country =
                                                arrayAddress[index].short_name;
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                console.log(
                                    "PASO POR AQUI",
                                    store.getState().estimationConcierge
                                );
                                const requestBody = {
                                    ...store.getState().estimationConcierge
                                        .settings,
                                    sourceId: 2,
                                    address: {
                                        city: city,
                                        province: province,
                                        country: country,
                                        postcode: postCode,
                                        lat:
                                            res[0]?.geometry?.location?.lat() ??
                                            null,
                                        lng:
                                            res[0]?.geometry?.location?.lng() ??
                                            null,
                                    },
                                };
                                estimationLocationUpdate(requestBody)(dispatch);
                                history("/concierge/housing");
                            })
                            .catch((error) => console.error("Error", error));
                    }
                })
                .catch((error) => {
                    console.log(errors, "errorss");

                    errors.postCode = t("Client.Error.postcode_not_active");
                    setErrors(errors);
                    console.error("Error", error.response.data);
                });
        },
    });

    return (
        <Box>
            {isLoggedIn === false ? (
                <form action="" onSubmit={handleSubmit}>
                    <Box className="post_code__input_container">
                        <input
                            id="postCode"
                            type="text"
                            name="postCode"
                            placeholder={t("Client.Hero.postCodeLabel")}
                            value={values.postCode}
                            onChange={handleChange}
                        ></input>
                    </Box>
                    <Button
                        type="submit"
                        value={t("Client.Hero.postCodeBtn")}
                        className={ClassColor}
                        style={{ marginBottom: "20px", textAlign: "center" }}
                    >
                        {t("Client.Hero.postCodeBtn")}
                    </Button>
                    {errors.postCode && (
                        <p className="error" style={{ fontSize: "1.6rem" }}>
                            {errors.postCode}
                        </p>
                    )}
                </form>
            ) : (
                <Box margin="20px" position="relative !important">
                    {/* <input type='submit' value='Envoyer une demande' className={ClassColor} ></input> */}

                    <Link>
                        <Button
                            className={classes.Btn}
                            onClick={(e) => newRequest(e)}
                        >
                            Nouvelle demande
                        </Button>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

InputPostCode.defaultProps = {
    ClassColor: "btn_green_bg",
};

InputPostCode.propTypes = {
    ClassColor: PropTypes.string,
};

export default withTranslation()(InputPostCode);
