import { v4 as uuid } from "uuid";
import { SET_ALERT, REMOVE_ALERT, RESET_ALERT } from "./typesAction";

export const setAlertProcessing =
	(message, alertType, indice) => (dispatch) => {
		const id = uuid();

		if (indice === 1) {
			dispatch({
				type: SET_ALERT,
				payload: { message: "Processing...", alertType, id },
			});
			setTimeout(() => {
				dispatch(setAlertProcessing(message, alertType, 2));
			}, 1500);
		} else if (indice === 2) {
			dispatch({
				type: SET_ALERT,
				payload: { message, alertType, id },
			});
			setTimeout(() => {
				dispatch(setAlertProcessing(message, alertType, 3));
			}, 1500);
		} else {
			dispatch({
				type: RESET_ALERT,
			});
		}
	};

export const setAlert =
	(message, alertType, timeout = 3000) =>
	(dispatch) => {
		const id = uuid();
		dispatch({
			type: SET_ALERT,
			payload: { message, alertType, id },
		});
		setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
	};

export const resetAlert = () => (dispatch) => {
	dispatch({
		type: RESET_ALERT,
	});
};
