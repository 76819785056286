
import '../scss/app.scss';

import { withTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

import { geocodeByAddress } from 'react-places-autocomplete';

import { useDispatch, useStore } from 'react-redux';
import { estimationLocationUpdate } from '../../../../../common/store/actions/estimationConciergeAction';

import Footer from '../../../../layout/concierge/FooterServ';
import HeaderServ from '../../../../layout/concierge/HeaderServ';

import Button from '@material-ui/core/Button';
import { Typography, Box } from '@material-ui/core';

import Form from '../form/Form';
import MessengerCustomerChat from 'react-messenger-customer-chat'

function LocalisationC({ t }) {
    const store = useStore();
    const dispatch = useDispatch();
    const history = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { errors, values, handleChange, handleSubmit } = Form({
        initValues: { postCode: '' },
        validator: (values) => {
            let errors = {};

            const postCode = values.postCode.trim().replace(/[^0-9A-Z]/g, '');

            if (!postCode || postCode === '') errors.postCode = t("Client.Error.postcode_required");
            if (postCode && postCode !== '' && !(new RegExp(/^([A-Za-z]{1})([0-9]{1})([A-Za-z]{1})([0-9]{1})([A-Za-z]{1})([0-9]{1})$/, 'g')).test(postCode)) errors.postCode = t("Client.Error.postcode_invalid_format");

            return errors;
        },
        onSubmit: ({ values, setErrors }) => {
            let postCode = values.postCode.trim().replace(/[^0-9A-Z]/g, '');
            postCode = postCode.slice(0, 3) + ' ' + postCode.slice(3);

            geocodeByAddress(postCode)
                .then(res => {
                    const arrayAddress = res[0].address_components;

                    let city = null;
                    let province = null;
                    let country = null;

                    for (let index in arrayAddress) {
                        switch (arrayAddress[index].types[0]) {
                            case 'locality':
                                city = arrayAddress[index].long_name
                                break;
                            case 'political':
                                city = arrayAddress[index].long_name
                                break;
                            case 'administrative_area_level_1':
                                province = arrayAddress[index].long_name
                                break;
                            case 'country':
                                country = arrayAddress[index].short_name
                                break;
                            default:
                                break;
                        }            
                    }

                    const requestBody = {
                        ...store.getState().estimationConcierge.settings,
                        address: {
                            city: city,
                            province: province,
                            country: country,
                            postcode: postCode,
                            lat: res[0]?.geometry?.location?.lat() ?? null,
                            lng: res[0]?.geometry?.location?.lng() ?? null,
                        },
                    };

                    estimationLocationUpdate(requestBody)(dispatch);
                    history('/concierge/housing');
                })
                .catch(error => console.error('Error', error));
        },
    });
    const handleClick = () => {
        history('/concierge/login')
    }
    return (
        <div>
            <HeaderServ />
            <Row className="C">
                <Col md={5}>
                    <Typography variant="h3">{t("Client.Location.section1_title")}</Typography>

                    <p>{t("Client.Location.section1_text1")}</p>

                    <Box className="Localisation_subtext">
                        <p>{t("Client.Location.section1_text2")}</p>
                        <p>{t("Client.Location.section1_text3")}</p>
                    </Box>

                    <Box className="post_code__form">
                        <form action="" onSubmit={handleSubmit}>
                            <Box className="post_code__input_container">
                                <input id="postCode" type='text' name="postCode" placeholder={t("Client.Hero.postCodeLabel")} value={values.postCode} onChange={handleChange}></input>
                            </Box>

                            <input type='submit' value={t("Client.Location.section1_btn")} className="btn_green_bg" ></input>

                            {errors.postCode && <p className='error'>{errors.postCode}</p>}
                        </form>
                    </Box>
                </Col>

                <Col md={2} className="Localisation_seperator__container">
                    <Box>
                        <span className="Localisation_seperator__befor"></span>
                        <p className="Localisation_seperator">
                            {t("Client.Location.ou")}
                        </p>
                        <span className="Localisation_seperator__after"></span>
                    </Box>
                </Col>

                <Col md={5}>
                    <Typography variant="h3">{t("Client.Location.section2_title")}</Typography>

                    <p>{t("Client.Location.section2_text1")}</p>

                    <Box className="Localisation_subtext">
                        <p> <Link to="#">{t("Client.Location.section2_text2")}</Link></p>
                    </Box>

                    <Button className="btn_green_bg connect_link" onClick={handleClick}>
                        {t("Client.Location.section2_btn")}
                    </Button>
                </Col>
            </Row>

            <Row className="localisation_footer">
                {/* <p>{t("Client.Location.footer")} <Link to="#" className="link">{t("Client.Location.footer_link")} </Link></p> */}
            </Row>

            <MessengerCustomerChat
                pageId={ process.env.REACT_APP_FACEBOOK_PAGE_ID }
                appId={ process.env.REACT_APP_FACEBOOK_APP_ID }
            />
            <Footer />
        </div>
    );
};

export default withTranslation()(LocalisationC);