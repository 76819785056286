import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Matarial-UI
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./assets/css/material-ui/configTheme";

import { Provider } from "react-redux";
import store from "./common/store";

import ConciergeHome from "./components/views/mrConcierge/quote_generator/pages/ConciergeHome";
import ChooseServiceC from "./components/views/mrConcierge/quote_generator/pages/ChooseServiceC";
import LocalisationC from "./components/views/mrConcierge/quote_generator/pages/LocalisationC";
import ThankYouPageC from "./components/views/mrConcierge/quote_generator/pages/ThankYouPageC";
import SmsValidationC from "./components/views/mrConcierge/quote_generator/pages/SmsValidationC";

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <div className="App">
                        <Routes>
                            <Route 
                                exact path="/" 
                                element={<ConciergeHome/>}
                            />
                            <Route
                                exact
                                path="/concierge/localisation"
                                element={<LocalisationC/>}
                            />
                            <Route
                                exact
                                path="/concierge/housing"
                                element={<ChooseServiceC />}
                            />
                            <Route
                                exact
                                path="/concierge/benefit"
                                element={<ChooseServiceC />}
                            />
                            <Route
                                exact
                                path="/concierge/validation"
                                element={<ChooseServiceC />}
                            />
                            <Route
                                exact
                                path="/concierge/thankyou"
                                element={<ThankYouPageC />}
                            />
                            <Route
                                exact
                                path="/concierge/sms_validation"
                                element={<SmsValidationC />}
                            />
                        </Routes>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
}
export default App;
