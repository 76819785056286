import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { useStore } from "react-redux";

import { startDateToTextualDate } from "../utils/date";

const Demande = ({ t }) => {
    const store = useStore();
    const [settings, setSettings] = useState(
        store.getState().estimationConcierge.settings
    );

    useEffect(() => {
        store.subscribe(() => {
            setSettings(store.getState().estimationConcierge.settings);
        });
    }, [store]);

    const displaySpecificities = () => {
        const specificities = settings.housingSpecificity;

        let elements = [];

        for (const specificity in specificities) {
            if (specificity !== "id" && specificity !== "terrace") {
                if (specificities[specificity] !== 0) {
                    elements.push(
                        <li key={specificity}>
                            {specificities[specificity]}{" "}
                            {t(
                                `Client.Logement.housingSpecificity_${specificity}`
                            )}
                        </li>
                    );
                }
            }
        }

        return elements;
    };

    const displayFormula = () => {
        const houseworkFrequencyId = settings.houseworkFrequencyId;
        const houseworkWeekTime = settings.houseworkWeekTime;

        const startDate = settings.startDate;

        let elements = [];

        //elements.push(<li key="1">{t(`Client.Validation.frequency_selected_${houseworkFrequencyId}`)}</li>);

        if (startDate) {
            if (
                houseworkFrequencyId === 1 &&
                houseworkWeekTime[0] &&
                houseworkWeekTime[0]?.weekDate !== "" &&
                houseworkWeekTime[0].period !== ""
            ) {
                if (houseworkWeekTime.length > 2)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[2].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[2].period}`
                            )}
                            )
                        </li>
                    );
                if (houseworkWeekTime.length > 1)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[1].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[1].period}`
                            )}
                            )
                        </li>
                    );

                // ({t(`Client.Time.period_${houseworkWeekTime[0].period}`)})
                if (houseworkWeekTime !== undefined)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[0].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[0].period}`
                            )}
                            )
                        </li>
                    );
            } else if (
                houseworkWeekTime[0] &&
                houseworkWeekTime[0]?.weekDate !== "" &&
                houseworkWeekTime[0].period !== ""
            ) {
                if (houseworkWeekTime.length > 2)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.from_the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[2].weekDate
                                ),
                            })}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[2].period}`
                            )}
                            )
                        </li>
                    );
                if (houseworkWeekTime.length > 1)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.from_the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[1].weekDate
                                ),
                            })}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[1].period}`
                            )}
                            )
                        </li>
                    );

                if (houseworkWeekTime !== undefined) {
                    elements.push(
                        <li key="2">
                            {t("Client.Time.from_the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[0].weekDate
                                ),
                            })}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[0].period}`
                            )}
                            )
                        </li>
                    );
                }
                if (
                    houseworkWeekTime.length > 1 &&
                    houseworkWeekTime[1]?.weekDate !== "" &&
                    houseworkWeekTime[1].period !== ""
                ) {
                    if (houseworkWeekTime.length > 2) {
                        elements.push(<li key="3">{t("Client.Time.or")}</li>);
                        elements.push(
                            <li key="4">
                                {t("Client.Time.from_the_with_date", {
                                    date: startDateToTextualDate(
                                        houseworkWeekTime[2].weekDate
                                    ),
                                })}{" "}
                                (
                                {t(
                                    `Client.Time.period_${houseworkWeekTime[2].period}`
                                )}
                                )
                            </li>
                        );
                    }
                    if (houseworkWeekTime.length > 1) {
                        elements.push(<li key="3">{t("Client.Time.or")}</li>);
                        elements.push(
                            <li key="4">
                                {t("Client.Time.from_the_with_date", {
                                    date: startDateToTextualDate(
                                        houseworkWeekTime[1].weekDate
                                    ),
                                })}{" "}
                                (
                                {t(
                                    `Client.Time.period_${houseworkWeekTime[1].period}`
                                )}
                                )
                            </li>
                        );
                    }

                    if (houseworkWeekTime !== undefined) {
                        elements.push(<li key="3">{t("Client.Time.or")}</li>);
                        elements.push(
                            <li key="4">
                                {t("Client.Time.from_the_with_date", {
                                    date: startDateToTextualDate(
                                        houseworkWeekTime[0].weekDate
                                    ),
                                })}{" "}
                                (
                                {t(
                                    `Client.Time.period_${houseworkWeekTime[0].period}`
                                )}
                                )
                            </li>
                        );
                    }
                }
            }
        }

        let elementsMoreTitle = [];
        if (elements.length > 0) {
            elementsMoreTitle = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte3")}
                </p>,
            ];
            elementsMoreTitle.push(elements);
        }
        return elementsMoreTitle;
    };
    const displayBuanderie = () => {
        let options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization.laundry_room;

        let elements = [];
        console.log(options, "options");
        if (options === "1")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet1`)}
                </li>
            );
        if (options === "2")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet2`)}
                </li>
            );
        if (options === "3")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet3`)}
                </li>
            );
        options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization;

        for (const option in options) {
            if (
                typeof options[option] === "number" &&
                options[option] !== 0 &&
                option.includes("bed")
            ) {
                elements.push(
                    <li key={option}>
                        {options[option]}{" "}
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            }
        }
        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte5")}
                </p>,
            ];
            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };
    const displayOptions = () => {
        let options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization;

        let elements = [];
        for (const option in options) {
            if (
                typeof options[option] === "number" &&
                options[option] !== 0 &&
                !option.includes("bed")
            ) {
                elements.push(
                    <li key={option}>
                        {options[option]}{" "}
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            } else if (options[option] === true) {
                elements.push(
                    <li key={option}>
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            }
        }
        options =
            store.getState().estimationConcierge.settings
                .houseworkServiceAddition.concierge_key;

        if (options === true) {
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkServiceAddition_concierge_key`)}
                </li>
            );
        }

        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte4")}
                </p>,
            ];

            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };
    const displayKey = () => {
        let elements = [];
        let options =
            store.getState().estimationConcierge.settings
                .houseworkServiceAddition;
        for (const option in options) {
            if (
                options[option] === true &&
                option !== "less_48h_key" &&
                option !== "concierge_key"
            ) {
                elements.push(
                    <li key={option}>
                        {t(`Client.Benefit.houseworkServiceAddition_${option}`)}
                    </li>
                );
            }
        }

        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte6")}
                </p>,
            ];

            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };

    return (
        <div className="demande__housing_specification">
            <h2>{t("Client.sideBar.demande")}</h2>

            {/* {!store.getState().estimationConcierge.twiceOrMoreRequests && ( */}
            <>
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t(
                        `Client.Logement.housingCategory_${
                            store.getState().estimationConcierge.settings
                                .housingCategoryId
                        }`
                    )}
                </p>
                <ul className="recap_list">
                    <li>
                        {/* {t(`Client.Logement.housingCategory_${store.getState().estimationConcierge.settings.housingCategoryId}`)} */}

                        <span> </span>

                        {store.getState().estimationConcierge.settings
                            .housingCategoryId === 2 ||
                        store.getState().estimationConcierge.settings
                            .housingCategoryId === 3 ||
                        store.getState().estimationConcierge.settings
                            .housingCategoryId === 4
                            ? t(
                                  `Client.Logement.housingSize_${
                                      store.getState().estimationConcierge
                                          .settings.housingSizeId
                                  }`
                              )
                            : t(`Client.Logement.studioSize`)}
                    </li>

                    {displaySpecificities()}
                </ul>
            </>
            {/* )} */}

            {store.getState().estimationConcierge.housingSuccess && (
                <>
                    <ul className="recap_list">{displayFormula()}</ul>
                </>
            )}
            <ul className="recap_list">{displayBuanderie()}</ul>
            <ul className="recap_list">{displayOptions()}</ul>
            <ul className="recap_list">{displayKey()}</ul>
        </div>
    );
};

export default withTranslation()(Demande);
