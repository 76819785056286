export const StudioIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="75.124" height="56.77" viewBox="0 0 75.124 56.77">
                <path className="a" d="M73.829,128.68H68.648V90.043h5.181A1.3,1.3,0,0,0,75.1,88.488l-2.59-12.952c-.123-.6-1.269-1.036-1.269-1.036H9.067A1.3,1.3,0,0,0,7.8,75.536L5.207,88.488a1.3,1.3,0,0,0,1.269,1.555h5.181V128.68H1.3a1.3,1.3,0,0,0,0,2.59H73.829a1.3,1.3,0,0,0,0-2.59Zm-32.381-25.9-27.2-5.181h51.81l-5.181,5.181a1.3,1.3,0,0,0-1.3-1.3H42.743A1.3,1.3,0,0,0,41.448,102.775Zm-27.2,7.771v0Zm46.629-7.771,5.181-5.181V128.68H60.876ZM10.129,77.09H70.176l2.072,10.362H8.057ZM66.057,90.043v7.551H14.248V90.043Zm-24.61,12.732v25.9h-27.2V97.594Zm2.59,25.9V104.07H58.286v24.61Z" transform="translate(0 -74.5)"/>
                <path className="a" d="M13.441,33.973h10.09a1.442,1.442,0,0,0,1.441-1.441V22.441A1.442,1.442,0,0,0,23.532,21H13.441A1.442,1.442,0,0,0,12,22.441v10.09A1.442,1.442,0,0,0,13.441,33.973Zm1.441-10.09H22.09V31.09H14.883Z" transform="translate(8.944 5.894)"/>
            </svg>
        </>
    )
}
