import React from "react";
import TextField from "@material-ui/core/TextField";
//import { MaskTelephone } from './mask/TextMaskCustom';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: "-1px 4px 6px 3px #80808047",
    },
    inputWidth: {
        borderRadius: "4px",
        "& .MuiFormLabel-root": {
            "@media (max-width:600px)": {
                paddingTop: "3px",
                fontSize: "1.8rem",
            },
        },
        "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -8px) scale(0.75)",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "4px", //8px
        },

        // '& .MuiInputBase-input': {
        //     boxShadow: '1px 3px 4px 0px #80808047',
        //     height: '0.8em',
        //     borderRadius: '8px'
        // },

        "& .MuiOutlinedInput-inputMarginDense": {
            paddingTop: "11px",
            paddingBottom: "11px",
        },
        "& .MuiInputBase-input": {
            padding: "10px 14px",
            fontSize: "1.8rem",
            //backgroundColor: '#bcbcbc'
        },
    },
    backgroundColorInputDisabled: {
        "& .MuiInputBase-input": {
            backgroundColor: "#bcbcbc !important",
            border: "none !important",
            borderRadius: "4px", //8px
        },
    },
    errors: {
        "& .MuiOutlinedInput-root": {
            //border: '1px solid #dc3545 !important',
            "& fieldset": {
                borderColor: "#dc3545",
                borderRadius: "4px",
            },
        },
        "& .MuiInput-underline:before": {
            borderBottom: "1px solid #dc3545",
        },
        "& label": {
            color: "#dc3545",
        },
    },
    errorMessage: {
        color: "#dc3545",
        paddingTop: "5px",
        fontSize: "1.6rem",
    },
    dateMargin: {
        "& input": {
            paddingTop: "10.5px",
            paddingBottom: "10.5px",
        },
    },
    testFieldStyle: {
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(0),
        "@media(max-width: 600px)": {
            marginRight: theme.spacing(0),
        },
    },
}));

//const onlyLetters = /[^a-z]/gi;
const onlyLetters = /[^a-z-" "]/gi;
// const lettersPostalCode = /[^\A-Z]/g;
// const numbersPostalCode = /[^\d]/g;
const lettersAndNumbers = /[^\w]/g;
const matchNothingRegex = /(?!)/;

export default function Input(props) {
    const classes = useStyles();
    const {
        id,
        placeholder,
        label,
        size,
        onBlur,
        defaultValue,
        error,
        type,
        width,
        variant,
        readOnly,
        mask,
        inputRef,
        checked,
    } = props;
    let customizedClass = classes.inputWidth; // = error.length === 0 ? classes.inputWidth : classes.errors;

    if (variant === "outlined") {
        customizedClass = customizedClass + " " + classes.shadow;
    }

    if (type === "date") {
        customizedClass = customizedClass + " " + classes.dateMargin;
    }

    let defaultValueExists = "";
    if (defaultValue !== undefined || defaultValue !== null) {
        defaultValueExists = defaultValue;
    }

    let readOnlyPro = false;
    if (readOnly !== undefined) {
        if (readOnly) {
            customizedClass =
                customizedClass + " " + classes.backgroundColorInputDisabled;
            readOnlyPro = true;
        }
    }

    if (error.length > 0) {
        customizedClass = customizedClass + " " + classes.errors;
    }

    const onChange = (e) => {
        e.preventDefault();
        if (inputRef !== undefined) {
            switch (inputRef) {
                case "onlyLetters":
                    e.target.value = e.target.value.replace(onlyLetters, "");
                    break;
                case "lettersAndNumbers":
                    //                    let expression = numbersPostalCode
                    //                    if(e.nativeEvent.data === 1 || e.nativeEvent.data === 3 || e.nativeEvent.data === 5){
                    //                        expression = lettersPostalCode
                    //                    }

                    e.target.value = e.target.value
                        .replace(lettersAndNumbers, "")
                        .toLocaleUpperCase();
                    break;
                default:
                    e.target.value = e.target.value.replace(
                        matchNothingRegex,
                        ""
                    );
                    break;
            }
        }
        onBlur(e);
    };

    const playMask = (value) => {
        if (mask !== undefined) {
            const { id, string } = mask;

            switch (id) {
                case "telephone":
                    let i = 0;
                    let lastReplacedIndex = -1;

                    const filledMask = string.replace(/#/g, (_, j) => {
                        if (i >= value.length) {
                            return "#";
                        }
                        lastReplacedIndex = j;
                        return value[i++];
                    });
                    return filledMask.substring(0, lastReplacedIndex + 1);
                default:
                    return value;
            }
        }
        return value;
    };

    // switch (maskExists) {
    //     case 'telephone':
    //         return (
    //             <Box className={classes.testFieldStyle} style={{ width: width }}>
    //                 <TextField
    //                     id={id}
    //                     name={id}
    //                     placeholder={placeholder}
    //                     label={label}
    //                     variant={variant}
    //                     size={size}
    //                     type={type}
    //                     onChange={e => onBlur(e)}
    //                     value={defaultValueExists}
    //                     className={customizedClass}
    //                     InputProps={{
    //                         readOnly: readOnlyPro,
    //                         autoComplete: 'off',
    //                         inputComponent: MaskTelephone
    //                     }}
    //                     // style={{
    //                     //     '& .MuiOutlinedInputInput':{
    //                     //         color: '#dc3545'
    //                     //     }
    //                     // }}
    //                     // inputRef = {inputRef !== undefined ? inputRef : null}
    //                 />
    //                 {(error.length > 0) && (
    //                     <span className={classes.errorMessage}>{error}</span>
    //                 )}
    //             </Box>
    //         );
    //     default:

    return (
        <Box display="flex" flexDirection="column" style={{ width: width }}>
            <TextField
                id={id}
                name={id}
                placeholder={placeholder}
                label={label}
                variant={variant}
                size={size}
                type={type}
                onChange={(e) => onChange(e)}
                value={playMask(defaultValueExists)}
                className={customizedClass}
                disabled={checked}
                InputProps={{
                    readOnly: readOnlyPro,
                    autoComplete: "off",
                    //color: '#dc3545'
                }}
                // inputRef = {inputRef !== undefined ? inputRef : null}
            />
            {error.length > 0 && (
                <span className={classes.errorMessage}>{error}</span>
            )}
        </Box>
    );
    //    }
}
