//import { createMuiTheme } from "@material-ui/core/styles";

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme ({
  palette:{
    primary:{
      main: '#2880fb'
    },
    secondary:{
      main: '#28cc8b'
    },
    tertiary:{
      main: '#6d6d6d'
    },
    fourth:{
      main: '#ff8925'
    },
    alert: {
      error: '#f44336',
      success: '#28cc8b',
      info: '#2880fb',
      warning: '#fb9800'
    },
    sideMenuColor: {
      main: '#6d6d6d',
      visited: '#2880fb'
    }
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 8,
    h1: {
      fontSize: 'clamp(2.9rem, 0.608rem + 3.82vw, 5.5rem)', //55px
      fontFamily: "Avenir_Black",
      color: "#000"
    },
    h2: {
      fontSize: 'clamp(2rem, 0.68rem + 2.20vw, 3.5rem)', //"35px",
      fontFamily: "Avenir_Black",
      margin: 0,
      textAlign: "center"
    },
    h3: {
      fontSize: 'clamp(1.9rem, 1.10rem + 1.32vw, 2.8rem)', //"28px",
      fontFamily: "Avenir_Black",
      marginBottom: '29px'
    },
    h4: {
      fontSize: 'clamp(1.8rem, 1.182rem + 1.03vw, 2.5rem)', //"25px",
      fontFamily: "Avenir_Black"
    },
    h5: {
      fontSize: 'clamp(1.7rem, 1.256rem + 0.74vw, 2.2rem)', //"22px",
      //fontSize: '1.563rem', //"25px",
      fontFamily: "Avenir_Black",
      margin: 0,
      color: "#000",
      '& span':{
        fontSize: 'clamp(1.6rem, 1.072rem + 0.88vw, 2.2rem)', //"22px",
      },
      '&.titleSmall':{
        fontSize: '12px', //"17px",
      }
    },
    h6: {
      fontSize: '1.6rem',
      color: '#6d6d6d',
      '&.titleSmall':{
        fontSize: '14px', //"17px",
      },
      '&.titleSmallDate':{
        fontSize: '14px', //"17px",
      }
    },
    p: {
      fontSize: 'clamp(1.6rem, 0.808rem + 1.32vw, 2.5rem)', // '25px',
      fontFamily: "Avenir_Black"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900, //960
      lg: 1200, //1280
      xl: 1536, //1630
      xxl: 1920
    }
  }  
  // Breakpoints bootstrap v5 xs: 0, sm: 576, md: 768, lg: 992, xl:1200, xxl: 1400
});

export default theme;
