import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'

import { useDispatch, useStore } from "react-redux";
import {
    estimationBenefitValidationError,
    estimationBenefitValidationSuccess,
    estimationHousingValidationError,
    estimationHousingValidationSuccess,
} from "../../../../../../common/store/actions/estimationConciergeAction";

import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import HousingType from "./HousingType";

import Demande from "../../section-parts/Demande";
import Balance from "../../section-parts/Balance";
import Benefit from "./Benefit";
import { useState, useEffect } from "react";

const HousingBenefitMenu = ({ t }) => {
    const history = useNavigate();
    const location = useLocation();
    const store = useStore();
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (
        location.pathname === "/concierge/housing" &&
        !store.getState().estimationConcierge.settings.address
    ) {
        history("/");
        return <></>;
    }

    if (
        location.pathname === "/concierge/benefit" &&
        !store.getState().estimationConcierge.housingSuccess
    ) {
        history("/concierge/housing");
        return <></>;
    }

    const validateHousingStep = () => {
        const errors = {};
        const settings = store.getState().estimationConcierge.settings;

        if (settings.houseworkPersonalization.dog === "") errors.dog = true;
        if (settings.houseworkPersonalization.cat === "") errors.cat = true;
        if (settings.housingSizeId === "") errors.housingSizeId = true;

        if (settings.housingCategoryId > 1) {
            if (
                settings.housingSpecificity.bathtub === 0 &&
                settings.housingSpecificity.shower === 0
            )
                errors.hasBathtubOrShower = true;
        }

        if (Object.keys(errors).length !== 0)
            estimationHousingValidationError(errors)(dispatch);
        else estimationHousingValidationSuccess()(dispatch);

        return errors;
    };

    const getValidationTotalDurationMorning = () => {
        const duration =
            store.getState().estimationConcierge.calculation.totalDuration;
        const durationArray = duration.split(":");
        const totalDuration =
            parseInt(durationArray[0], 10) * 60 +
            parseInt(durationArray[1], 10);

        if (totalDuration > 300) {
            return true;
        }

        return false;
    };

    const validateBenefitStep = () => {
        const errors = {};
        const settings = store.getState().estimationConcierge.settings;

        if (
            settings.houseworkWeekTime &&
            settings.houseworkWeekTime.length === 0
        )
            errors.days = true;
        if (!settings.startDate || settings.startDate.includes("_"))
            errors.date = true;

        if (
            !settings.houseworkPersonalization.product_ecological &&
            !settings.houseworkPersonalization.product_standard &&
            !settings.suppliesAcceptation
        )
            errors.suppliesAcceptation = true;

        if (
            !settings.houseworkPersonalization.vacuum &&
            !settings.vacuumAcceptation
        )
            errors.vacuumAcceptation = true;

        if (settings.houseworkPersonalization.laundry_room === 0)
            errors.laundry_room = true;

        if (
            !settings.houseworkServiceAddition.find_key &&
            !settings.houseworkServiceAddition.on_site_key &&
            !settings.houseworkServiceAddition.in_box_key
        )
            errors.key = true;

        for (const day in settings.houseworkWeekTime) {
            if (settings.houseworkWeekTime[day]["period"] === "")
                errors.hours = true;

            if (settings.houseworkWeekTime[day]["period"] === 1) {
                if (getValidationTotalDurationMorning()) {
                    errors.hoursNotEnough = true;
                }
            }
        }

        if (Object.keys(errors).length !== 0)
            estimationBenefitValidationError(errors)(dispatch);
        else estimationBenefitValidationSuccess()(dispatch);

        return errors;
    };

    const validateCurrentStep = () => {
        if (location.pathname === "/concierge/housing")
            return validateHousingStep();
        if (location.pathname === "/concierge/benefit")
            return validateBenefitStep();

        return {};
    };

    const changeStep = () => {
        if (location.pathname === "/concierge/housing")
            history("/concierge/benefit");
        if (location.pathname === "/concierge/benefit")
            history("/concierge/validation");
    };

    const goToNextStep = () => {
        const errors = validateCurrentStep();

        setErrors(errors);

        if (Object.keys(errors).length !== 0) return;

        changeStep();
    };

    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };

    const displayErrors = () => {
        if (Object.keys(errors).length !== 0) {
            let errorMessage = t("Client.Validation.error_message");
            for (let index in errors) {
                if (index === "hasBathtubOrShower") {
                    errorMessage = t(
                        "Client.Validation.error_message_bathtub_or_shower"
                    );
                }
            }

            return (
                <Box className="error_box">
                    <span>
                        <img
                            src={"../images/icon_error.png"}
                            alt=""
                            className="error_icon"
                        />
                    </span>

                    <span className="error_message">{errorMessage}</span>
                </Box>
            );
        }
    };

    return (
        <Box>
            <Box className="choose_service">
                <Grid container>
                    <Grid item xl={9} lg={9} md={8} sm={12}>
                        {location.pathname === "/concierge/housing" && (
                            <HousingType />
                        )}

                        {location.pathname === "/concierge/benefit" && (
                            <Benefit />
                        )}
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <div className="sidebar">
                            <div className="sidebar_inner">
                                <Demande />
                                <Balance />
                                <Button
                                    variant="contained"
                                    className="sidebar__submit"
                                    onClick={goToNextStep}
                                >
                                    {t("Client.sideBar.demande_submit")}
                                </Button>
                            </div>
                            {displayErrors()}
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <div className="services_footer">
                <Link href="#" className="services_backlink" onClick={goBack}>
                    {t("Client.Logement.back")}
                </Link>
                {/* <p>{t("Client.Logement.footer_text")} <a href="http://">{t("Client.Logement.footer_link")}</a></p> */}
            </div>
        </Box>
    );
};

export default withTranslation()(HousingBenefitMenu);
