export const CondoIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="59.932" height="67.583" viewBox="0 0 59.932 67.583">
                <path className="a" d="M61.657,10.2a1.276,1.276,0,0,0,1.275-1.275V1.275A1.276,1.276,0,0,0,61.657,0H4.275A1.276,1.276,0,0,0,3,1.275V8.926A1.276,1.276,0,0,0,4.275,10.2h5.1V57.382H8.107A5.109,5.109,0,0,0,3,62.483v3.825a1.276,1.276,0,0,0,1.275,1.275H61.657a1.276,1.276,0,0,0,1.275-1.275V62.483a5.109,5.109,0,0,0-5.107-5.1H56.557V10.2ZM5.55,2.55H60.382v5.1H5.55ZM60.382,62.483v2.55H5.55v-2.55a2.556,2.556,0,0,1,2.557-2.55H57.825A2.556,2.556,0,0,1,60.382,62.483Zm-22.953-5.1H28.5V42.08h8.926Zm16.577,0H39.98V40.805A1.276,1.276,0,0,0,38.7,39.53H27.228a1.276,1.276,0,0,0-1.275,1.275V57.382H11.926V10.2h42.08Z" transform="translate(-3)"/>
                <path className="a" d="M13.275,21.476H22.2A1.276,1.276,0,0,0,23.476,20.2V11.275A1.276,1.276,0,0,0,22.2,10H13.275A1.276,1.276,0,0,0,12,11.275V20.2A1.276,1.276,0,0,0,13.275,21.476ZM14.55,12.55h6.376v6.376H14.55Z" transform="translate(-0.524 2.752)"/>
                <path className="a" d="M33.275,21.476H42.2A1.276,1.276,0,0,0,43.476,20.2V11.275A1.276,1.276,0,0,0,42.2,10H33.275A1.276,1.276,0,0,0,32,11.275V20.2A1.276,1.276,0,0,0,33.275,21.476ZM34.55,12.55h6.376v6.376H34.55Z" transform="translate(4.98 2.752)"/>
                <path className="a" d="M13.275,32.476H22.2A1.276,1.276,0,0,0,23.476,31.2V22.275A1.276,1.276,0,0,0,22.2,21H13.275A1.276,1.276,0,0,0,12,22.275V31.2A1.276,1.276,0,0,0,13.275,32.476ZM14.55,23.55h6.376v6.376H14.55Z" transform="translate(-0.524 5.778)"/>
                <path className="a" d="M33.275,32.476H42.2A1.276,1.276,0,0,0,43.476,31.2V22.275A1.276,1.276,0,0,0,42.2,21H33.275A1.276,1.276,0,0,0,32,22.275V31.2A1.276,1.276,0,0,0,33.275,32.476ZM34.55,23.55h6.376v6.376H34.55Z" transform="translate(4.98 5.778)"/>
            </svg>
        </>
    )
}