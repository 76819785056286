import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from "react";
//import { Col, Row } from 'react-bootstrap';
import { useDispatch, useStore } from "react-redux";
// import { estimationPersonalDataUpdate } from "../../../../../../common/store/actions/estimationConciergeAction";
import {
    Typography,
    Box,
    Grid,
    makeStyles,
    Avatar,
    Link,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { paymentDoneByClient } from "../../../../../../common/store/actions/estimationConciergeAction";
import { startDateToTextualDate } from "../../utils/date";
import InputCustomPhone from "../../../../../share/inputs/InputCustomPhone";
// import config from "../../../../../../config.json";
import { useTranslation } from "react-i18next";
import {
    calculateTpsTvqAndTotal,
    checkNumber,
} from "../../../../../share/librery/librery";
import PaymentForm from "../../stripe2/PaymentForm";
const useStyle = makeStyles((theme) => ({
    button_complement_Opacity: {
        width: "50px",
        opacity: "1",
        height: "50px",
        margin: "0px 30px 10px 0px",
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none",
        "& #image": {
            width: "100%",
            height: "100%",
        },
        "&:focus": {
            outline: "none",
        },

        "@media(max-width: 600px)": {
            width: "50px",
            height: "50px",
            margin: "20px 20px 20px 0px",
        },
    },
    pComplement: {
        //float:'right',
        display: "flex",
        marginLeft: "100px",
        marginBottom: "20px",
    },
    complement: {
        "& p": {
            fontSize: "10px",
        },
    },
    nombre: {
        color: "#fff",
        float: " right",
        width: "10px",
        height: "fit-content",
        display: "flex",
        padding: "2px 5px 0px 5px",
        position: "absolute",
        minWidth: "max-content",
        marginBottom: "30px",
        // marginTop: '20px',

        fontWeight: "inherit",
        fontSize: "10px",
        marginLeft: "40px",
        borderRadius: "8px",
        backgroundColor: "#1D1D1E",
    },
    avatarStyle: {
        width: "80px",
        height: "80px",
        margin: "14px 10px auto 0",
        border: "6px solid #fff",
        "@media(max-width: 600px)": {
            margin: "-10px -55px auto auto",
            width: "80px",
            height: "80px",
        },
    },
    h6: {
        color: "#2880FB !important",
    },
    name: {
        margin: "50px 10px auto 0",
        fontWeight: "bold",
    },
    section__title: {},
}));
const Validation = () => {
    const classes = useStyle();
    const { t, i18n } = useTranslation();
    const history = useNavigate();
    const location = useLocation();
    const store = useStore();
    const dispatch = useDispatch();
    //const [smsError, setSmsError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [arr, setArr] = useState({
        labels: null,
        complement: null,
    });

    const { labels, complement } = arr;
    let proInf = store.getState().estimationConcierge.settings.proData;

    useEffect(() => {
        //if(proInf !== null && proInf !== undefined){
        if (proInf !== null && proInf !== undefined) {
            if (Object.keys(proInf).length > 0) {
                const labels = {
                    ponctuel: "hourglass",

                    professionnel: "Ok",

                    sociable: "sociable",

                    super_clean: "sponge",
                };
                let complement = [
                    { ponctuel: proInf.punctual },
                    { professionnel: proInf.professional },
                    { sociable: proInf.sociable },
                    { super_clean: proInf.super_clean },
                ];

                setArr({
                    labels: labels,
                    complement,
                });
            }
        }
    }, [proInf]);

    const [personalData, setPersonalData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        password: "12345678",
        confirmPassword: "12345678",
    });
    //    const [conditionAcceptation, setConditionAcceptation] = useState({ cgu: false, personalHouse: false });
    // const [conditionAcceptation, setConditionAcceptation] = useState({
    //     cgu: false,
    // });
    // const [displayPasswords, setDisplayPasswords] = useState({
    //     password: true,
    //     confirmPassword: true,
    // });
    const [showInfo, setShowInfo] = useState(false);
    const [errors, setErrors] = useState({});
    const [displayMore, setDisplayMore] = useState(false);

    if (
        location.pathname === "/concierge/validation" &&
        !store.getState().estimationConcierge.benefitSuccess
    ) {
        history("/concierge/housing");
        return <></>;
    }
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    };
    const handlePersonalDataChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (name === "email") value = value.trim().replace(/\s/g, "");
        if (name === "") {
            name = "telephone";
            value = `+${value.trim().replace(/\D/g, "")}`;
        }

        setPersonalData({ ...personalData, [name]: value });
    };

    // const handleConditionAcceptationChange = (name) => {
    //     setConditionAcceptation({
    //         ...conditionAcceptation,
    //         [name]: !conditionAcceptation[name],
    //     });
    // };

    // const handleDisplayPasswordClick = (event, name) => {
    //     event.preventDefault();
    //     setDisplayPasswords({
    //         ...displayPasswords,
    //         [name]: !displayPasswords[name],
    //     });
    // };

    const dataValidation = () => {
        const errors = {};
        if (
            !store.getState().estimationConcierge.settings.twiceOrMoreRequests
        ) {
            const firstName = personalData.firstName.trim();
            const lastName = personalData.lastName.trim();
            const email = personalData.email.trim().replace(/\s/g, "");
            const telephone = personalData.telephone.trim().replace(/\D/g, "");
            const password = personalData.password;
            const confirmPassword = personalData.confirmPassword;

            if (firstName === "") errors.firstName = true;
            if (lastName === "") errors.lastName = true;
            if (email === "") errors.email = true;
            if (
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    email
                )
            )
                errors.email = true;
            if (telephone === "") errors.telephone = true;
            if (telephone.length < 11) errors.telephone = true;
            if (password === "") errors.password = true;
            if (confirmPassword === "") errors.confirmPassword = true;
            if (password !== confirmPassword)
                errors.password = errors.confirmPassword = true;
        }

        // if (!conditionAcceptation.cgu) errors.cgu = true;
        //if (!conditionAcceptation.personalHouse) errors.personalHouse = true;

        setErrors(errors);

        return errors;
    };

    // const submit = () => {
    //     if (Object.keys(dataValidation()).length !== 0) return;

    //     if (
    //         !store.getState().estimationConcierge.settings.twiceOrMoreRequests
    //     ) {
    //         const requestBody = {
    //             ...store.getState().estimationConcierge.settings,
    //             ...personalData,
    //             lag: "fr",
    //         };
    //         estimationPersonalDataUpdate(requestBody)(dispatch);
    //         try {
    //             fetch(`${config.API_SERVER}/api/twilio/send`, {
    //                 method: "POST",
    //                 headers: { "Content-Type": "application/json" },
    //                 body: JSON.stringify({ telephone: requestBody.telephone }),
    //             }).then(() => history("/concierge/sms_validation"));
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     } else {
    //         //const requestBody = { ...store.getState().estimationConcierge.settings };
    //         const token = store.getState().auth.access_token;
    //         const requestBody = {
    //             ...store.getState().estimationConcierge.settings,
    //             ...personalData,
    //             lag: "fr",
    //         };
    //         fetch(`${config.API_SERVER}/api/concierge/reservation/reRequest`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify(requestBody),
    //         }).then((res) => {
    //             if (res.status === 200) {
    //                 history("/concierge/request-list");
    //             }
    //         });
    //     }
    // };

    const displaySpecificities = () => {
        const specificities =
            store.getState().estimationConcierge.settings.housingSpecificity;

        let maxDisplayedElement = 3;
        let elements = [];

        for (const specificity in specificities) {
            if (specificities[specificity] !== 0) {
                elements.push(
                    <li
                        style={{
                            display:
                                maxDisplayedElement < 1 && !displayMore
                                    ? "none"
                                    : "block",
                        }}
                        key={specificity}
                    >
                        {specificities[specificity]}{" "}
                        {t(`Client.Logement.housingSpecificity_${specificity}`)}
                    </li>
                );
                maxDisplayedElement--;
            }
        }

        return elements;
    };
    const displayKey = () => {
        let elements = [];
        let options =
            store.getState().estimationConcierge.settings
                .houseworkServiceAddition;
        for (const option in options) {
            if (
                options[option] === true &&
                option !== "less_48h_key" &&
                option !== "concierge_key"
            ) {
                elements.push(
                    <li key={option}>
                        {t(`Client.Benefit.houseworkServiceAddition_${option}`)}
                    </li>
                );
            }
        }

        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte6")}
                </p>,
            ];

            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };
    const displayBuanderie = () => {
        let options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization.laundry_room;

        let elements = [];
        console.log(options, "options");
        if (options === "1")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet1`)}
                </li>
            );
        if (options === "2")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet2`)}
                </li>
            );
        if (options === "3")
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkPersonalization_valet3`)}
                </li>
            );
        options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization;

        for (const option in options) {
            if (
                typeof options[option] === "number" &&
                options[option] !== 0 &&
                option.includes("bed")
            ) {
                elements.push(
                    <li key={option}>
                        {options[option]}{" "}
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            }
        }
        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte5")}
                </p>,
            ];
            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };
    const displayOptions = () => {
        let options =
            store.getState().estimationConcierge.settings
                .houseworkPersonalization;

        let elements = [];
        for (const option in options) {
            if (
                typeof options[option] === "number" &&
                options[option] !== 0 &&
                !option.includes("bed")
            ) {
                elements.push(
                    <li key={option}>
                        {options[option]}{" "}
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            } else if (options[option] === true) {
                elements.push(
                    <li key={option}>
                        {t(`Client.Benefit.houseworkPersonalization_${option}`)}
                    </li>
                );
            }
        }
        options =
            store.getState().estimationConcierge.settings
                .houseworkServiceAddition.concierge_key;

        if (options === true) {
            elements.push(
                <li key={options}>
                    {t(`Client.Benefit.houseworkServiceAddition_concierge_key`)}
                </li>
            );
        }

        let totalMoreElements = [];
        if (elements.length > 0) {
            totalMoreElements = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte4")}
                </p>,
            ];

            totalMoreElements.push(elements);
        }
        return totalMoreElements;
    };

    const displayFormula = () => {
        const houseworkFrequencyId =
            store.getState().estimationConcierge.settings.houseworkFrequencyId;
        const houseworkWeekTime =
            store.getState().estimationConcierge.settings.houseworkWeekTime;

        const startDate =
            store.getState().estimationConcierge.settings.startDate;

        let elements = [];

        //elements.push(<li key="1">{t(`Client.Validation.frequency_selected_${houseworkFrequencyId}`)}</li>);

        if (startDate) {
            if (
                houseworkFrequencyId === 1 &&
                houseworkWeekTime[0].period !== ""
            ) {
                if (houseworkWeekTime.length > 2)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[2].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[2].period}`
                            )}
                            )
                        </li>
                    );
                if (houseworkWeekTime.length > 1)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[1].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[1].period}`
                            )}
                            )
                        </li>
                    );
                if (houseworkWeekTime !== undefined)
                    elements.push(
                        <li key="2">
                            {t("Client.Time.the_with_date", {
                                date: startDateToTextualDate(
                                    houseworkWeekTime[0].weekDate
                                ),
                            })}{" "}
                            (
                            {t(
                                `Client.Time.period_${houseworkWeekTime[0].period}`
                            )}
                            )
                        </li>
                    );
                // } else if (houseworkWeekTime.length > 1)
                //     elements.push(
                //         <li key="2">
                //             {t("Client.Time.from_the_with_date", {
                //                 date: startDateToTextualDate(
                //                     houseworkWeekTime[1].weekDate
                //                 ),
                //             })}{" "}
                //             (
                //             {t(
                //                 `Client.Time.period_${houseworkWeekTime[1].period}`
                //             )}
                //             )
                //         </li>
                //     );
                // if (houseworkWeekTime.length > 2)
                //     elements.push(
                //         <li key="2">
                //             {t("Client.Time.from_the_with_date", {
                //                 date: startDateToTextualDate(
                //                     houseworkWeekTime[2].weekDate
                //                 ),
                //             })}{" "}
                //             (
                //             {t(
                //                 `Client.Time.period_${houseworkWeekTime[2].period}`
                //             )}
                //             )
                //         </li>
                //     );
                // if (houseworkWeekTime[0].period !== "") {
                //     elements.push(
                //         <li key="2">
                //             {t("Client.Time.from_the_with_date", {
                //                 date: startDateToTextualDate(
                //                     houseworkWeekTime[0].weekDate
                //                 ),
                //             })}{" "}
                //             (
                //             {t(`Client.Time.period_${houseworkWeekTime[0].period}`)}
                //             )
                //         </li>
                //     );
                // if (
                //     houseworkWeekTime.length > 1 &&
                //     houseworkWeekTime[1].period !== ""
                // ) {
                //     if (houseworkWeekTime.length > 1) {
                //         elements.push(<li key="3">{t("Client.Time.or")}</li>);
                //         elements.push(
                //             <li key="4">
                //                 {t("Client.Time.from_the_with_date", {
                //                     date: startDateToTextualDate(
                //                         houseworkWeekTime1[1].weekDate
                //                     ),
                //                 })}{" "}
                //                 (
                //                 {t(
                //                     `Client.Time.period_${houseworkWeekTime1[1].period}`
                //                 )}
                //                 )
                //             </li>
                //         );
                //     }
                //     if (houseworkWeekTime.length > 2) {
                //         elements.push(<li key="3">{t("Client.Time.or")}</li>);
                //         elements.push(
                //             <li key="4">
                //                 {t("Client.Time.from_the_with_date", {
                //                     date: startDateToTextualDate(
                //                         houseworkWeekTime2[1].weekDate
                //                     ),
                //                 })}{" "}
                //                 (
                //                 {t(
                //                     `Client.Time.period_${houseworkWeekTime2[1].period}`
                //                 )}
                //                 )
                //             </li>
                //         );
                //     }
                //     if (houseworkWeekTime.length > 2) {
                //         elements.push(<li key="3">{t("Client.Time.or")}</li>);
                //         elements.push(
                //             <li key="4">
                //                 {t("Client.Time.from_the_with_date", {
                //                     date: startDateToTextualDate(
                //                         houseworkWeekTime[1].weekDate
                //                     ),
                //                 })}{" "}
                //                 (
                //                 {t(
                //                     `Client.Time.period_${houseworkWeekTime[1].period}`
                //                 )}
                //                 )
                //             </li>
                //         );
                //     }
            }
            //}
        }

        let elementsMoreTitle = [];
        if (elements.length > 0) {
            elementsMoreTitle = [
                <p
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                >
                    {t("Client.Validation.bloc1_texte3")}
                </p>,
            ];
            elementsMoreTitle.push(elements);
        }
        return elementsMoreTitle;
    };

    const sendPaymentByClient = (
        formData,
        paymentMethodId,
        Price,
        Data,
        personalData,
        history
    ) => {
        if (Object.keys(dataValidation()).length !== 0) {
            console.log(
                "v1",
                Object.keys(dataValidation()).length,
                Object.keys(dataValidation())
            );
            return;
        } else {
            dispatch(
                paymentDoneByClient(
                    formData,
                    paymentMethodId,
                    Price,
                    Data,
                    personalData,
                    history
                )
            );
        }
    };

    // const getPasswordStrength = (password) => {
    //     if (!password || password === "") return 1;

    //     const hasUppers = /[A-Z]/.test(password);
    //     const hasLowers = /[a-z]/.test(password);
    //     const hasSpecialChars = /\W|_/.test(password);
    //     const hasNumeric = /[0-9]/.test(password);

    //     if (
    //         password.length > 8 &&
    //         hasUppers &&
    //         hasLowers &&
    //         hasSpecialChars &&
    //         hasNumeric
    //     ) {
    //         return 5;
    //     }

    //     if (
    //         password.length > 8 &&
    //         ((hasUppers && hasLowers && hasSpecialChars) ||
    //             (hasUppers && hasLowers && hasNumeric) ||
    //             (hasUppers && hasSpecialChars && hasNumeric) ||
    //             (hasLowers && hasSpecialChars && hasNumeric))
    //     ) {
    //         return 4;
    //     }

    //     if (
    //         password.length > 8 &&
    //         ((hasUppers && (hasLowers || hasSpecialChars || hasNumeric)) ||
    //             (hasLowers && (hasSpecialChars || hasNumeric)) ||
    //             (hasSpecialChars && hasNumeric))
    //     ) {
    //         return 3;
    //     }

    //     if (
    //         password.length > 6 &&
    //         ((hasUppers && (hasLowers || hasSpecialChars || hasNumeric)) ||
    //             (hasLowers && (hasSpecialChars || hasNumeric)) ||
    //             (hasSpecialChars && hasNumeric))
    //     ) {
    //         return 2;
    //     }

    //     return 1;
    // };

    // let submitBtnClasses = [
    //     "validation__submit_disabled",
    //     "validation__submit",
    // ];
    // //    if(conditionAcceptation.personalHouse && conditionAcceptation.cgu){
    // if (conditionAcceptation.cgu) {
    //     submitBtnClasses = ["validation__submit"];
    // }

    let totalPrice =
        store.getState().estimationConcierge.calculation.totalPrice;
    let tps = checkNumber((totalPrice * 5) / 100);
    let tvq = checkNumber((totalPrice * 9.975) / 100);

    let PriceTVQ = Math.round(tvq * 100) / 100;
    let PriceTPS = Math.round(tps * 100) / 100;
    let total = Math.round(totalPrice) + PriceTVQ + PriceTPS;
    // let minPrice = Math.round(totalPrice) - (totalPrice * 10) / 100;
    // let maxPrice = Math.round(totalPrice) + (totalPrice * 10) / 100;
    const totalf = calculateTpsTvqAndTotal(totalPrice, 0, 0, 0, "1");

    return (
        <Box className="Validation">
            <p>{t("Client.Validation.intro")}</p>

            <Box className="Validation_row">
                <Grid container>
                    {!store.getState().estimationConcierge.settings
                        .twiceOrMoreRequests && (
                        <Grid item lg={9} md={12} sm={12} xs={12}>
                            <Box className="Validation_col">
                                <Box className="section__title">
                                    <svg
                                        className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <circle
                                            cx="12"
                                            cy="12"
                                            r="12"
                                            color="#FFD74F"
                                        ></circle>
                                        <text
                                            className="MuiStepIcon-text"
                                            x="12"
                                            y="16"
                                            textAnchor="middle"
                                        >
                                            1
                                        </text>
                                    </svg>
                                    <Typography variant="h6">
                                        {t("Client.Validation.bloc2_title")}
                                    </Typography>
                                </Box>

                                <Box className="information_box">
                                    <form
                                        className="information_form"
                                        noValidate
                                    >
                                        <Box display={"block"}>
                                            {errors?.firstName ? (
                                                <Box
                                                    fontSize={"1.6rem"}
                                                    color={"#ff3e3e"}
                                                >
                                                    First name is required
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                            <TextField
                                                name="firstName"
                                                value={personalData.firstName}
                                                onChange={
                                                    handlePersonalDataChange
                                                }
                                                label={t(
                                                    "Client.Validation.name"
                                                )}
                                                variant="outlined"
                                                error={
                                                    errors?.firstName
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Box>
                                        <Box display={"block"}>
                                            {errors?.lastName ? (
                                                <Box
                                                    fontSize={"1.6rem"}
                                                    color={"#ff3e3e"}
                                                >
                                                    Last name is required
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                            <TextField
                                                name="lastName"
                                                value={personalData.lastName}
                                                onChange={
                                                    handlePersonalDataChange
                                                }
                                                label={t(
                                                    "Client.Validation.lastname"
                                                )}
                                                variant="outlined"
                                                error={
                                                    errors?.lastName
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Box>
                                        <Box display={"block"}>
                                            {errors?.email ? (
                                                <Box
                                                    fontSize={"1.6rem"}
                                                    color={"#ff3e3e"}
                                                >
                                                    {" "}
                                                    Email is required{" "}
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                            <TextField
                                                name="email"
                                                value={personalData.email}
                                                onChange={
                                                    handlePersonalDataChange
                                                }
                                                type="email"
                                                label={t(
                                                    "Client.Validation.email"
                                                )}
                                                variant="outlined"
                                                error={
                                                    errors?.email ? true : false
                                                }
                                            />
                                        </Box>
                                        <Box display={"block"}>
                                            {errors?.telephone ? (
                                                <Box
                                                    fontSize={"1.6rem"}
                                                    color={"#ff3e3e"}
                                                >
                                                    The phone is required
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                            <InputCustomPhone
                                                name="telephone"
                                                country="ca"
                                                onChange={
                                                    handlePersonalDataChange
                                                }
                                                value={personalData.telephone}
                                                disableCountryCode={false}
                                                disableDropdown={false}
                                                enableSearch={true}
                                                regions={[
                                                    "north-america",
                                                    "europe",
                                                ]}
                                                error={
                                                    errors?.telephone
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                            <Box marginTop={"20px"} padding={"25px 15px"}>
                                <PaymentForm
                                    price={
                                        store.getState().estimationConcierge
                                            .calculation.totalPrice
                                    }
                                    sendPaymentByClient={sendPaymentByClient}
                                    address={
                                        store.getState().estimationConcierge
                                            .settings.address
                                    }
                                    housingSpec={
                                        store.getState().estimationConcierge
                                            .settings.housingCategoryId
                                    }
                                    personalData={personalData}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Grid
                        item
                        lg={
                            !store.getState().estimationConcierge.settings
                                .twiceOrMoreRequests
                                ? 3
                                : 12
                        }
                        md={12}
                        sm={12}
                        xs={12}
                    >
                        <Box
                            className={
                                !store.getState().estimationConcierge.settings
                                    .twiceOrMoreRequests
                                    ? "Validation_col"
                                    : "Validation_TwiceRequest_col"
                            }
                        >
                            <Box>
                                <Box
                                    className="section__title"
                                    marginBottom={"0px"}
                                >
                                    <Typography variant="h4">
                                        {t("Client.Validation.bloc1_title")}
                                    </Typography>
                                </Box>
                                <hr
                                    color="#FFD74F"
                                    size="1"
                                    style={{
                                        marginTop: "0rem",
                                        marginLeft: "5px",
                                        color: "#FFD74F",
                                        height: "5px",
                                        size: "3",
                                        width: "60%",
                                    }}
                                ></hr>
                                <Box className="validation_recap">
                                    {!store.getState().estimationConcierge
                                        .twiceOrMoreRequests && (
                                        <>
                                            <p
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "2rem",
                                                    color: "#000",
                                                }}
                                            >
                                                {t(
                                                    `Client.Logement.housingCategory_${
                                                        store.getState()
                                                            .estimationConcierge
                                                            .settings
                                                            .housingCategoryId
                                                    }`
                                                )}
                                            </p>
                                            <ul className="recap_list">
                                                <li>
                                                    {
                                                        // t(`Client.Logement.housingCategory_${store.getState().estimationConcierge.settings.housingCategoryId}`)
                                                    }
                                                    {store.getState()
                                                        .estimationConcierge
                                                        .settings
                                                        .housingCategoryId ===
                                                        2 ||
                                                    store.getState()
                                                        .estimationConcierge
                                                        .settings
                                                        .housingCategoryId ===
                                                        3 ||
                                                    store.getState()
                                                        .estimationConcierge
                                                        .settings
                                                        .housingCategoryId === 4
                                                        ? t(
                                                              `Client.Logement.housingSize_${
                                                                  store.getState()
                                                                      .estimationConcierge
                                                                      .settings
                                                                      .housingSizeId
                                                              }`
                                                          ).toLowerCase()
                                                        : t(
                                                              `Client.Logement.studioSize`
                                                          ).toLowerCase()}
                                                    <span> </span>
                                                </li>

                                                {displaySpecificities()}

                                                {Object.keys(
                                                    displaySpecificities()
                                                ).length > 3 && (
                                                    <Button
                                                        className="see_more_btn"
                                                        onClick={() =>
                                                            setDisplayMore(
                                                                !displayMore
                                                            )
                                                        }
                                                    >
                                                        {displayMore ? (
                                                            <p className="see_more_text">
                                                                {t(
                                                                    "Client.sideBar.see_less"
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p className="see_more_text">
                                                                {t(
                                                                    "Client.sideBar.see_more"
                                                                )}
                                                            </p>
                                                        )}
                                                    </Button>
                                                )}
                                            </ul>
                                        </>
                                    )}

                                    {/* <h5 className="recap_title">{t("Client.Validation.bloc1_texte3")}</h5> */}

                                    <ul className="recap_list">
                                        {displayFormula()}
                                    </ul>
                                    <ul className="recap_list">
                                        {displayBuanderie()}
                                    </ul>
                                    <ul className="recap_list">
                                        {displayOptions()}
                                    </ul>
                                    <ul className="recap_list">
                                        {displayKey()}
                                    </ul>
                                </Box>
                            </Box>

                            <div>
                                <div className="demande__balance">
                                    <div className="balance__title">
                                        <Box
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "1.6rem",
                                                color: "#000",
                                            }}
                                            className="estimation_title"
                                        >
                                            {t("Client.sideBar.estimation")}{" "}
                                        </Box>
                                        <Box display={"flex"}>
                                            <Box
                                                display="flex"
                                                flexDirection={
                                                    i18n.language === "en"
                                                        ? "row-reverse"
                                                        : "row"
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        color: "#000",
                                                        margin: "0 10px",
                                                        width: "50px",
                                                        textAlign: "right",
                                                        fontSize: "1.6rem",
                                                    }}
                                                    className="estimation_price"
                                                >
                                                    {i18n.language === "fr"
                                                        ? checkNumber(
                                                              totalPrice
                                                          )
                                                              .toString()
                                                              .replace(".", ",")
                                                        : checkNumber(
                                                              totalPrice
                                                          )
                                                              .toString()
                                                              .replace(
                                                                  ",",
                                                                  "."
                                                              )}
                                                </Box>

                                                <Box
                                                    style={{
                                                        color: "#FFD74F",
                                                    }}
                                                >
                                                    $
                                                </Box>
                                            </Box>
                                        </Box>
                                    </div>
                                    <Box
                                        className="balance__title"
                                        display={"flex"}
                                    >
                                        <Box
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "1.5rem",
                                                color: "#000",
                                            }}
                                            className="estimation_title"
                                        >
                                            TPS
                                        </Box>
                                        <Box
                                            className="estimation_price"
                                            style={{
                                                float: "right",
                                                fontSize: "1.5rem",
                                                display: "flex",
                                                fontWeight: "normal",
                                                flexDirection:
                                                    i18n.language === "en"
                                                        ? "row-reverse"
                                                        : "row",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    margin: "0 10px",
                                                    width: "50px",
                                                    textAlign: "right",
                                                    color: "#000",
                                                }}
                                            >
                                                {i18n.language === "fr"
                                                    ? checkNumber(totalf.tps)
                                                          .toString()
                                                          .replace(".", ",")
                                                    : checkNumber(totalf.tps)
                                                          .toString()
                                                          .replace(",", ".")}
                                            </Box>
                                            <Box
                                                style={{
                                                    color: "#FFD74F",
                                                }}
                                            >
                                                {" "}
                                                $
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        className="balance__title"
                                        display={"flex"}
                                    >
                                        <Box
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "1.5rem",
                                                color: "#000",
                                            }}
                                            className="estimation_title"
                                        >
                                            TVQ
                                        </Box>
                                        <Box
                                            className="estimation_price"
                                            style={{
                                                float: "right",
                                                fontSize: "1.5rem",
                                                fontWeight: "normal",
                                                display: "flex",
                                                flexDirection:
                                                    i18n.language === "en"
                                                        ? "row-reverse"
                                                        : "row",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    margin: "0 10px",
                                                    width: "50px",
                                                    textAlign: "right",
                                                    color: "#000",
                                                }}
                                            >
                                                {i18n.language === "fr"
                                                    ? checkNumber(totalf.tvq)
                                                          .toString()
                                                          .replace(".", ",")
                                                    : totalf.tvq}
                                            </Box>
                                            <Box
                                                style={{
                                                    color: "#FFD74F",
                                                }}
                                            >
                                                {" "}
                                                $
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                                <Box className="balance__title" margin="10px">
                                    <Box
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "2rem",
                                            color: "#000",
                                        }}
                                        className="estimation_title"
                                    >
                                        TOTAL
                                    </Box>

                                    <Box display={"flex"}>
                                        <Box
                                            display={"flex"}
                                            flexDirection={
                                                i18n.language === "en"
                                                    ? "row-reverse"
                                                    : "row"
                                            }
                                        >
                                            <Box
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "2rem",
                                                    color: "#000",
                                                    margin: "0 10px",
                                                    width: "75px",
                                                    textAlign: "right",
                                                }}
                                                className="estimation_price"
                                            >
                                                {i18n.language === "fr"
                                                    ? checkNumber(
                                                          totalf.total.toFixed(
                                                              2
                                                          )
                                                      )
                                                          .toString()
                                                          .replace(".", ",")
                                                    : totalf.total.toFixed(2)}
                                            </Box>
                                            <Box
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "2rem",
                                                    color: "#FFD74F",
                                                }}
                                            >
                                                $
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <div className="services_footer">
                <p>
                    <Link
                        href="#"
                        className="services_backlink"
                        onClick={goBack}
                    >
                        {t("Client.Logement.back")}
                    </Link>
                </p>
            </div>
        </Box>
    );
};

export default Validation;
