import "../scss/app.scss";

import { withTranslation } from "react-i18next";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Footer from "../../../../layout/concierge/FooterServ";
import HeaderServ from "../../../../layout/concierge/HeaderServ";

import Button from "@material-ui/core/Button";
import { Typography, Box } from "@material-ui/core";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { authClientAction } from "../../../../../common/store/actions/authAction";
import { deleteData } from "../../../../../common/store/actions/estimationConciergeAction";
function ThankYouPageC({ t }) {
    const dispatch = useDispatch();
    const store = useStore();
    const history = useNavigate();
    const settings = store.getState().estimationConcierge.settings;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goClientLoginPage = (e) => {
        e.preventDefault();
        history("/");
        dispatch(deleteData());
        // const fData = {
        //     email: settings.email,
        //     password: settings.password
        // }
        // dispatch(authClientAction(fData, "",history))
    };

    return (
        <div>
            <HeaderServ />
            <Row className="Localisation thankyoupage">
                <Col lg={6} md={12} className="text-center">
                    {/* <img
                        src={"../images/icon_finished.png"}
                        alt=""
                        className="finished_icon"
                    /> */}
                    <CheckCircleOutlineIcon fontSize="large" color="primary" />
                    <Typography variant="h2" className="mb-5">
                        {t("Client.ThankyouPage.bloc1_text1")}{" "}
                        <span className="blue_text">
                            {
                                store.getState().estimationConcierge.settings
                                    .firstName
                            }
                        </span>
                    </Typography>
                    <Typography variant="h5" className="mb-3">
                        {t("Client.ThankyouPage.bloc1_text2")}
                    </Typography>

                    <Button
                        style={{
                            height: "50px",
                            width: "500px",
                            marginTop: "30px",
                        }}
                        className="btn_green_bg"
                        onClick={(e) => goClientLoginPage(e)}
                    >
                        {t("Client.ThankyouPage.bloc1_text4")}
                    </Button>
                </Col>

                <Col lg={6} md={12} style={{ marginTop: "50px" }}>
                    <Typography variant="h1" className="">
                        {t("Client.ThankyouPage.bloc2_text1")}
                    </Typography>

                    <Box className="mt-4" style={{ marginTop: "50px" }}>
                        <Box className="finalsteps">
                            <svg
                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <circle cx="12" cy="12" r="12"></circle>
                                <text
                                    className="MuiStepIcon-text"
                                    x="12"
                                    y="16"
                                    textAnchor="middle"
                                >
                                    1
                                </text>
                            </svg>
                            <p>{t("Client.ThankyouPage.bloc2_text2")}</p>
                        </Box>

                        <Box className="finalsteps">
                            <svg
                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <circle cx="12" cy="12" r="12"></circle>
                                <text
                                    className="MuiStepIcon-text"
                                    x="12"
                                    y="16"
                                    textAnchor="middle"
                                >
                                    2
                                </text>
                            </svg>
                            <p>{t("Client.ThankyouPage.bloc2_text3")}</p>
                        </Box>
                    </Box>
                </Col>
            </Row>

            <Row className="localisation_footer">
                {/* <p>{t("Client.Location.footer")} <Link to="#" className="link">{t("Client.Location.footer_link")} </Link></p> */}
            </Row>

            <MessengerCustomerChat
                pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            />
            <Footer />
        </div>
    );
}

export default withTranslation()(ThankYouPageC);
