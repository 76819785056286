import { useState } from "react";
import { useStore } from "react-redux";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Button, Grid, makeStyles } from "@material-ui/core";
import CheckoutFormElements from "./CheckoutFormElements";
import CheckoutFormInfoClient from "./CheckoutFormInfoClient";
import LabelWithBorder from "../../../../share/inputs/LabelWithBorder";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme) => ({
    payment: {
        marginTop: "40px",
        padding: "25px 15px",
        width: "100%",
        margin: "10px auto",
        height: "auto",
        backgroundColor: "#fff",
        //margin: theme.spacing(5, 3),
        "@media(max-width: 600px)": {
            margin: theme.spacing(5, 0),
        },
    },
    tariff: {
        padding: theme.spacing(0, 2, 0, 3),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "@media(max-width: 1200px)": {
            padding: theme.spacing(1),
        },
    },
    btnReserve: {
        display: "flex",
        flexDirection: "column",
        margin: theme.spacing(3, 0, 1, 0),
        "& button": {
            color: "#fff",
        },
        fontSize: "17px",
        "& span": {
            "@media(max-width: 1410px)": {
                fontSize: "14px",
            },
            "@media(max-width: 1279px)": {
                fontSize: "17px",
            },
        },
    },
    infoIcon: {
        BackgroundColor: "#F6F6F6",
        display: "flex",
        minHeight: "59.5px",
        "& svg": {
            marginTop: "-10px",
        },
        "@media(max-width: 600px)": {
            marginBottom: theme.spacing(1),
        },
    },
    btn: {
        width: "100%",
        BackgroundColor: "#FFD74F !important",
    },
}));

const CheckoutForm = ({
    price,
    sendPaymentByClient,
    address,
    housingSpec,
    personalData,
}) => {
    console.log("Direccion desde el cliente: ", address.postcode);
    const classes = useStyle();
    const { t } = useTranslation();
    const store = useStore();
    const history = useNavigate();
    const elements = useElements();
    const Data = store.getState().estimationConcierge.settings;
    const stripe = useStripe();
    const [formData, setFormData] = useState({
        name: "",
        //        postal: address.postcode,
        postal: "",
        street: "",
        appNumber: "",
        lat: null,
        lng: null,
        keepingCard: false,
        valStreet: false,
        checkApp: false,
    });

    const {
        name,
        postal,
        street,
        appNumber,
        keepingCard,
        valStreet,
        checkApp,
    } = formData;

    //const [postal, setPostal] = useState('');
    const [errorMessage, setErrorMessage] = useState({
        message: null,
        code: null,
    });

    //const [paymentMethod, setPaymentMethod] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        if (card === null) {
            return;
        }

        if (street.trim().length === 0) {
            setFormData({ ...formData, valStreet: true, street: "" });
            return;
        } else {
            setFormData({ ...formData, valStreet: false });
        }

        if (name.trim().length === 0) {
            setErrorMessage({
                message: "The name is required",
                code: "name_required",
            });
            return;
        } else {
            setErrorMessage({
                message: null,
                code: null,
            });
        }
        if (appNumber.trim().length === 0 && checkApp === false) {
            setErrorMessage({
                message: "The appartement number is required",
                code: "appNumber_required",
            });
            return;
        }
        if (postal.trim().length === 0) {
            setErrorMessage({
                message: "The code postal is required",
                code: "postal_required",
            });
            return;
        } else {
            setErrorMessage({
                message: null,
                code: null,
            });
        }

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card,
            billing_details: {
                name,
                address: {
                    postal_code: postal,
                },
            },
        });

        formData.card = {
            brand: payload.paymentMethod.card.brand,
            funding: payload.paymentMethod.card.funding,
            last4: payload.paymentMethod.card.last4,
        };
        console.log(formData, "formData");
        if (payload.error !== undefined) {
            setErrorMessage({
                message: payload.error.message,
                code: payload.error.code,
            });
        } else {
            console.log(Data, "history");
            sendPaymentByClient(
                formData,
                payload.paymentMethod.id,
                price,
                Data,
                personalData,
                history
            );

            setErrorMessage({
                message: null,
                code: null,
            });
        }
    };

    const handleStreetClient = (noAndStreet, lat, lng) => {
        setFormData({
            ...formData,
            street: noAndStreet,
            lat: lat,
            lng: lng,
        });
    };

    const handleFormData = (e) => {
        if (e.target.type === "checkbox") {
            setFormData({ ...formData, [e.target.name]: e.target.checked });
        } else {
            e.preventDefault();
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trimStart(),
            });
        }
    };

    return (
        <form>
            <Box className={classes.payment}>
                <Grid container>
                    <Grid items xs={12} sm={12} md={12} lg={7}>
                        <Box
                            className="section__title"
                            marginBottom={"20px"}
                            BackgroundColor={"#F6F6F6"}
                        >
                            <svg
                                className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    color="#FFD74F"
                                ></circle>
                                <text
                                    className="MuiStepIcon-text"
                                    x="12"
                                    y="16"
                                    textAnchor="middle"
                                >
                                    2
                                </text>
                            </svg>
                            <Typography variant="h6">
                                {t("Client.Validation.bloc3_title")}
                            </Typography>{" "}
                        </Box>
                        <Box>
                            {/* <Box p={1} pb={1} pt={2}>
                                <LabelWithBorder
                                    text={t(
                                        "REQUEST.CheckoutForm.step2.labelTitle"
                                    )}
                                />
                            </Box> */}
                            <Box className={classes.infoIcon} mt={1}>
                                <Box mx={1}>
                                    <InfoOutlinedIcon
                                        color="primary"
                                        style={{ fontSize: "2.5rem" }}
                                    />
                                </Box>
                                <p
                                    style={{
                                        fontSize: "15px",
                                        marginBottom: "0",
                                    }}
                                >
                                    {t(
                                        "REQUEST.CheckoutForm.step2.labelDescription"
                                    )}
                                </p>
                            </Box>
                            <CheckoutFormElements
                                name={name}
                                postal={postal}
                                keepingCard={keepingCard}
                                handleFormData={handleFormData}
                                errorMessage={errorMessage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.payment}>
                <Box className="section__title" marginBottom={"20px"}>
                    <svg
                        className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle cx="12" cy="12" r="12" color="#FFD74F"></circle>
                        <text
                            className="MuiStepIcon-text"
                            x="12"
                            y="16"
                            textAnchor="middle"
                        >
                            3
                        </text>
                    </svg>
                    <Typography variant="h6">
                        {t("Client.Validation.bloc4_title")}
                    </Typography>{" "}
                </Box>
                <Box>
                    {/* <Box p={1} pb={1}>
                                <LabelWithBorder
                                    text={t(
                                        "REQUEST.CheckoutForm.step1.labelTitle"
                                    )}
                                />
                            </Box> */}
                    <Box ml={1} pb={1}>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginBottom={"10px"}
                        >
                            <Box mr={1}>
                                <InfoOutlinedIcon
                                    color="primary"
                                    style={{ fontSize: "2.5rem" }}
                                />
                            </Box>
                            <p
                                style={{
                                    fontSize: "15px",
                                    margin: "0",
                                }}
                            >
                                {t(
                                    "REQUEST.CheckoutForm.step1.labelDescription"
                                )}
                            </p>
                        </Box>
                    </Box>
                    <CheckoutFormInfoClient
                        address={address}
                        street={street}
                        appNumber={appNumber}
                        handleFormData={handleFormData}
                        handleStreetClient={handleStreetClient}
                        valStreet={valStreet}
                        errorMessage={errorMessage}
                        checkApp={checkApp}
                    />
                </Box>
            </Box>
            <Box width={"100%"} marginTop={"20px"}>
                <button
                    onClick={handleSubmit}
                    className={classes.btn}
                    style={{
                        backgroundColor: "#FFD74F",
                        fontWeight: "bold",
                        border: "#FFD74F",
                        height: "50px",
                    }}
                >
                    {" "}
                    {t("Client.Validation.Button_Valider")}{" "}
                </button>
            </Box>
        </form>
    );
};

export default CheckoutForm;
