import { useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import Input from "../../../../share/inputs/Input";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete, {
    geocodeByAddress,
} from "react-places-autocomplete";
import { setAlert } from "../../../../../common/store/actions/alertAction";
import { useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { ErrorResult } from "./util";
import CheckBoxCustom from "../../../../share/inputs/CheckBoxCustom";
const useStyle = makeStyles((theme) => ({
    inputError: {
        "& #cardNumber, #expiry, #cvc, #name, #postal": {
            border: `2px solid #dc3545 !important`,
        },
    },
    textGroup: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "8px !important",
        "& .error": {
            fontSize: "1.6rem",
        },
        "& h5": {
            fontWeight: "normal",
            fontSize: "13px",
            color: "#737379",
            "@media(max-width: 1366px)": {
                fontSize: "14px",
            },
            "@media(max-width: 1200px)": {
                fontSize: "11px",
            },
        },
    },
    placesAutocomplete: {
        marginTop: "-30px",
    },
    searchIcon: {
        position: "relative",
        top: "40px",
        float: "right",
        backgroundColor: "#ffffff",
        marginRight: "300px",
    },
    groupLabelInputAddress: {
        "@media (max-width:900px)": {
            marginBottom: "0px",
            paddingRight: "0",
        },
        "& input": {
            width: "100%",
            height: "41.39px",
        },
    },
    addressSearch: {
        "& input": {
            width: "71%",
            height: "41.39px",
            marginTop: "5px",
            padding: "10px",
            border: "1px solid #2880F9",
            color: "#2880fb",
            borderRadius: "4px",
        },
        "& input:focus": {
            border: "1px solid #2880fb",
        },
    },
    formControlLabel: {
        fontSize: "1.2rem",
        lineHeight: "2",
        color: "#737379",
        fontWeight: "700",
        "& .error": {
            color: "red",
        },
    },
}));

const CheckoutFormInfoClient = ({
    address,
    street,
    errorMessage,
    appNumber,
    handleFormData,
    handleStreetClient,
    valStreet,
    checkApp,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [addressGoogle, setAddressGoogle] = useState("");
    const dispatch = useDispatch();
    let cardApp = classes.textGroup;
    let check = classes.check;
    if (errorMessage.message && errorMessage.code === "appNumber_required") {
        cardApp = cardApp + " " + classes.inputError;
        check = check + " " + classes.inputError;
    }
    const handleChangeAddress = (value) => {
        setAddressGoogle(value);
    };

    const handleSelectAddress = async (value) => {
        setAddressGoogle(value);
        geocodeByAddress(value)
            .then((results) => {
                const res = results[0].address_components;
                const no = res.filter(
                    (row) => row.types[0] === "street_number"
                );
                const route = res.filter((row) => row.types[0] === "route");
                const zip = res.filter((row) => row.types[0] === "postal_code");
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();

                if (no.length === 0) {
                    dispatch(
                        setAlert(
                            t("REQUEST.CheckoutForm.step1.noStreet"),
                            "error"
                        )
                    );
                } else {
                    if (zip[0].long_name === address.postcode) {
                        handleStreetClient(
                            `${no[0].long_name} ${route[0].long_name}`,
                            lat,
                            lng
                        );
                        dispatch(
                            setAlert(
                                t("REQUEST.CheckoutForm.step1.okPostalCode"),
                                "success"
                            )
                        );
                    } else {
                        dispatch(
                            setAlert(
                                t("REQUEST.CheckoutForm.step1.noPostalCode"),
                                "error"
                            )
                        );
                    }
                }
            })
            .catch((error) => console.error("Error", error));
    };

    return (
        <Box>
            <Grid container>
                <Grid itms xs={12} sm={12} md={12} lg={12}></Grid>

                <Grid itms xs={12} sm={12} md={6} lg={12}>
                    <Box className={classes.textGroup}>
                        <Box className={classes.groupLabelInputAddress}>
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step1.labelSearch")}
                            </Typography>
                            <Box style={{ marginTop: "-32px" }}>
                                <Box className={classes.searchIcon}>
                                    <SearchIcon />
                                </Box>
                                <PlacesAutocomplete
                                    value={addressGoogle}
                                    onChange={handleChangeAddress}
                                    onSelect={handleSelectAddress}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <Box className={classes.addressSearch}>
                                            <input
                                                {...getInputProps({
                                                    placeholder: t(
                                                        "ProForm.FormPersonalDetails.searchAdress"
                                                    ),
                                                })}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    zIndex: "1",
                                                    maxWidth: "450px",
                                                }}
                                            >
                                                {loading ? (
                                                    <div>...loading</div>
                                                ) : null}
                                                {suggestions.map(
                                                    (suggestion, index) => {
                                                        const style = {
                                                            backgroundColor:
                                                                suggestion.active
                                                                    ? "#2880fb"
                                                                    : "#ececec",
                                                            color: suggestion.active
                                                                ? "#fff"
                                                                : "#000",
                                                        };

                                                        return (
                                                            <div
                                                                key={index}
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    { style }
                                                                )}
                                                            >
                                                                {
                                                                    suggestion.description
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </Box>
                                    )}
                                </PlacesAutocomplete>
                            </Box>
                        </Box>
                    </Box>

                    {/* 
                    <Box className={classes.textGroup}>                                           
                        <Typography variant="h5">{ t("REQUEST.CheckoutForm.step1.labelStreet") }</Typography>
                        <Input
                            id="street" 
                            label="" 
                            size="medium" 
                            //onBlur={handleFormData} 
                            defaultValue={ street } 
                            variant="outlined" 
                            readOnly={true}
                            error={valStreet ? t("REQUEST.CheckoutForm.step1.labelError") : ''}
                        />                                    
                    </Box>                    
 */}
                </Grid>
                <Box display={"flex"}>
                    <Box display={"flex"} marginTop={"20px"} width={"230px"}>
                        {checkApp === false &&
                        errorMessage.message &&
                        errorMessage.code === "appNumber_required" ? (
                            <CheckBoxCustom
                                outline={true}
                                color={
                                    checkApp === false && errorMessage.message
                                        ? "warning"
                                        : "primary"
                                }
                                className={check}
                                name="checkApp"
                                label={
                                    <Typography
                                        className={classes.formControlLabel}
                                    >
                                        {t(
                                            "REQUEST.CheckoutForm.step1.labelCheckBox"
                                        )}
                                    </Typography>
                                }
                                value={checkApp}
                                handleChange={(e) => handleFormData(e)}
                            />
                        ) : (
                            <CheckBoxCustom
                                outline={false}
                                color={
                                    checkApp === false && errorMessage.message
                                        ? "warning"
                                        : "primary"
                                }
                                className={check}
                                name="checkApp"
                                label={
                                    <Typography
                                        className={classes.formControlLabel}
                                    >
                                        {t(
                                            "REQUEST.CheckoutForm.step1.labelCheckBox"
                                        )}
                                    </Typography>
                                }
                                value={checkApp}
                                handleChange={(e) => handleFormData(e)}
                            />
                        )}
                        {console.log(checkApp, "checkkk")}
                    </Box>
                    <Grid itms xs={12} sm={12} md={6} lg={6}>
                        <Box className={cardApp}>
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step1.labelApp")}
                            </Typography>
                            <Input
                                id="appNumber"
                                label=""
                                size="medium"
                                onBlur={handleFormData}
                                defaultValue={appNumber}
                                variant="outlined"
                                readOnly={false}
                                error=""
                                checked={checkApp}
                            />
                            {checkApp === false &&
                                errorMessage.message &&
                                errorMessage.code === "appNumber_required" &&
                                errorMessage.message && (
                                    <ErrorResult>
                                        {errorMessage.message}
                                    </ErrorResult>
                                )}
                        </Box>
                    </Grid>
                </Box>
                <Box display={"flex"}>
                    <Grid itms xs={12} sm={12} md={3} lg={6}>
                        <Box className={classes.textGroup}>
                            <Typography variant="h5">
                                {t(
                                    "REQUEST.CheckoutForm.step1.labelPostalCode"
                                )}
                            </Typography>
                            <Input
                                id="postcode"
                                label=""
                                size="medium"
                                //onBlur={handleChange}
                                defaultValue={address.postcode}
                                variant="outlined"
                                readOnly={true}
                                error=""
                            />
                        </Box>
                    </Grid>
                    <Grid itms xs={12} sm={12} md={3} lg={6}>
                        <Box className={classes.textGroup}>
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step1.labelCity")}
                            </Typography>
                            <Input
                                id="city"
                                label=""
                                size="medium"
                                //onBlur={handleChange}
                                defaultValue={address.city}
                                variant="outlined"
                                readOnly={true}
                                error=""
                            />
                        </Box>
                    </Grid>
                    <Grid itms xs={12} sm={12} md={6} lg={6}>
                        <Box className={classes.textGroup}>
                            <Typography variant="h5">
                                {t("REQUEST.CheckoutForm.step1.labelCountry")}
                            </Typography>
                            <Input
                                id="country"
                                label=""
                                size="medium"
                                //onBlur={handleChange}
                                defaultValue={address.country}
                                variant="outlined"
                                readOnly={true}
                                error=""
                            />
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    );
};

export default CheckoutFormInfoClient;
