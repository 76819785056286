import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

const CounterCard = ({ count,  handleCounterLessClick, handleCounterMoreClick, name }) => {
return (
  <Box > 
  <div className="counter">
          <h6>{name}</h6>
          <button className="counter_btn" onClick={handleCounterLessClick}>
              -
          </button>
          <p>{count}</p>
          <button className="counter_btn" onClick={handleCounterMoreClick}>
              +
          </button>
      </div>
      
  </Box>
)
}
export default withTranslation()(CounterCard);