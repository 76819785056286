import { useState, useLayoutEffect } from "react";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import Stepper from "../../components/views/mrConcierge/quote_generator/section-parts/HorizontalLinearStepper";

import { Navbar, Nav } from "react-bootstrap";
import {
    Box,
    Container,
    Typography,
    AppBar,
    Toolbar,
    useMediaQuery,
    Button,
    Hidden,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import AlertMessage from "../layout/AlertMessage";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";

// IMPORTING ICONS
import MenuIcon from "@material-ui/icons/Menu";

const useStyle = makeStyles((theme) => ({
    headerArea: {
        "& .MuiToolbar-gutters": {
            padding: "0",
        },
    },
    headerBakgroundColor: {
        backgroundColor: "#fff",
        width: "100%",
    },
    header: {
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    imgWidth: {
        "& img": {
            height: "3.3rem",
            margin: theme.spacing(0),
        },
        "@media (min-width:993px)": {
            "& img": {
                height: "7rem",
                margin: theme.spacing(2),
            },
        },
    },
    avatarStyle: {
        "@media (min-width: 993px)": {
            display: "none",
        },
    },
    langauageStyle: {
        width: "2.5rem",
        color: "#fff",
        fontSize: "2.1rem",
        "&:hover": {
            color: "#fff",
            fontWeight: "bold",
            textDecoration: "none",
        },
    },
}));

const useStylesPR = makeStyles((theme) => ({
    header: {
        "& .MuiPaper-elevation4": {
            backgroundColor: "#fff !important",
            boxShadow: "none",
            transition: "ease-in 0.3s",
            "& a": {
                color: "#fff",
                "& span.MuiButton-label": {
                    color: "#fff",
                },
            },
            "& .MuiButton-root": {
                border: "3px solid #fff",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "transparent",
                    "& span.MuiButton-label": {
                        color: "#fff",
                    },
                },
                "&:active": {
                    border: `3px solid #fff"`,
                },
            },
        },
    },
    headerTransparent: {
        "& .MuiPaper-elevation4": {
            backgroundColor: "#fff",
        },
        "& .MuiToolbar-regular": {
            padding: "5px 0",
            transition: "ease-in 0.3s",
        },
    },
    headerWhite: {
        "& .MuiPaper-elevation4": {
            backgroundColor: "#fff",
            "& a": {
                color: "#000",
            },
            "& .MuiButton-root": {
                border: `3px solid #fff`,
                color: theme.palette.primary.main,
                "&:hover": {
                    backgroundColor: "#fff",
                    "& span.MuiButton-label": {
                        color: "#fff",
                    },
                },
                "&:active": {
                    border: `3px solid "#fff"`,
                },
            },
        },
        "& .MuiToolbar-regular": {
            padding: "0",
            transition: "ease-in 0.3s",
        },
    },
    linkStyle: {
        margin: "0 51px",
        padding: "5px",
        color: "#000",
        fontSize: "20px",
        textDecoration: "none",
        "@media(max-width: 1280px)": {
            margin: "20px",
        },
        "@media(max-width: 1200px)": {
            margin: "10px",
        },
        "&:hover": {
            color: "#000",
            fontWeight: "bold",
            textDecoration: "none",
        },
    },
    langauageStyle: {
        margin: "0 51px",
        padding: "5px",
        width: "2.5rem",
        color: "#000",
        fontSize: "2.1rem",
        "@media(max-width: 1280px)": {
            margin: "20px",
        },
        "@media(max-width: 1200px)": {
            margin: "10px",
        },
        "&:hover": {
            fontWeight: "bold",
            color: "#000",
            textDecoration: "none",
        },
    },
    appBar: {
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#fff",

            padding: "30px 5%",
        },
    },
    container: {
        width: "100%",
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#fff",
        },
        "& .MuiToolbar-root": {
            width: "100%",
            maxWidth: "1600px",
            margin: "auto",
            display: "flex",
            "@media(max-width: 900px)": {
                flexDirection: "column",
            },
        },
        // padding: "27px 5%",
        "& .MuiToolbar-regular": {
            "@media(max-width: 900px)": {
                minHeight: "53px",
            },
            "@media(max-width: 600px)": {
                minHeight: "45px",
            },
        },
        // "& img": {
        //     width: "100%",
        //     "@media(max-width: 900px)": {
        //         width: "100%",
        //     },
        //     "@media(max-width: 600px)": {
        //         width: "100%",
        //     },
        // },
        "@media(max-width: 900px)": {
            padding: "15px 15px 0 15px",
        },
    },
    navBar: {
        width: "68%",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        "& .MuiButton-root": {
            padding: "10px 28px",
            borderRadius: "0",
            backgroundColor: "#fff",
        },
        "@media(max-width: 900px)": {
            width: "100%",
            flexDirection: "column",
        },
    },
    imgArea: {
        marginBottom: "64px",
        width: "19%",
        marginRight: "80px",
        "@media(max-width: 900px)": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
    homeStyle: {
        marginRight: "226px",
        "@media(max-width: 1280px)": {
            marginRight: "0",
        },
    },
    optionNav: {
        "@media(max-width: 900px)": {
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "@media(max-width: 600px)": {
                width: "100%",
            },
        },
    },
    disabledLink: {
        color: "#bcbcbc !important",
        pointerEvents: "none",
    },
}));

export const HeaderCg = ({ backgroundColorStatic }) => {
    const classes = useStylesPR();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    //const theme = useTheme();
    //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    //const [scrollPosition, setPosition] = useState(0);

    useLayoutEffect(() => {
        function updatePosition() {
            //setPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    const changeLanguage = (lang) => {
        //When the user click on the button to change the language, this function is reached and the language is changed.
        i18n.changeLanguage(lang);
        // if(isMobile){
        //   setAnchor(null)
        // }
    };

    const lang = i18n.language;
    let langLabel = "En";
    if (lang === "en") {
        langLabel = "Fr";
    }

    const displayMenu = (e) => {
        e.preventDefault();
        setOpen(!open);
    };

    //let headerStayle = classes.header;
    // if (!backgroundColorStatic) {
    //     if (isMobile) {
    //         headerStayle = headerStayle + " " + classes.headerWhite;
    //     } else {
    //         headerStayle = headerStayle + " " + classes.headerTransparent;
    //     }
    //     if (scrollPosition >= 60) {
    //         headerStayle = classes.header + " " + classes.headerWhite;
    //     }
    // }

    return (
        <Box className={classes.appBar}>
            <AppBar>
                <Box className={classes.container}>
                    <Toolbar>
                        <Box className={classes.imgArea}>
                            <Box>
                                <a
                                    href="https://www.monsieurconcierge.ca/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={`/images/logo_Concierge_blanc.svg`}
                                        alt=""
                                        style={{ width: "200px" }}
                                    />
                                </a>
                            </Box>

                            <Hidden mdUp>
                                <Box>
                                    <MenuIcon
                                        style={{ color: "#000" }}
                                        onClick={(e) => displayMenu(e)}
                                    />
                                </Box>
                            </Hidden>
                        </Box>
                        {/* <Box
                            className={classes.navBar}
                            hidden={!isMobile ? false : open ? false : true}
                        >
                            <Box className={classes.optionNav}> */}
                        {/* <Link className={classes.linkStyle} to="/proform">
                      Services
                    </Link> */}
                        {/* </Box> */}

                        {/* <Box className={classes.optionNav}>
                                <Link
                                    className={classes.langauageStyle}
                                    to="?#"
                                    onClick={() =>
                                        changeLanguage(langLabel.toLowerCase())
                                    }
                                >
                                    {langLabel}
                                </Link>
                            </Box> */}
                        {/* </Box> */}
                        <Box marginLeft={"-20px"} display={"flex"}>
                            <Navbar style={{ display: "block" }}>
                                <Nav className="NavBar__menu">
                                    <div>
                                        <Stepper
                                            style={{
                                                paddingRight:
                                                    "100px !important",
                                            }}
                                        />
                                    </div>
                                </Nav>
                                <Box
                                    color={"#000"}
                                    marginTop={"10px"}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    // marginTop: "300px",
                                    // marginBottom: "100px",
                                    /* height: 100%; */
                                    justifyContent="center"
                                >
                                    <h2>{t("Client.Hero.title2")}</h2>
                                </Box>
                            </Navbar>
                            <Nav.Link
                                style={{ color: "#000" }}
                                href="#"
                                className="NavBar__language__item NavBar__item"
                                onClick={() =>
                                    changeLanguage(langLabel.toLowerCase())
                                }
                            >
                                {langLabel}
                            </Nav.Link>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </Box>
    );
};

export const HeaderRF = ({
    isLoggedIn,
    logOut,
    logOutUrl,
    backgroundColorStatic,
}) => {
    const classes = useStylesPR();
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    const [scrollPosition, setPosition] = useState(0);
    let link = logOutUrl.includes("client");
    useLayoutEffect(() => {
        function updatePosition() {
            setPosition(window.pageYOffset);
        }
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    const changeLanguage = (lang) => {
        //When the user click on the button to change the language, this function is reached and the language is changed.
        i18n.changeLanguage(lang);
        // if(isMobile){
        //   setAnchor(null)
        // }
    };

    const lang = i18n.language;
    let langLabel = "En";
    if (lang === "en") {
        langLabel = "Fr";
    }

    const displayMenu = (e) => {
        e.preventDefault();
        setOpen(!open);
    };

    let headerStayle = classes.header;
    if (!backgroundColorStatic) {
        if (isMobile) {
            headerStayle = headerStayle + " " + classes.headerWhite;
        } else {
            headerStayle = headerStayle + " " + classes.headerTransparent;
        }
        if (scrollPosition >= 60) {
            headerStayle = classes.header + " " + classes.headerWhite;
        }
    }

    return (
        <Box className={headerStayle}>
            <AppBar>
                <Box className={classes.container}>
                    <Toolbar>
                        <Box className={classes.imgArea}>
                            <Box className={classes.homeStyle}>
                                <Link to="/">
                                    <img
                                        src={`/images/${
                                            !backgroundColorStatic
                                                ? "logo_tiggidoo.svg"
                                                : "logo_tiggidoo_white.svg"
                                        }`}
                                        alt=""
                                    />
                                </Link>
                            </Box>
                            <Hidden mdUp>
                                <Box>
                                    <MenuIcon
                                        style={{ color: "#000" }}
                                        onClick={(e) => displayMenu(e)}
                                    />
                                </Box>
                            </Hidden>
                        </Box>

                        <Box
                            className={classes.navBar}
                            hidden={!isMobile ? false : open ? false : true}
                        >
                            <Box className={classes.optionNav}>
                                {/* <Link className={classes.linkStyle} to="/proform">
                      Services
                    </Link> */}
                                <Link
                                    className={
                                        window.location.pathname === "/proform"
                                            ? classes.linkStyle +
                                              " " +
                                              classes.disabledLink
                                            : classes.linkStyle
                                    }
                                    to="/proform"
                                >
                                    {t("Nav.menu.becomeTodo")}
                                </Link>
                            </Box>

                            <Box className={classes.optionNav}>
                                {!isLoggedIn ? (
                                    <Button
                                        href={logOutUrl}
                                        variant="contained"
                                        className={classes.btnLogin}
                                    >
                                        {t("Nav.menu.login")}
                                    </Button>
                                ) : (
                                    // <Button variant="contained" className={classes.btnLogin} onClick = { e=>logOut(e)} >
                                    //   {t("Nav.menu.logout")}
                                    // </Button>

                                    <Link
                                        to={
                                            link
                                                ? "/client/request-list"
                                                : "/pro/reservations-list"
                                        }
                                        className={classes.linkStyle}
                                    >
                                        <PersonOutlineOutlinedIcon />
                                        Mon Espace
                                    </Link>
                                )}
                                <Link
                                    className={classes.langauageStyle}
                                    to="?#"
                                    onClick={() =>
                                        changeLanguage(langLabel.toLowerCase())
                                    }
                                >
                                    {langLabel}
                                </Link>
                            </Box>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </Box>
    );
};

export const Header = ({ name, urlAvatar, typeOfUser }) => {
    const classes = useStyle();
    const { t, i18n } = useTranslation();
    function changeLanguage(lang) {
        //When the user click on the button to change the language, this function is reached and the language is changed.
        i18n.changeLanguage(lang);
    }
    const lang = i18n.language;
    let langLabel = "En";
    if (lang === "en") {
        langLabel = "Fr";
    }

    return (
        <Box className={classes.headerArea}>
            <AppBar>
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box className={classes.headerBakgroundColor}>
                            <AlertMessage />
                            <Box className={classes.header}>
                                <Box className={classes.imgWidth}>
                                    <Link
                                        to={
                                            typeOfUser === "client"
                                                ? "/client"
                                                : ""
                                        }
                                    >
                                        <img
                                            src="/images/logo_tiggidoo_white.svg"
                                            alt=""
                                        />
                                    </Link>
                                </Box>
                                <Box className={classes.avatarStyle}>
                                    <Avatar alt={name} src={urlAvatar} />
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box mr={2}>
                                        <Typography
                                            component="h2"
                                            variant="h5"
                                            style={{ color: "#fff" }}
                                        >{`${t(
                                            "Nav.menu.hi"
                                        )} ${name}`}</Typography>
                                    </Box>
                                    <Link
                                        className={classes.langauageStyle}
                                        to="?#"
                                        onClick={() =>
                                            changeLanguage(
                                                langLabel.toLowerCase()
                                            )
                                        }
                                    >
                                        {langLabel}
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};
