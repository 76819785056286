import { useState } from "react";
import { useStore } from "react-redux";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import {
    calculateTpsTvqAndTotal,
    checkNumber,
} from "../../../../share/librery/librery";

const Balance = () => {
    const store = useStore();
    const { t, i18n } = useTranslation();

    const [showInfo, setShowInfo] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    useStore().subscribe(() => {
        setTotalPrice(
            store.getState().estimationConcierge.calculation.totalPrice
        );
    });
    let tps = checkNumber((totalPrice * 5) / 100);
    let tvq = checkNumber((totalPrice * 9.975) / 100);

    const totalf = calculateTpsTvqAndTotal(totalPrice, 0, 0, 0, "1");

    return (
        <div>
            <div className="demande__balance">
                <div className="balance__title">
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="1"
                        fontSize="16px"
                        fontWeight="bold"
                        mb={1}
                    >
                        <Box
                            style={{
                                color: "#000",
                            }}
                            className="estimation_title"
                        >
                            {t("Client.sideBar.estimation")}{" "}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={
                                i18n.language === "en" ? "row-reverse" : "row"
                            }
                        >
                            <Box
                                style={{
                                    color: "#000",
                                    margin: "0 10px",
                                    width: "50px",
                                    textAlign: "right",
                                }}
                                className="estimation_price"
                            >
                                {i18n.language === "fr"
                                    ? checkNumber(totalPrice)
                                          .toString()
                                          .replace(".", ",")
                                    : checkNumber(totalPrice)
                                          .toString()
                                          .replace(",", ".")}
                            </Box>

                            <Box
                                style={{
                                    color: "#FFD74F",
                                }}
                            >
                                $
                            </Box>
                        </Box>
                    </Box>
                </div>
                <Box display={"flex"} className="balance__title" mb={0.5}>
                    <Box
                        style={{
                            fontSize: "1.5rem",
                            color: "#000",
                        }}
                        className="estimation_title"
                    >
                        TPS
                    </Box>
                    <Box
                        className="estimation_price"
                        style={{
                            float: "right",
                            fontSize: "1.5rem",
                            display: "flex",
                            flexDirection:
                                i18n.language === "en" ? "row-reverse" : "row",
                        }}
                    >
                        <Box
                            style={{
                                margin: "0 10px",
                                width: "50px",
                                textAlign: "right",
                            }}
                        >
                            {i18n.language === "fr"
                                ? checkNumber(totalf.tps)
                                      .toString()
                                      .replace(".", ",")
                                : checkNumber(totalf.tps)
                                      .toString()
                                      .replace(",", ".")}
                        </Box>
                        <Box> $</Box>
                    </Box>
                </Box>

                <Box display={"flex"} className="balance__title">
                    <Box
                        style={{
                            fontSize: "1.4rem",
                            color: "#000",
                        }}
                        className="estimation_title"
                    >
                        TVQ
                    </Box>
                    <Box
                        className="estimation_price"
                        style={{
                            float: "right",
                            fontSize: "1.5rem",
                            display: "flex",
                            flexDirection:
                                i18n.language === "en" ? "row-reverse" : "row",
                        }}
                    >
                        <Box
                            style={{
                                margin: "0 10px",
                                width: "50px",
                                textAlign: "right",
                            }}
                        >
                            {i18n.language === "fr"
                                ? checkNumber(totalf.tvq)
                                      .toString()
                                      .replace(".", ",")
                                : totalf.tvq}
                        </Box>
                        <Box> $</Box>
                    </Box>
                </Box>
            </div>
            <Box className="balance__title" margin="10px">
                <Box
                    style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        color: "#000",
                    }}
                    className="estimation_title"
                >
                    TOTAL
                </Box>
                <Box display={"flex"}>
                    <Box
                        display={"flex"}
                        flexDirection={
                            i18n.language === "en" ? "row-reverse" : "row"
                        }
                    >
                        <Box
                            style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: "#000",
                                margin: "0 10px",
                                width: "75px",
                                textAlign: "right",
                            }}
                            className="estimation_price"
                        >
                            {i18n.language === "fr"
                                ? checkNumber(totalf.total.toFixed(2))
                                      .toString()
                                      .replace(".", ",")
                                : totalf.total.toFixed(2)}
                        </Box>
                        <Box
                            style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: "#FFD74F",
                            }}
                        >
                            $
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Balance;
