import axios from "axios";
import config from "../../../config.json";
import { getTimeUnix } from "../../../components/share/librery/librery";

import {
    ESTIMATION_BENEFIT_UPDATE_C,
    ESTIMATION_SERVICE_ADDITION,
    ESTIMATION_BENEFIT_VALIDATION_ERROR_C,
    ESTIMATION_BENEFIT_VALIDATION_SUCCESS_C,
    ESTIMATION_HOUSING_UPDATE_C,
    ESTIMATION_HOUSING_VALIDATION_ERROR_C,
    ESTIMATION_HOUSING_VALIDATION_SUCCESS_C,
    ESTIMATION_LOCATION_UPDATE_C,
    ESTIMATION_PERSONAL_DATA_UPDATE_C,
    ESTIMATION_BANDERIE_UPDATE,
    FETCH_ESTIMATION_C,
    FETCH_NEW_ESTIMATION_C,
    ESTIMATION_NEW_REQUEST_C,
    GET_LOCKED_DATE_C,
    DELETE_DATA,
} from "./typesAction";

export const estimationLocationUpdate = (payload) => (dispatch) => {
    console.log("UltimatumL ", JSON.stringify(payload));
    dispatch({
        type: ESTIMATION_LOCATION_UPDATE_C,
        payload,
    });
};
export const estimationBanderieUpdate = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_BANDERIE_UPDATE,
        payload,
    });
};
export const deleteData = () => (dispatch) => {
    dispatch({
        type: DELETE_DATA,
    });
};

export const estimationHousingUpdate = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_HOUSING_UPDATE_C,
        payload,
    });
};

export const estimationHousingValidationSuccess = () => (dispatch) => {
    dispatch({
        type: ESTIMATION_HOUSING_VALIDATION_SUCCESS_C,
    });
};

export const estimationHousingValidationError = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_HOUSING_VALIDATION_ERROR_C,
        payload,
    });
};

export const estimationBenefitUpdate = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_BENEFIT_UPDATE_C,
        payload,
    });
};
export const estimationServiceAddition = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_SERVICE_ADDITION,
        payload,
    });
};

export const estimationBenefitValidationSuccess = () => (dispatch) => {
    dispatch({
        type: ESTIMATION_BENEFIT_VALIDATION_SUCCESS_C,
    });
};

export const estimationBenefitValidationError = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_BENEFIT_VALIDATION_ERROR_C,
        payload,
    });
};

export const estimationPersonalDataUpdate = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_PERSONAL_DATA_UPDATE_C,
        payload,
    });
};

export const estimationNewRequest = (payload) => (dispatch) => {
    dispatch({
        type: ESTIMATION_NEW_REQUEST_C,
        payload,
    });
};

// export const fetchEstimation = (requestBody) => (dispatch) => {
//     console.log(JSON.stringify(requestBody), "fetchEstimation");
//     axios
//         .post(`${config.API_SERVER}/api/housing/estimation`, requestBody)
//         .then((request) => {
//             console.log(
//                 request.data.estimation.calculation.totalPrice * 3,
//                 "calculation"
//             );
//             dispatch({
//                 type: FETCH_ESTIMATION_C,
//                 payload: request.data.estimation.calculation,
//             });
//         });
// };
export const fetchEstimation = (requestBody) => (dispatch) => {
    axios
        .post(`${config.API_SERVER}/api/housing/estimation`, requestBody)
        .then((request) => {
            let newCalculation = request.data.estimation.calculation;
            const less_48h_key =
                request.data.estimation.houseworkServiceAdditionAll.filter(
                    (row) => row.name === "less_48h_key"
                );
            console.log("Alternativa: ", JSON.stringify(requestBody));

            if (requestBody.houseworkWeekTime !== undefined) {
                if (requestBody.houseworkWeekTime.length > 0) {
                    if (requestBody.houseworkServiceAddition.less_48h_key) {
                        let valLess = 0;
                        if (requestBody.houseworkWeekTime.length >= 2) {
                            valLess =
                                parseFloat(less_48h_key[0].price, 10) *
                                (requestBody.houseworkWeekTime.length - 1);
                        }
                        newCalculation = {
                            totalPrice:
                                request.data.estimation.calculation.totalPrice *
                                    requestBody.houseworkWeekTime.length -
                                valLess,
                            totalDuration:
                                request.data.estimation.calculation
                                    .totalDuration,
                            less48h: parseFloat(less_48h_key[0].price, 10),
                        };
                    } else {
                        newCalculation = {
                            totalPrice:
                                request.data.estimation.calculation.totalPrice *
                                requestBody.houseworkWeekTime.length,
                            totalDuration:
                                request.data.estimation.calculation
                                    .totalDuration,
                            less48h: 0,
                        };
                    }
                }
            }

            dispatch({
                type: FETCH_ESTIMATION_C,
                payload: newCalculation,
            });
        });
};

export const fetchEstimationCopy = (requestBody) => (dispatch) => {
    console.log("Paz total 23: ", JSON.stringify(requestBody));
    if (requestBody.houseworkWeekTime !== undefined) {
        if (requestBody.houseworkWeekTime.length > 2) {
            if (requestBody.houseworkServiceAddition.less_48h_key) {
                //YA
                axios
                    .post(
                        `${config.API_SERVER}/api/housing/estimation`,
                        requestBody
                    )
                    .then((request) => {
                        let data = {
                            totalPrice:
                                request.data.estimation.calculation.totalPrice *
                                    requestBody.houseworkWeekTime.length -
                                50,
                            totalDuration:
                                request.data.estimation.calculation
                                    .totalDuration,
                        };
                        dispatch({
                            type: FETCH_ESTIMATION_C,
                            payload: data,
                        });
                    });
            } else {
                axios
                    .post(
                        `${config.API_SERVER}/api/housing/estimation`,
                        requestBody
                    )
                    .then((request) => {
                        let data = {
                            totalPrice:
                                request.data.estimation.calculation.totalPrice *
                                requestBody.houseworkWeekTime.length,
                            totalDuration:
                                request.data.estimation.calculation
                                    .totalDuration,
                        };
                        dispatch({
                            type: FETCH_ESTIMATION_C,
                            payload: data,
                        });
                    });
            }
        } else if (requestBody.houseworkWeekTime.length > 1) {
            if (requestBody.houseworkServiceAddition.less_48h_key) {
                axios
                    .post(
                        `${config.API_SERVER}/api/housing/estimation`,
                        requestBody
                    )
                    .then((request) => {
                        let data = {
                            totalPrice:
                                request.data.estimation.calculation.totalPrice *
                                    requestBody.houseworkWeekTime.length -
                                25,
                            totalDuration:
                                request.data.estimation.calculation
                                    .totalDuration,
                        };
                        dispatch({
                            type: FETCH_ESTIMATION_C,
                            payload: data,
                        });
                    });
            }
        } else {
            axios
                .post(
                    `${config.API_SERVER}/api/housing/estimation`,
                    requestBody
                )
                .then((request) => {
                    let data = {
                        totalPrice:
                            request.data.estimation.calculation.totalPrice,
                        totalDuration:
                            request.data.estimation.calculation.totalDuration,
                    };
                    dispatch({
                        type: FETCH_ESTIMATION_C,
                        payload: data,
                    });
                });
        }
    } else {
        axios
            .post(`${config.API_SERVER}/api/housing/estimation`, requestBody)
            .then((request) => {
                dispatch({
                    type: FETCH_ESTIMATION_C,
                    payload: request.data.estimation.calculation,
                });
            });
    }
};
export const paymentDoneByClient =
    (formData, paymentMethodId, price, Data, personalData, history) =>
    async (dispatch) => {
        const requestBody = {
            ...Data,
            ...personalData,
            lag: "fr",
        };
        estimationPersonalDataUpdate(requestBody)(dispatch);

        console.log(JSON.stringify(requestBody), "requestBody 753");

        try {
            await axios
                .post(
                    `${config.API_SERVER}/api/register/client`,
                    JSON.stringify(requestBody)
                )
                .then((res) => {
                    if (res.status === 200) {
                        console.log("Info saved: ", res.data);
                        const f = new FormData();
                        f.append("clientId", res.data.client_id);
                        f.append("rue", formData.street);
                        f.append("lat", formData.lat);
                        f.append("lng", formData.lng);
                        f.append("city", Data.address.city);
                        f.append("province", Data.address.province);
                        f.append("country", Data.address.country);
                        f.append("postcode", formData.postal);
                        f.append("apt", formData.appNumber);
                        f.append("paymentMethodId", paymentMethodId);
                        f.append("price", parseInt(price, 10));

                        axios
                            .post(
                                `${config.API_SERVER}/api/conciege/payment`,
                                f
                            )
                            .then((res) => {
                                if (res.status === 200) {
                                    history("/concierge/thankyou");
                                }
                            });
                    }
                    if (res.status === 404) {
                        alert("l'email deja existe ");
                    }
                })
                .catch((error) => {
                    console.log(error.response.data.error, "data register");
                    alert(error.response.data.error);
                });
        } catch (error) {
            console.log(error.response.data.error, "data");
        }
    };
export const dataRegister = (Data) => (dispatch) => {
    axios
        .post(`${config.API_SERVER}/api/register/client`, Data)
        .then((res) => {
            if (res.status === 200) {
                console.log(res);
            }
        })
        .catch((error) => {
            console.log(error.response.status, error.response.data.error);
        });
};
export const fetchMoreThanOneEstimate =
    (requestBody, token, categoryId, sizeId, specificity, getDate) =>
    (dispatch) => {
        axios
            .post(
                `${config.API_SERVER}/api/client/reservation/estimationNewRequest`,
                requestBody,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((request) =>
                dispatch({
                    type: FETCH_NEW_ESTIMATION_C,
                    payload: {
                        estimation: request.data.estimation,
                        categoryId,
                        sizeId,
                        specificity,
                    },
                })
            )
            .then(() => {
                if (getDate) {
                    getClientReservationDates(token, "demand")(dispatch);
                }
            })
            .then(() => {
                if (getDate) {
                    getClientReservationDates(token, "accepted")(dispatch);
                }
            });
    };

export const getClientReservationDates = (token, api) => (dispatch) => {
    try {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };

        axios
            .get(`${config.API_SERVER}/api/client/reservation/${api}`, headers)
            .then((res) => {
                const d = res.data.reservations;
                const ad = [];
                for (let i in d) {
                    if (api === "demand") {
                        ad.push(getTimeUnix(`${d[i].weekDate}T00:00:00`));
                    }
                    if (api === "accepted") {
                        ad.push(getTimeUnix(`${d[i].week_date}T00:00:00`));
                    }
                }

                dispatch({
                    type: GET_LOCKED_DATE_C,
                    payload: ad,
                });
            })
            .catch((error) => {
                console.log(error.response.data.error);
            });
    } catch (error) {
        console.log(error);
    }
};
