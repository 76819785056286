import "../scss/app.scss";
import { HeaderCg } from "../../../../layout/Header";
import Hero from "../sections/clientHome/Hero";
import FooterRP from "../../../../layout/FooterRP";
// import Services from "../sections/clientHome/Services";
// import Steps from "../sections/clientHome/Steps";
// import Experts from "../sections/clientHome/Experts";
// import Advantages from "../sections/clientHome/Advantages";
// import Cta from "../sections/clientHome/Cta";
// import ProposeService from "../sections/clientHome/ProposeService";

//import { useDispatch } from "react-redux";
//import { useNavigate } from 'react-router-dom'
//import { logOutAction } from "../../../../../common/store/actions/authAction";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { Box } from "@material-ui/core";
//import Stepper from "../section-parts/HorizontalLinearStepper";
//import { useTranslation } from "react-i18next";

//import { Navbar, Nav } from "react-bootstrap";
function ConciergeHome() {
    //   const dispatch = useDispatch();
    //   const history = useNavigate();
    //const { i18n } = useTranslation();
    // function changeLanguage(lang) {
    //     //When the user click on the button to change the language, this function is reached and the language is changed.
    //     i18n.changeLanguage(lang);
    // }

    // const lang = i18n.language;
    // let langLabel = "En";
    // if (lang === "en") {
    //     langLabel = "Fr";
    // }
    return (
        <Box>
            <HeaderCg backgroundColorStatic={false} />
            <Hero />
            <FooterRP />
            <MessengerCustomerChat
                pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID}
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            />
        </Box>
    );
}

export default ConciergeHome;
