import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const FooterRP = () => {
    const { t } = useTranslation();

    return (
        <footer className="FooterServ">
            <Box className="FooterServ_main">
                <Box className="FooterServ__box d-md-flex justify-content-md-between pt-xl-5 pt-sm-4 pb-sm-4 pb-xl-4">
                    <Box className="d-flex flex-column justify-content-between logo_box">
                        <img
                            src={"/images/logo_Concierge.svg"}
                            alt="logo_tiggidoo"
                            className="Footer_logo"
                        />

                        <Box
                            className="desktop_copyright mt-xl-4"
                            marginLeft={"100px"}
                        >
                            <ul className="d-flex mt-4">
                                <li className="mb-4 mb-xl-5 mr-4 Footer__link">
                                    <Typography variant="h6">
                                        {" "}
                                        {t("Footer.copyRight")}{" "}
                                    </Typography>
                                </li>
                                {/* <li className="mb-4 mb-xl-5 mr-4 Footer__link">
                                    <Link to="/">
                                        {t("Footer.ul2.li.text2")}
                                    </Link>
                                </li> */}

                                {/* <li className="mb-4 mb-xl-5 Footer__link">
                                    <Link to="/">
                                        {t("Footer.ul2.li.text3")}
                                    </Link>
                                </li> */}
                            </ul>
                        </Box>
                    </Box>
                    <Box className="Footer_pay-icons">
                        <span>
                            <img
                                src={"images/icon_american-express.svg"}
                                alt="icon_american-express"
                            />
                        </span>

                        <span>
                            <img
                                src={"images/icon_apple-pay.svg"}
                                alt="icon_apple-pay"
                            />
                        </span>

                        <span>
                            <img
                                src={"images/icon_mastercard.svg"}
                                alt="icon_mastercard"
                            />
                        </span>

                        <span>
                            <img src={"images/icon_visa.png"} alt="icon_visa" />
                        </span>
                    </Box>
                </Box>
            </Box>
        </footer>
    );
};

export default FooterRP;
