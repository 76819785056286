import { withTranslation, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { useDispatch, useStore } from "react-redux";
import {
    estimationHousingUpdate,
    fetchEstimation,
} from "../../../../../common/store/actions/estimationConciergeAction";
import varLogement from "../../../../../constant.json";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Counter from "./Counter";
import config from "../../../../../config.json";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        bottom: 5,

        "@media (max-width:599px)": {
            minWidth: "97px",
            margin: 0,
        },
    },
    img: {
        bottom: 15,
        width: "25px",
        height: "25px",
    },
}));

const ApartmentServices = ({ t }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useStore();
    const { specLogement } = varLogement
    const [terrace, setTerrace] = useState(
        store.getState().estimationConcierge.settings.housingSpecificity.terrace
    );
    const settings = store.getState().estimationConcierge.settings;

    const [housingSize, setHousingSize] = useState(settings.housingSizeId);
    const [animals, setAnimals] = useState({
        dog: settings.houseworkPersonalization.dog,
        cat: settings.houseworkPersonalization.cat,
    });
    const [housingSizesList, setHousingSizesList] = useState([]);
    const [errors, setErrors] = useState({});

    const { i18n } = useTranslation();
    const lang = i18n.language;

    useEffect(() => {
        store.subscribe(() => {
            setErrors(store.getState().estimationConcierge.housingErrorsList);
        });
    }, [store]);

    const handleHousingSizeChange = (event) => {
        const value = event.target.value;

        let requestBody = {
            ...store.getState().estimationConcierge.settings,
            housingSizeId: value,
        };

        estimationHousingUpdate(requestBody)(dispatch);
        fetchEstimation(requestBody)(dispatch);
        setHousingSize(value);
    };

    const handleAnimalsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name, value, "animals");
        let requestBody = {
            ...store.getState().estimationConcierge.settings,
            housingSpecificity: {
                ...store.getState().estimationConcierge.settings
                    .housingSpecificity,
                [name]: value,
            },
        };

        estimationHousingUpdate(requestBody)(dispatch);
        fetchEstimation(requestBody)(dispatch);
        setTerrace(value);
    };

    useEffect(() => {
        const fetchHousingSizesList = async () => {
            const res = await fetch(
                `${config.API_SERVER}/api/conciegeHousingSizeList/${lang}`
            );
            const data = await res.json();

            return data;
        };

        const getHousingSizesList = async () => {
            const sizesFromServer = await fetchHousingSizesList();
            setHousingSizesList(sizesFromServer.sizes);
        };

        getHousingSizesList();
    }, [lang]);

    const housingSizes = [];

    for (const [key, value] of Object.entries(housingSizesList)) {
        housingSizes[key] = value;
    }

    const housingErrorsList =
        store.getState().estimationConcierge.housingErrorsList;
    let hasBathtubOrShower = false;
    if (Object.keys(housingErrorsList).length !== 0) {
        hasBathtubOrShower = housingErrorsList.hasBathtubOrShower;
    }

    return (
        <Box className="StudioServices__choice-container">
            <Typography variant="h3" className="HousingType__title">
                {t("Client.Logement.title2")}
            </Typography>
            <Box className="ComboServices__size">
                <FormControl
                    className={classes.formControl}
                    error={errors?.housingSizeId ? true : false}
                >
                    <Select
                        value={housingSize}
                        onChange={handleHousingSizeChange}
                        inputProps={{
                            name: "housingSize",
                            id: "housingSize",
                        }}
                        className="homeSize_select"
                    >
                        {housingSizes.map((homeSize, index) => (
                            <MenuItem key={index} value={index}>
                                {homeSize}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Typography variant="h3" className="HousingType__title">
                {t("Client.Logement.title3")}
            </Typography>
            <Box className="StudioServices__choice-form">
                <Counter
                    name="kitchen"
                    title={t("Client.Logement.housingSpecificity_kitchen")}
                    iconSrc="images/icon_kitchen.svg"
                    specLogement={specLogement.kitchen}
                />
                <Counter
                    name="salon"
                    title={t("Client.Logement.housingSpecificity_salon")}
                    iconSrc="images/icon_sofa.svg"
                    specLogement={specLogement.salon}
                />
                <Counter
                    name="dining_room"
                    title={t("Client.Logement.housingSpecificity_dining_room")}
                    iconSrc="images/icon_hotel.svg"
                    specLogement={specLogement.dining_room}
                />
                <Counter
                    name="bedroom"
                    title={t("Client.Logement.housingSpecificity_bedroom")}
                    iconSrc="images/icon_moon.svg"
                    description={t(
                        "Client.Logement.housingSpecificity_bedroom_desc"
                    )}
                    specLogement={specLogement.bedroom}
                />
                <Counter
                    name="bathroom"
                    title={t("Client.Logement.housingSpecificity_bathroom")}
                    iconSrc="images/icon_washbasin.svg"
                    specLogement={specLogement.bathroom}
                />
                <Counter
                    name="shower"
                    title={t("Client.Logement.housingSpecificity_shower")}
                    iconSrc="images/icon_shower.svg"
                    hasBathtubOrShower={hasBathtubOrShower}
                    specLogement={specLogement.shower}
                />
                <Counter
                    name="bathtub"
                    title={t("Client.Logement.housingSpecificity_bathtub")}
                    iconSrc="images/icon_bathtub.svg"
                    hasBathtubOrShower={hasBathtubOrShower}
                    specLogement={specLogement.bathtub}
                />
                <Counter
                    name="washbasin"
                    title={t("Client.Logement.housingSpecificity_washbasin")}
                    iconSrc="images/icon_water.svg"
                    specLogement={specLogement.washroom}
                />
                <Counter
                    name="floor"
                    title={t("Client.Logement.housingSpecificity_floor")}
                    iconSrc="images/icon_stares.svg"
                    description={t(
                        "Client.Logement.housingSpecificity_floor_desc"
                    )}
                    specLogement={specLogement.floor}
                />
                {/* <Counter
                    name="balcony"
                    title={t("Client.Logement.housingSpecificity_balcony")}
                    iconSrc="images/balcon.svg"
                    specLogement={specLogement.balcony}
                />
                <FormControl
                    className={classes.formControl}
                    error={errors?.terrace ? true : false}
                >
                    <InputLabel htmlFor="terrace">
                        <img
                            className={classes.img}
                            src={"images/terrace.svg"}
                            alt="terrace"
                        />
                        {t("Client.Logement.housingSpecificity_terrace")}
                    </InputLabel>

                    <Select
                        value={terrace}
                        onChange={handleAnimalsChange}
                        inputProps={{
                            name: "terrace",
                            id: "terrace",
                        }}
                    >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value={true}>{t("Client.yes")}</MenuItem>
                        <MenuItem value={false}>{t("Client.no")}</MenuItem>
                    </Select>
                </FormControl> */}
            </Box>
        </Box>
    );
};

export default withTranslation()(ApartmentServices);
