import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import InputPostCode from "./InputPostCode";
import { useDispatch, useStore } from "react-redux";
import { Box } from "@material-ui/core";

const PostCodeCl = ({ t }) => {
    const store = useStore();
    const isLoggedIn = store.getState().auth.isLoggedIn;

    return (
        <Box>
            <Box className="post_code__container">
                <h2
                    style={{ fontSize: "3rem" }}
                    className="post_code__title mt-5"
                >
                    {t("Client.Hero.title1")}{" "}
                </h2>
                <p>{t("Client.Hero.text1")}</p>
            </Box>
            <Box mt={6}>
                <InputPostCode />
            </Box>
        </Box>
    );
};

export default withTranslation()(PostCodeCl);
